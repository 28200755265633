<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-plus me-2"></i>Create or Update Event
        </legend>
        <FormInput
            type="id"
            label="ID"
            v-model="form.id"
            is-horizontal
            id-prefix="id"
            class="mb-md-1 mb-2"
            input-class="form-control-sm"
            disabled
            label-class="col-xl-3 col-md-5 label-sm"
            input-container-class="col-xl-9 col-md-7 col-12"
        />
        <FormInput
            type="text"
            label="Name"
            v-model="form.name"
            :errors="errors.name"
            is-horizontal
            id-prefix="name"
            class="mb-md-1 mb-2"
            input-class="form-control-sm"
            label-class="col-xl-3 col-md-5 label-sm"
            input-container-class="col-xl-9 col-md-7 col-12"
        />
        <FormSelect
            label="Type"
            v-model="form.type"
            :errors="errors.type"
            :options="companyEventTypes"
            class="mb-md-1 mb-2"
            :option-to-string="(option) => option.label"
            :option-value="(option) => option.value"
            id-prefix="type"
            select-class="form-select-sm"
            is-horizontal
            label-class="col-xl-3 col-md-5 label-sm"
            select-container-class="col-xl-9 col-md-7 col-12"
        />
        <FormInput
            type="date"
            label="Date"
            v-model="form.event_date"
            :errors="errors.event_date"
            is-horizontal
            id-prefix="date"
            class="mb-md-1 mb-2"
            input-class="form-control-sm"
            label-class="col-xl-3 col-md-5 label-sm"
            input-container-class="col-xl-9 col-md-7 col-12"
        />
        <FormTextarea
            label="Description"
            v-model="form.description"
            :errors="errors.description"
            is-horizontal
            id-prefix="description"
            class="mb-md-1 mb-2"
            textarea-class="form-control-sm"
            label-class="col-xl-3 col-md-5 label-sm"
            input-container-class="col-xl-9 col-md-7 col-12"
        />
        <FormSelect
            label="Is Recurring?"
            v-model="form.is_recurring"
            :errors="errors.is_recurring"
            :options="IS_RECURRING_OPTIONS"
            :option-to-string="(option) => option.label"
            :option-value="(option) => option.value"
            id-prefix="isRecurring"
            select-class="form-select-sm"
            is-horizontal
            class="mb-3"
            label-class="col-xl-3 col-md-5 label-sm"
            select-container-class="col-xl-9 col-md-7 col-12"
        />
        <div class="row pe-xl-2 g-1">
            <div class="col-md-6 col-12">
                <button
                    type="button"
                    class="btn btn-outline-secondary w-100 btn-sm"
                    @click="clear"
                >
                    <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Clear
                </button>
            </div>
            <div class="col-md-6 col-12">
                <button
                    type="button"
                    class="btn btn-primary w-100 btn-sm"
                    :disabled="companyEvent"
                    @click="insertCompanyEvent"
                >
                    <i class="icon ph-bold ph-note-pencil me-2"></i>Create
                </button>
            </div>
            <div class="col-md-6 col-12">
                <button
                    type="button"
                    class="btn btn-danger w-100 btn-sm"
                    :disabled="!companyEvent"
                    @click="destroyCompanyEvent"
                >
                    <i class="icon ph-bold ph-trash me-2"></i>Delete
                </button>
            </div>
            <div class="col-md-6 col-12">
                <button
                    type="button"
                    class="btn btn-primary w-100 btn-sm"
                    :disabled="!companyEvent"
                    @click="updateCompanyEvent"
                >
                    <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Update
                </button>
            </div>
        </div>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useCompanyEventTypes } from "@/composables/data/companyEventTypes";
import { useCompanyEvents } from "@/composables/data/companyEvents";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import FormTextarea from "@/components/utils/FormTextarea.vue";

const emit = defineEmits(["success"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { companyEventTypes } = useCompanyEventTypes();

const { postCompanyEvent, putCompanyEvent, deleteCompanyEvent } =
    useCompanyEvents();

const IS_RECURRING_OPTIONS = [
    {
        value: 1,
        label: "Recurring",
    },
    {
        value: 0,
        label: "Not Recurring",
    },
];

const companyEvent = defineModel();

const form = ref({
    name: null,
    type: null,
    event_date: null,
    is_recurring: null,
});

const errors = ref({});

watch(companyEvent, () => {
    form.value = Object.assign(
        {
            name: null,
            type: null,
            event_date: null,
            is_recurring: null,
        },
        companyEvent.value
    );
    errors.value = {};
});

async function insertCompanyEvent() {
    errors.value = {};

    loadingFlags.add("insertCompanyEvent");
    try {
        const newCompanyEvent = await postCompanyEvent(form.value);
        toasts.add("SUCCESS", "Success", "Successfully created company event.");
        companyEvent.value = newCompanyEvent;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("insertCompanyEvent");
}

async function updateCompanyEvent() {
    errors.value = {};

    loadingFlags.add("updateCompanyEvent");
    try {
        const newCompanyEvent = await putCompanyEvent(
            companyEvent.value.id,
            form.value
        );
        toasts.add("SUCCESS", "Success", "Successfully updated company event.");
        companyEvent.value = newCompanyEvent;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateCompanyEvent");
}

async function destroyCompanyEvent() {
    if (window.prompt("Are you sure? Type 'DELETE' to proceed.") != "DELETE") {
        return;
    }

    errors.value = {};

    loadingFlags.add("destroyCompanyEvent");
    try {
        await deleteCompanyEvent(companyEvent.value.id);
        toasts.add("SUCCESS", "Success", "Successfully deleted company event.");
        companyEvent.value = null;
        emit("success");
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("destroyCompanyEvent");
}

function clear() {
    companyEvent.value = null;
    form.value = {
        name: null,
        type: null,
        event_date: null,
        is_recurring: null,
    };
}
</script>
