<template>
    <div class="row g-3">
        <div class="col-xl-8 col-lg-6">
            <ColorTableCard
                v-model="selectedColor"
                :refresh-flag="colorTableRefreshFlag"
                @error="
                    (message) => {
                        addFlashMessage('ERROR', message);
                        scrollToTop();
                    }
                "
            />
        </div>
        <div class="col-xl-4 col-lg-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-notepad me-2"></i> Color Form
                </legend>
                <FormInput
                    type="text"
                    v-model="color.COLOR"
                    label="Color"
                    id-prefix="color"
                    class="mb-lg-1 mb-2"
                    is-horizontal
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-lg-9 col-12"
                    label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                    @keydown-enter="focusnext('isActiveFormInput')"
                    :errors="errors.COLOR"
                />
                <FormSelect
                    v-model="color.IS_ACTIVE"
                    label="Is Active"
                    id-prefix="isActive"
                    :options="[
                        { value: 1, verbose: 'Active' },
                        { value: 0, verbose: 'Inactive' },
                    ]"
                    :option-value="(option) => option.value"
                    :option-to-string="(option) => option.verbose"
                    is-horizontal
                    select-class="form-select-sm"
                    select-container-class="col-xl-8 col-lg-9 col-12"
                    label-class="col-xl-4 col-lg-3 label-sm align-items-center"
                    @keydown-enter="
                        (_) => {
                            if (selectedColor) {
                                focusnext('updateButton');
                            } else {
                                focusnext('insertButton');
                            }
                        }
                    "
                    :errors="errors.IS_ACTIVE"
                />
                <div class="text-end mt-3">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm mb-1 me-1"
                        id="insertButton"
                        :disabled="selectedColor"
                        @click="insertColor"
                    >
                        <i class="icon ph-bold ph-plus me-2"></i>Insert
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm mb-1 me-1"
                        id="updateButton"
                        :disabled="!selectedColor"
                        @click="updateColor"
                    >
                        <i class="icon ph-bold ph-pen-simple-line me-2"></i>Update
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm me-1 mb-1"
                        @click="reset"
                    >
                        <i class="icon ph-bold ph-arrow-clockwise me-2"></i>Reset
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";

import { useFlashMessages } from "@/composables/flashmessages";
import { useColors } from "@/composables/data/colors";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

import ColorTableCard from "./ColorTableCard.vue";

import scrollToTop from "@/utils/scrollToTop";
import focusnext from "@/utils/focusnext";

const { addFlashMessage } = useFlashMessages();

const colorTableRefreshFlag = ref(false);

const { postColor, putColor } = useColors();

const selectedColor = ref(null);

const color = ref({
    COLOR: null,
    IS_ACTIVE: null,
});

const errors = ref({});

const isLoading = ref(false);

watch(selectedColor, () => {
    color.value = Object.assign(
        {
            COLOR: null,
            IS_ACTIVE: null,
        },
        selectedColor.value
    );
});

function reset() {
    errors.value = {};
    selectedColor.value = null;
}

async function insertColor() {
    errors.value = {};

    isLoading.value = true;
    try {
        await postColor(color.value);
        addFlashMessage("SUCCESS", "Successfully saved color.");
        selectedColor.value = Object.assign({}, color.value);
        colorTableRefreshFlag.value = !colorTableRefreshFlag.value;
    } catch (e) {
        errors.value = e.errors ?? {};
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
    isLoading.value = false;
}

async function updateColor() {
    errors.value = {};

    isLoading.value = true;
    try {
        await putColor(selectedColor.value.COLOR, color.value);
        addFlashMessage("SUCCESS", "Successfully updated color.");
        selectedColor.value = Object.assign({}, color.value);
        colorTableRefreshFlag.value = !colorTableRefreshFlag.value;
    } catch (e) {
        errors.value = e.errors ?? {};
        addFlashMessage("ERROR", e.message);
    }
    scrollToTop();
    isLoading.value = false;
}
</script>
