<style scoped>
.wrapper {
    width: 100%;
    height: 15rem;
    overflow-y: auto;
    background-color: #fff;
}
</style>

<template>
    <div id="topOfPage"></div>
    <FlashMessageAlerts
        :messages="flashMessages"
        :dismissible="true"
        class="mb-3"
    />
    <div class="row">
        <div class="col-xl-4 mb-3">
            <fieldset :class="class">
                <legend>
                    <i class="icon ph-bold ph-credit-card me-2"></i>Account Details
                </legend>
                <div class="row">
                    <div class="col-lg-12 mb-3">
                        <div class="row g-1">
                            <div class="col-sm-6">
                                <button
                                    @click="openSelectAccount"
                                    class="btn btn-primary btn-sm w-100"
                                >
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>SELECT
                                </button>
                            </div>
                            <div class="col-sm-6">
                                <button
                                    @click="handleResetAccount"
                                    class="btn btn-primary btn-sm w-100"
                                >
                                    <i class="icon ph-bold ph-arrow-clockwise me-2"></i>RESET
                                </button>
                            </div>
                            <div class="col-sm-6">
                                <button
                                    class="btn btn-primary btn-sm w-100"
                                    @click="handleInsertAccount"
                                    :disabled="account.ACCOUNT_ID !== 'New Emp'"
                                >
                                    <i class="icon ph-bold ph-plus me-2"></i>INSERT
                                </button>
                            </div>
                            <div class="col-sm-6">
                                <button
                                    class="btn btn-primary btn-sm w-100"
                                    @click="handleUpdateAccount"
                                    :disabled="account.ACCOUNT_ID === 'New Emp'"
                                >
                                    <i class="icon ph-bold ph-pencil-simple-line me-2"></i>UPDATE
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <FormInput
                            label="Account ID:"
                            v-model="account.ACCOUNT_ID"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountId"
                            disabled
                            type="text"
                        />
                        <FormSelect
                            label="Account Type"
                            v-model="account.ACCOUNT_TYPE"
                            id-prefix="accountType"
                            :options="accounttypeList"
                            :option-to-string="(accountType) => accountType.ACCOUNT_TYPE"
                            :option-value="(accountType) => accountType.ACCOUNT_TYPE"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            select-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            select-class="form-select-sm"
                            @change="
                                () => {
                                    focusNextInput('accountNameFormInput');
                                    errors.ACCOUNT_TYPE = '';
                                }
                            "
                            :errors="errors.ACCOUNT_TYPE"
                        />
                        <FormInput
                            label="Account Name"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountName"
                            v-model="account.ACCOUNT_NAME"
                            type="text"
                            @keydown-enter="focusNextInput('accountDescriptionFormInput')"
                            :errors="errors.ACCOUNT_NAME"
                        />
                        <FormInput
                            label="Account Description"
                            v-model="account.ACCOUNT_DESCRIPTION"
                            type="text"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountDescription"
                            @keydown-enter="focusNextInput('accountAddressFormInput')"
                            :errors="errors.ACCOUNT_DESCRIPTION"
                        />
                        <FormInput
                            label="Account Address"
                            v-model="account.ACCOUNT_ADDRESS"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountAddress"
                            type="text"
                            @keydown-enter="focusNextInput('accountContactFormInput')"
                            :errors="errors.ACCOUNT_ADDRESS"
                        />
                        <FormInput
                            label="Account Contact"
                            v-model="account.ACCOUNT_CONTACT"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountContact"
                            type="text"
                            @keydown-enter="focusNextInput('accountTitleFormInput')"
                            :errors="errors.ACCOUNT_CONTACT"
                        />
                        <FormInput
                            label="Account Title"
                            v-model="account.ACCOUNT_TITLE"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountTitle"
                            type="text"
                            @keydown-enter="focusNextInput('accountPhoneFormInput')"
                            :errors="errors.ACCOUNT_TITLE"
                        />
                        <FormInput
                            label="Account Phone"
                            v-model="account.ACCOUNT_PHONE"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountPhone"
                            type="text"
                            @keydown-enter="focusNextInput('accountMemoFormInput')"
                            :errors="errors.ACCOUNT_PHONE"
                        />
                        <FormInput
                            label="Account Memo"
                            v-model="account.ACCOUNT_MEMO"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountMemo"
                            type="text"
                            :errors="errors.ACCOUNT_MEMO"
                        />
                        <FormInput
                            label="Account GL"
                            v-model="account.ACCOUNT_GL"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountMemo"
                            type="text"
                            disabled
                            :errors="errors.ACCOUNT_GL"
                        />
                        <p class="col-md-12 text-center">
                            *If needed, to update ACCOUNT_GL, ask James*
                        </p>
                        <FormInput
                            label="Created By"
                            v-model="account.CREATED_BY"
                            class="mb-md-1 mb-2 align-items-center"
                            is-horizontal
                            label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                            input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                            input-class="form-control-sm"
                            id-prefix="accountCreatedBy"
                            type="text"
                            disabled
                            :errors="errors.CREATED_BY"
                        />
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-8">
            <AccountItemCard
                :account="account"
                @success="
                    (message) => {
                        addFlashMessage('SUCCESS', message);
                    }
                "
                @error="
                    (message) => {
                        addFlashMessage('ERROR', message);
                    }
                "
                @accountItemSelected="handleAccountItem"
                class="mb-3"
            />
            <AccountItemPriceCard
                :item="accountItem"
                :account="account"
                @success="
                    (message) => {
                        addFlashMessage('SUCCESS', message);
                    }
                "
                @error="
                    (message) => {
                        addFlashMessage('ERROR', message);
                    }
                "
            />
        </div>
    </div>

    <SelectAccount
        :isOpen="isSelectAccountOpen"
        :onClose="() => (isSelectAccountOpen = false)"
        :onSelectAccount="handleSelectedAccount"
        :preventNone="true"
    />
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import FlashMessageAlerts from "../../../utils/FlashMessageAlerts.vue";

import focusNextInput from "../../../../utils/focusnext.js";
import scrollToTop from "../../../../utils/scrollToTop";

import FormInput from "../../../utils/FormInput.vue";
import FormSelect from "../../../utils/FormSelect.vue";
import SelectAccount from "../../../utils/SelectAccount.vue";

import AccountItemCard from "./AccountItemCard.vue";
import AccountItemPriceCard from "./AccountItemPriceCard.vue";

const account = ref({
    ACCOUNT_ID: "New Emp",
    ACCOUNT_TYPE: null,
    ACCOUNT_NAME: null,
    ACCOUNT_DESCRIPTION: null,
    ACCOUNT_ADDRESS: null,
    ACCOUNT_CONTACT: null,
    ACCOUNT_TITLE: null,
    ACCOUNT_PHONE: null,
    CREATED_BY: null,
    ACCOUNT_MEMO: null,
    ACCOUNT_GL: 0,
});

const accountItem = ref({});

const accounttypeList = ref([]);

const flashMessages = ref([]);
const errors = ref({});
const isSelectAccountOpen = ref(false);

onMounted(() => {
    getAccountTypes();
    getUser();
});

async function getAccountTypes() {
    try {
        const response = await axios.get(route("api.account-types.index"));
        accounttypeList.value = response.data.data;
    } catch (e) {
        console.log(e);
        scrollToTop();
        addFlashMessage(
            "ERROR",
            "An error occurred while fetching account types. Please contact your administrator."
        );
    }
}

async function getUser() {
    try {
        const response = await axios.get(route("api.logged-in-user.index"));
        account.value.CREATED_BY = response.data.data.name_from_email;
    } catch (e) {
        console.log(e);
    }
}

function openSelectAccount() {
    isSelectAccountOpen.value = true;
}

function handleSelectedAccount(selectedAccount) {
    errors.value = {};
    account.value = selectedAccount;
}

async function handleInsertAccount() {
    try {
        const response = await axios.post(route("api.accounts.store"), {
            ACCOUNT_TYPE: account.value.ACCOUNT_TYPE,
            ACCOUNT_NAME: account.value.ACCOUNT_NAME,
            ACCOUNT_DESCRIPTION: account.value.ACCOUNT_DESCRIPTION,
            ACCOUNT_ADDRESS: account.value.ACCOUNT_ADDRESS,
            ACCOUNT_CONTACT: account.value.ACCOUNT_CONTACT,
            ACCOUNT_TITLE: account.value.ACCOUNT_TITLE,
            ACCOUNT_PHONE: account.value.ACCOUNT_PHONE,
            ACCOUNT_MEMO: account.value.ACCOUNT_MEMO,
            ACCOUNT_GL: account.value.ACCOUNT_GL,
        });
        errors.value = {};
        scrollToTop();
        addFlashMessage("SUCCESS", response.data);
        handleResetAccount();
    } catch (e) {
        console.log(e);
        if (e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            addFlashMessage(
                "ERROR",
                "An error occurred while saving account. Please contact your administrator."
            );
        }
    }
}

async function handleUpdateAccount() {
    try {
        const response = await axios.patch(
            route("api.accounts.update", { id: account.value.ACCOUNT_ID }),
            {
                ACCOUNT_TYPE: account.value.ACCOUNT_TYPE,
                ACCOUNT_NAME: account.value.ACCOUNT_NAME,
                ACCOUNT_DESCRIPTION: account.value.ACCOUNT_DESCRIPTION,
                ACCOUNT_ADDRESS: account.value.ACCOUNT_ADDRESS,
                ACCOUNT_CONTACT: account.value.ACCOUNT_CONTACT,
                ACCOUNT_TITLE: account.value.ACCOUNT_TITLE,
                ACCOUNT_PHONE: account.value.ACCOUNT_PHONE,
                ACCOUNT_MEMO: account.value.ACCOUNT_MEMO,
                ACCOUNT_GL: account.value.ACCOUNT_GL,
            }
        );
        errors.value = {};
        scrollToTop();
        addFlashMessage("SUCCESS", response.data);
    } catch (e) {
        console.log(e);
        if (e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            addFlashMessage(
                "ERROR",
                "An error occurred while saving account. Please contact your administrator."
            );
        }
    }
}

function handleResetAccount() {
    account.value = {
        ACCOUNT_ID: "New Emp",
        ACCOUNT_TYPE: null,
        ACCOUNT_NAME: null,
        ACCOUNT_DESCRIPTION: null,
        ACCOUNT_ADDRESS: null,
        ACCOUNT_CONTACT: null,
        ACCOUNT_TITLE: null,
        ACCOUNT_PHONE: null,
        ACCOUNT_MEMO: null,
        ACCOUNT_GL: 0,
    };
    getUser();
    errors.value = {};
}

function handleAccountItem(selectedItem) {
    accountItem.value = selectedItem;
}

function addFlashMessage(type, content) {
    flashMessages.value.push({ type, content });
}
</script>
