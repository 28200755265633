<style scoped>
#timePeriodTableContainer,
#timeReportTableContainer {
    max-height: 400px;
    overflow-y: scroll;
}

#timePeriodTableContainer thead,
#timeReportTableContainer thead {
    position: sticky;
    top: 0;
    margin: 0;
    background-color: lightslategrey;
    color: #fff;
}

table {
    cursor: pointer;
}
</style>

<!-- TODO: Divide into multiple vue components -->
<template>
    <div class="row">
        <div class="col-lg-7 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar-dots me-2"></i>Select Time Period
                </legend>
                <div class="row mb-3">
                    <div class="col-md-3">
                        <label for="payTypeFilter" aria-label="Pay Type Filter">Select a Period Type</label>
                    </div>
                    <div class="col-md-5">
                        <select
                            v-model="selectedPayType"
                            class="form-control form-select-sm"
                            id="payTypeFilter"
                            @change="payTypeSelectOnChange"
                        >
                            <option disabled selected value="">SELECT PERIOD TYPE</option>
                            <option
                                v-for="payType in payTypesList"
                                :value="payType"
                            >
                                {{ payType }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- Time Periods Table -->
                <div
                    class="table-responsive mb-3"
                    id="timePeriodTableContainer"
                >
                    <table class="table table-bordered table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Time Period</th>
                                <th>Pay Type</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!timePeriodsList || timePeriodsList.length == 0">
                                <td colspan="5" class="text-center">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-calendar me-2"></i>No time periods.
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-else
                                v-for="timePeriod in timePeriodsList"
                                :class="{
                                    'bg-primary text-white':
                                        selectedTimePeriod?.TIME_PERIOD ===
                                        timePeriod.TIME_PERIOD,
                                }"
                                @click="timePeriodRowClick(timePeriod)"
                            >
                                <td>{{ timePeriod.TIME_PERIOD }}</td>
                                <td>{{ timePeriod.PAYTYPE }}</td>
                                <td>{{ timePeriod.START_DATE }}</td>
                                <td>{{ timePeriod.END_DATE }}</td>
                                <td>
                                    <span
                                        v-if="timePeriod.STATUS === 'O'"
                                        class="badge bg-success"
                                    >
                                        Open
                                    </span>
                                    <span
                                        v-else-if="timePeriod.STATUS === 'C'"
                                        class="badge bg-secondary"
                                    >
                                        Closed
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-5">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Currently Selected Time Period
                </legend>
                <div v-if="selectedTimePeriod" class="row g-3 mb-3">
                    <div class="col-sm-4 col-md-12 col-lg-6 col-xl-4">
                        <label for="selectedTimePeriodTimePeriodDisplay">Time Period</label>
                        <input
                            type="text"
                            v-model="selectedTimePeriod.TIME_PERIOD"
                            class="form-control form-control-sm"
                            id="selectedTimePeriodTimePeriodDisplay"
                            disabled
                        />
                    </div>
                    <div class="col-sm-4 col-md-12 col-lg-6 col-xl-4">
                        <label for="selectedTimePeriodPaytypeDisplay">Pay Type</label>
                        <input
                            type="text"
                            v-model="selectedTimePeriod.PAYTYPE"
                            class="form-control form-control-sm"
                            id="selectedTimePeriodPaytypeDisplay"
                            disabled
                        />
                    </div>
                    <div class="col-sm-4 col-md-12 col-lg-6 col-xl-4">
                        <label for="selectedTimePeriodStartDateDisplay">Start Date</label>
                        <input
                            type="date"
                            v-model="selectedTimePeriod.START_DATE"
                            class="form-control form-control-sm"
                            id="selectedTimePeriodStartDateDisplay"
                            disabled
                        />
                    </div>
                    <div class="col-sm-4 col-md-12 col-lg-6 col-xl-4">
                        <label for="selectedTimePeriodEndDateDisplay">End Date</label>
                        <input
                            type="date"
                            v-model="selectedTimePeriod.END_DATE"
                            class="form-control form-control-sm"
                            id="selectedTimePeriodEndDateDisplay"
                            disabled
                        />
                    </div>
                    <div class="col-sm-4 col-md-12 col-lg-6 col-xl-4">
                        <label for="selectedTimePeriodStatusDisplay">Status</label>
                        <input
                            type="text"
                            v-model="selectedTimePeriod.STATUS"
                            class="form-control form-control-sm"
                            id="selectedTimePeriodStatusDisplay"
                            disabled
                        />
                    </div>
                </div>
                <div>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm me-2 mb-1"
                        :disabled="
                            selectedTimePeriod &&
                            selectedTimePeriod.STATUS === 'O'
                        "
                        @click="openPeriodButtonOnClick"
                    >
                        <i class="icon ph-bold ph-lock-simple-open me-2"></i>Open Period
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm me-2 mb-1"
                        :disabled="selectedTimePeriod === null"
                        data-bs-toggle="modal"
                        data-bs-target="#viewTimeReportsModal"
                        @click="viewTimeReportsButtonOnClick"
                    >
                        <i class="icon ph-bold ph-eye me-2"></i>View Time Reports
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm me-2 mb-1"
                        :disabled="selectedTimePeriod === null"
                        data-bs-toggle="modal"
                        data-bs-target="#viewPayslipsModal"
                        @click="viewPayslipsButtonOnClick"
                    >
                        <i class="icon ph-bold ph-eye me-2"></i>View Payslips
                    </button>
                </div>
                <div
                    v-if="openTimePeriodAlertMessage.success !== null"
                    class="alert alert-success alert-dismissible fade show"
                >
                    {{ openTimePeriodAlertMessage.success }}
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                    >
                    </button>
                </div>
                <div
                    v-if="openTimePeriodAlertMessage.error !== null"
                    class="alert alert-danger alert-dismissible fade show"
                >
                    {{ openTimePeriodAlertMessage.error }}
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                    >
                    </button>
                </div>
                <!-- An input prompt for opening time period -->
                <input
                    type="text"
                    v-if="isCurrentlyOpeningTimePeriod"
                    v-model="openTimePeriodInputPrompt"
                    v-on:keyup.enter="openTimePeriodInputPromptOnEnter"
                    class="form-control form-control-sm mt-2"
                    placeholder="Type OPEN then hit Enter key to open time period"
                />
            </fieldset>
        </div>
    </div>

    <!-- View Time Reports Modal -->
    <ViewTimeReportsModal
        id="viewTimeReportsModal"
        :time-period="selectedTimePeriod"
        :time-period-hours-details="selectedTimePeriodHoursDetails"
    />

    <!-- View Payslips Modal -->
    <ViewPayslipsModal
        id="viewPayslipsModal"
        :time-period="selectedTimePeriod"
        :time-period-payslips="selectedTimePeriodPayslips"
    />
</template>

<script setup>
import { ref } from "vue";

import axios from "axios";
import moment from "moment";

import ViewTimeReportsModal from "../modal/ViewTimeReportsModal.vue";
import ViewPayslipsModal from "../modal/ViewPayslipsModal.vue";

import handleFetchErrors from "../../../utils/handleFetchErrors";

const payTypesList = ref(["BIMONTHLY", "WEEKLY"]);
const selectedPayType = ref("");
const timePeriodsList = ref();
const selectedTimePeriod = ref();

const isCurrentlyOpeningTimePeriod = ref(false);
const openTimePeriodInputPrompt = ref("");

const openTimePeriodAlertMessage = ref({
    success: null,
    error: null,
});

const selectedTimePeriodHoursDetails = ref([]);
const selectedTimePeriodPayslips = ref([]);

async function payTypeSelectOnChange() {
    try {
        const timePeriodsResponse = await axios.get(
            route("api.time-periods.index", {
                PAYTYPE: selectedPayType.value,
                START_DATE_LTE: moment().format("YYYY-MM-DD"),
                ORDER_DESC: "START_DATE",
            })
        );
        timePeriodsList.value = timePeriodsResponse.data.data;
        selectedTimePeriod.value = null;
    } catch (e) {
        console.log(e);
        handleFetchErrors(e, "Error while fetching time periods.");
    }
}

function timePeriodRowClick(timePeriod) {
    selectedTimePeriod.value = timePeriod;
}

function openPeriodButtonOnClick(event) {
    isCurrentlyOpeningTimePeriod.value = true;
}

async function openTimePeriodInputPromptOnEnter(event) {
    if (openTimePeriodInputPrompt.value === "OPEN") {
        try {
            await axios.post(
                route("api.time-periods.openings.store", {
                    TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
                })
            );
            payTypeSelectOnChange();
            isCurrentlyOpeningTimePeriod.value = false;
            openTimePeriodAlertMessage.value.success = "Successfully opened time period.";
            openTimePeriodAlertMessage.value.error = null;
        } catch (e) {
            openTimePeriodAlertMessage.value.error = "An error occured while attempting to open time period.";
            openTimePeriodAlertMessage.value.success = null;
            console.log(e);
            if (e.response.status === 422) {
                errors.value = e.response.data.errors;
            }
        }
    }
}

async function viewTimeReportsButtonOnClick(event) {
    try {
        const response = await axios.get(
            route("api.time-periods.hours.index", [
                selectedTimePeriod.value.TIME_PERIOD,
            ])
        );
        selectedTimePeriodHoursDetails.value = response.data.data;
    } catch (e) {
        console.log(e);
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        }
    }
}

async function viewPayslipsButtonOnClick(event) {
    try {
        const response = await axios.get(
            route("api.time-periods.payslips.index", [
                selectedTimePeriod.value.TIME_PERIOD,
            ])
        );
        selectedTimePeriodPayslips.value = response.data.data;
    } catch (e) {
        console.log(e);
        if (e.response.status === 422) {
            errors.value = e.response.data.errors;
        }
    }
}
</script>
