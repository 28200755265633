import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useUserRolesAndPermissions() {
    const userRoles = ref(null);
    const userPermissions = ref(null);

    const getUserRoles = async (userId) => {
        try {
            const getUserRolesResponse = await axios.get(route('api.users.roles.index', {
                user: userId,
            }));
            userRoles.value = getUserRolesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching user roles.');
        }
    };

    const getUserPermissions = async (userId) => {
        try {
            const getUserPermissionsResponse = await axios.get(route('api.users.permissions.index', {
                user: userId,
            }));
            userPermissions.value = getUserPermissionsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching user permissions.');
        }
    };

    const putUserRolesAndPermissions = async (userId, roles, permissions) => {
        try {
            const putUserRolesAndPermissionsResponse = await axios.put(route('api.users.roles-and-permissions.update', {
                user: userId,
            }), {
                roles,
                permissions,
            });
            return putUserRolesAndPermissionsResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching user permissions.');
        }
    };

    return {
        userRoles,
        userPermissions,
        getUserRoles,
        getUserPermissions,
        putUserRolesAndPermissions,
    };
}
