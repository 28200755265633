import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useImages() {
    const images = ref(null);
    const totalRecords = ref(null);

    const getImages = async (params) => {
        try {
            const getImagesResponse = await axios.get(route('api.images.index', params));
            images.value = getImagesResponse.data.data;
            totalRecords.value = getImagesResponse.data.meta?.total ?? images.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching images data.');
        }
    };

    const postImagesFlock = async (formData) => {
        try {
            const postImagesFlockResponse = await axios.post(route('api.images.flock.store'), formData);
            return postImagesFlockResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving images.');
        }
    };

    const deleteImage = async (id) => {
        try {
            const deleteImageResponse = await axios.delete(route('api.images.destroy', {
                image: id,
            }));
            return deleteImageResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while deleting image.');
        }
    };
    
    return {
        images,
        totalRecords,
        getImages,
        postImagesFlock,
        deleteImage,
    };
}
