<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-barcode me-2"></i>Style Landmark
        </legend>
        <form>
            <FormInput
                label="Landmark SKU"
                type="text"
                v-model="form.LANDMARK_SKU"
                is-horizontal
                id-prefix="landmarkSku"
                class="mb-2"
                input-class="form-control-sm"
                :errors="errors.LANDMARK_SKU"
            />
            <div class="text-end">
                <hr class="my-3" />
                <button type="button"
                    class="btn btn-sm btn-primary me-1"
                    :disabled="!style"
                    @click="updateLandmarkSku">
                    <i class="icon ph-bold ph-floppy-disk me-2"></i>Save
                </button>
            </div>
        </form>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useStyleLandmarkSkus } from "@/composables/data/styleLandmarkSkus";

import FormInput from "@/components/utils/FormInput.vue";

const props = defineProps({
    style: String,
    class: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { styleLandmarkSku, getStyleLandmarkSku, putStyleLandmarkSku } = useStyleLandmarkSkus();

const form = ref({
    LANDMARK_SKU: null,
});

const errors = ref({});

watch(
    () => props.style,
    async () => {
        errors.value = {};
        styleLandmarkSku.value = null;
        form.value = {
            LANDMARK_SKU: null,
        };

        if (!props.style) {
            return;
        }

        loadingFlags.add("fetchStyleLandmarkSku");
        try {
            await getStyleLandmarkSku(props.style);
            form.value = styleLandmarkSku.value;
        } catch (e) {
            if (e.status != 404) {
                toasts.add('ERROR', 'Error', e.message);
            }
        }
        loadingFlags.delete("fetchStyleLandmarkSku");
    }
);

async function updateLandmarkSku() {
    errors.value = {};

    loadingFlags.add("updateLandmarkSku");
    try {
        const newLandmarkSku = await putStyleLandmarkSku(props.style, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully updated Landmark SKU to ${newLandmarkSku.LANDMARK_SKU}.`
        );
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateLandmarkSku");
}
</script>
