<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Inventory Check
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <InventoryCheckDescriptionsTable
                        v-model="selectedInventoryCheckDescription"
                        :location="location"
                    />
                    <div class="text-end">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="!selectedInventoryCheckDescription"
                            @click="
                                (_) => {
                                    emit(
                                        'select',
                                        selectedInventoryCheckDescription
                                    );
                                }
                            "
                        >
                            <i class="icon ph-bold ph-hand-pointing me-2"></i>Select Inventory Check Description
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";

import InventoryCheckDescriptionsTable from "../tables/InventoryCheckDescriptionsTable.vue";

const props = defineProps({
    isOpen: Boolean,
    location: String,
});

const emit = defineEmits(["select", "close"]);

const selectedInventoryCheckDescription = ref(null);
</script>

<style scoped>
.container {
    max-width: 800px;
}
</style>
