<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
        id="otherSelectUnifiedPopup"
        tabindex="-1"
        @keydown.enter="onPopupEnter">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-info me-2"></i>Details
                </h4>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <div class="row mb-3">
                        <div class="col-lg-4">
                            <FormInput
                                type="date"
                                label="Sold Date"
                                v-model="item.SOLD_DT"
                                :required="['SALES', 'RETURN', 'RECON'].includes(comment.MOVEMENT_TYPE)"
                                is-horizontal
                                :disabled="!['SALES', 'RETURN', 'RECON'].includes(comment.MOVEMENT_TYPE)"
                                id-prefix="soldDate"
                                class="mb-3"
                                :errors="itemErrors.SOLD_DT"
                                input-class="form-control-sm"
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                            />
                            <EmployeesAvailableOnLocationTable
                                v-model="selectedEmployee"
                                :filters="employeeLocationsFilters"
                                class="mb-3"
                            />
                        </div>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-xl-6">
                                    <FormInput
                                        type="number"
                                        step=".01"
                                        label="Price"
                                        v-model="item.PRICE"
                                        @change="(_) => { discountPriceBasis = item.PRICE; }"
                                        :disabled="!['SALES', 'DELIVERY', 'RECON'].includes(comment.MOVEMENT_TYPE)"
                                        is-horizontal
                                        id-prefix="price"
                                        class="mb-md-1 mb-2"
                                        :errors="itemErrors.PRICE"
                                        input-class="form-control-sm"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        input-container-class="col-xl-8 col-md-7 col-12"
                                    />
                                    <FormSelect
                                        label="Sale Type"
                                        v-model="item.SALE_TYPE"
                                        :options="saleTypes"
                                        :option-value="(saleType) => saleType.value"
                                        :option-to-string="(saleType) => [ saleType.value, saleType.verbose ].join(' | ')"
                                        is-horizontal
                                        id-prefix="saleType"
                                        class="mb-md-1 mb-2"
                                        select-class="form-select-sm"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        select-container-class="col-xl-8 col-md-7 col-12"
                                    />
                                    <FormInput
                                        label="Receipt #"
                                        v-model="item.RECEIPT_NO"
                                        is-horizontal
                                        id-prefix="receiptNo"
                                        class="mb-md-1 mb-2"
                                        input-class="form-control-sm"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        input-container-class="col-xl-8 col-md-7 col-12"
                                        :errors="itemErrors.RECEIPT_NO"
                                    />
                                    <FormSelect
                                        label="Color"
                                        v-model="item.COLOR"
                                        :disabled="itemToMoveType == 'BARCODE'"
                                        :options="colors"
                                        required
                                        is-horizontal
                                        id-prefix="color"
                                        class="mb-md-1 mb-2"
                                        select-class="form-control-sm"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        input-container-class="col-xl-8 col-md-7 col-12"
                                        :errors="itemErrors.COLOR"
                                    />
                                </div>
                                <div class="col-xl-6">
                                    <FormInput
                                        label="Discount"
                                        step=".01"
                                        v-model="item.DISCOUNT"
                                        disabled
                                        is-horizontal
                                        id-prefix="discount"
                                        class="mb-md-1 mb-2"
                                        input-class="form-control-sm"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        input-container-class="col-xl-8 col-md-7 col-12"
                                    />
                                    <div v-if="item.SALE_TYPE == 3"
                                        class="text-end mb-3">
                                        <button type="button"
                                            class="btn btn-primary btn-sm px-2"
                                            :disabled="!previousLocation.LOCATION.startsWith('SM_')"
                                            @click="(_) => { item.DISCOUNT = anyTwoPriceBasis - item.PRICE; item.PRICE = anyTwoPriceBasis; }">
                                            <i class="icon ph-bold ph-seal-percent me-2"></i>Recalculate Discount (for SM)
                                        </button>
                                    </div>
                                    <div v-else-if="item.SALE_TYPE == 5"
                                        class="text-end mb-3">
                                        <button v-for="discountPercentage in [ 0.1, 0.2, 0.3, 0.4, 0.5, ]"
                                            class="btn btn-primary me-1 btn-sm px-1"
                                            @click="(_) => { item.PRICE = parseFloat(( discountPriceBasis - discountPriceBasis * discountPercentage ).toFixed(2));}">
                                            {{ discountPercentage * 100 }}%
                                        </button>
                                    </div>
                                    <FormSelect
                                        v-if="comment.MOVEMENT_TYPE == 'SALES' && [4, 6].includes(item.SALE_TYPE)"
                                        label="Partner Type"
                                        v-model="partnerType"
                                        :options="['BARCODE', 'NOBC']"
                                        is-horizontal
                                        id-prefix="barcodeType"
                                        class="mb-md-1 mb-2"
                                        select-class="form-select-sm"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        select-container-class="col-xl-8 col-md-7 col-12"
                                    />
                                    <div v-if="partnerType != null"
                                        class="row g-3 mb-3">
                                        <div class="col-12">
                                            <FormInput v-if="partnerType == 'BARCODE'"
                                                label="Partner Barcode"
                                                v-model="partnerItem.BARCODE"
                                                is-horizontal
                                                id-prefix="partnerBarcode"
                                                :errors="partnerItemErrors.BARCODE"
                                                class="mb-md-1 mb-2"
                                                input-class="form-control-sm"
                                                label-class="col-xl-4 col-md-5 label-sm"
                                                input-container-class="col-xl-8 col-md-7 col-12"
                                            />
                                            <FormInput v-if="partnerType == 'NOBC'"
                                                label="Partner Style"
                                                v-model="partnerItem.STYLE"
                                                is-horizontal
                                                id-prefix="partnerStyle"
                                                :errors="partnerItemErrors.STYLE"
                                                class="mb-md-1 mb-2"
                                                input-class="form-control-sm"
                                                label-class="col-xl-4 col-md-5 label-sm"
                                                input-container-class="col-xl-8 col-md-7 col-12"
                                            />
                                        </div>
                                        <div class="col-12 text-end mt-0">
                                            <button type="button"
                                                class="btn btn-sm btn-primary"
                                                @click="validatePartner">
                                                <i class="icon ph-bold ph-seal-check me-2"></i>Check Partner
                                            </button>
                                        </div>
                                    </div>
                                    <FormSelect v-if="partnerType != null"
                                        :disabled="partnerType == 'BARCODE'"
                                        label="Partner Color"
                                        v-model="partnerItem.COLOR"
                                        :options="colors"
                                        required
                                        :errors="partnerItemErrors.COLOR"
                                        is-horizontal
                                        id-prefix="partnerColor"
                                        class="mb-md-1 mb-2"
                                        select-class="form-select-sm"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        select-container-class="col-xl-8 col-md-7 col-12"
                                    />
                                    <FormInput
                                        type="number"
                                        step=".01"
                                        v-if="partnerType != null"
                                        label="Partner Price"
                                        v-model="partnerItem.PRICE"
                                        :errors="partnerItemErrors.PRICE"
                                        is-horizontal
                                        id-prefix="partnerPrice"
                                        class="mb-md-1 mb-2"
                                        input-class="form-control-sm"
                                        label-class="col-xl-4 col-md-5 label-sm"
                                        input-container-class="col-xl-8 col-md-7 col-12"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <StyleSkuPricesTable
                        :style="item.STYLE"
                        :date="item.SOLD_DT"
                    />
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-outline-danger me-2"
                            @click="(_) => { emit('close'); }">
                            <i class="icon ph-bold ph-x me-2"></i>Close
                        </button>
                        <button type="button"
                            class="btn btn-primary"
                            id="otherSelectUnifiedDoneButton"
                            @click="markBarcodeForMovement">
                            <i class="icon ph-bold ph-check-circle me-2"></i>Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useEmployees } from "@/composables/data/employees";
import { useColors } from "@/composables/data/colors";
import { useBarcodesInventory } from "@/composables/data/barcodesInventory";
import { useStylePrices } from "@/composables/data/stylePrices";
import { useSaleTypes } from "@/composables/data/saleTypes";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import EmployeesAvailableOnLocationTable from "./EmployeesAvailableOnLocationTable.vue";
import StyleSkuPricesTable from "./StyleSkuPricesTable.vue";
import moment from "moment";
import focusnext from "@/utils/focusnext";

const loadingFlags = useLoadingFlagsStore();

const { saleTypes } = useSaleTypes();
const { colors, getActiveColors } = useColors();
const { getBarcodeLatestInformation } = useBarcodesInventory();
const { getStylePrices } = useStylePrices();

const { employee: fetchedEmployee, getEmployee } = useEmployees();
const selectedEmployee = ref(null);

const props = defineProps({
    isOpen: Boolean,
    barcodeLatestLog: Object,
    itemToMoveType: String,
    comment: Object,
    previousLocation: Object,
    availableEmployeesFilters: Object,
    itemsToMove: Array,
});

const emit = defineEmits(["success", "buy-one-take-one-success", "error", "close"]);

async function fetchColors() {
    loadingFlags.add("fetchColors");
    try {
        await getActiveColors();
        colors.value = colors.value.map((color) => color.COLOR);
    } catch (e) {
        emit("error", e.message);
    }
    loadingFlags.delete("fetchColors");
}

onMounted(fetchColors);

const item = defineModel();
const partnerItem = defineModel('partnerItem');

const partnerType = ref(null);

const itemErrors = ref({});
const partnerItemErrors = ref({});

const isPartnerValid = ref(false);

// For preselected options
const soldDateRetained = defineModel("soldDateRetained");
const employeeRetained = defineModel("employeeRetained");

// For sold date validation
const soldDateMonthToEnforce = defineModel("soldDateMonthToEnforce");

// For applying any two recalculate button
const anyTwoPriceBasis = ref(null);
// For applying discount buttons (10% to 50% buttons)
const discountPriceBasis = ref(null);

const availableEmployeeDurationFilters = ref({});
const employeeLocationsFilters = computed(() => ({
    ...availableEmployeeDurationFilters.value,
    ...props.availableEmployeesFilters,
}));

const soldDateFilterTimeout = ref(null);

watch(
    () => props.isOpen,
    async () => {
        if (props.isOpen) {
            setTimeout((_) => {
                if (["SALES", "RETURN"].includes(props.comment.MOVEMENT_TYPE)) {
                    focusnext("soldDateFormInput");
                } else {
                    focusnext("otherSelectUnifiedPopup");
                }
            }, 0);

            anyTwoPriceBasis.value = item.value.PRICE;
            discountPriceBasis.value = item.value.PRICE;

            if (item.value.EMPID !== null) {
                try {
                    await getEmployee(item.value.EMPID);
                    selectedEmployee.value = fetchedEmployee.value;
                } catch (e) {
                    emit('error', e.message);
                }
            }
        }
    }
);

watch(
    selectedEmployee,
    () => {
        item.value.EMPID = selectedEmployee.value.EMPID;
        item.value.employee = selectedEmployee.value;
        employeeRetained.value = selectedEmployee.value;
    }
);

watch(
    () => item.value.SOLD_DT,
    async () => {
        if (props.comment.MOVEMENT_TYPE != "SALES") {
            return;
        }

        item.value.PRICE = null;
        
        if (soldDateFilterTimeout.value) {
            clearTimeout(soldDateFilterTimeout.value);
        }

        soldDateFilterTimeout.value = setTimeout(() => {
            availableEmployeeDurationFilters.value = {
                START_DATE: {
                    value: item.value.SOLD_DT,
                    matchMode: 'lte',
                },
                END_DATE: {
                    value: item.value.SOLD_DT,
                    matchMode: 'gte',
                },
            };
        }, 500);

        if (!item.value.SOLD_DT) {
            return;
        }
        
        try {
            const latestPrice = (
                await getStylePrices(item.value.STYLE, {
                    filters: {
                        START_DATE: {
                            value: item.value.SOLD_DT,
                            matchMode: 'lte',
                        },
                        END_DATE: {
                            value: item.value.SOLD_DT,
                            matchMode: 'gte',
                        },
                    },
                })
            )[0] ?? null;

            if (!latestPrice) {
                itemErrors.value.PRICE = ['Barcode has no price on this date.'];
                return;
            }

            item.value.PRICE = props.previousLocation.PRICE_TYPE == "S"
                ? latestPrice.SM_PRICE
                : latestPrice.LM_SALE_PRICE;

            anyTwoPriceBasis.value = item.value.PRICE;
            discountPriceBasis.value = item.value.PRICE;

            soldDateRetained.value = item.value.SOLD_DT;
            
            isPartnerValid.value = false;
        } catch (e) {
            console.log(e);
            emit('error', e.message);
        }
    }
);

watch(
    () => item.value.SALE_TYPE,
    () => {
        item.value.DISCOUNT = 0;
        partnerType.value = null;
    }
);

watch(
    partnerType,
    () => {
        partnerItem.value = {
            STYLE: null,
            DATE: null,
            SERIAL: null,
            PREV_LOC: null,
            PRICE: null,
            COLOR: null,
        };
    }
);

watch(partnerItem, (_) => {
    isPartnerValid.value = false;
});

async function validatePartner() {
    partnerItemErrors.value = {};

    isPartnerValid.value = false;

    if (![4, 6].includes(item.value.SALE_TYPE)) {
        return;
    }

    if (!item.value.SOLD_DT) {
        itemErrors.value.SOLD_DT = ['Sold date is required before checking partner.'];
        return;
    }

    if (partnerType.value == "BARCODE") {
        if (!partnerItem.value.BARCODE) {
            partnerItemErrors.value.BARCODE = ["Please specify the barcode."];
            return;
        }

        partnerItem.value.BARCODE = partnerItem.value.BARCODE.toUpperCase();

        // Must not be same as the first barcode
        if (partnerItem.value.BARCODE == item.value.BARCODE) {
            partnerItemErrors.value.BARCODE = [
                "Barcode already scanned. Please scan the partner.",
            ];
            return;
        }

        // Must not be already being moved
        if (
            props.itemsToMove
                .map((itemToMove) => itemToMove.BARCODE)
                .includes(partnerItem.value.BARCODE)
        ) {
            partnerItemErrors.value.BARCODE = [ "Barcode already inputted for movement. Please specify another." ];
            return;
        }

        try {
            const barcodeLatestInformation = await getBarcodeLatestInformation(
                partnerItem.value.BARCODE,
                item.value.SOLD_DT,
            );

            const barcodeLatestLog = barcodeLatestInformation.latest;

            if (barcodeLatestLog.comment.LOCATION != props.previousLocation.LOCATION) {
                partnerItemErrors.value.BARCODE = [
                    `Barcode previous location ${barcodeLatestLog.comment.LOCATION} ` + 
                    `does not match previous location ${props.previousLocation.LOCATION}.`
                ];
                return;
            }

            if (!barcodeLatestLog.style.style_prices.length) {
                partnerItemErrors.value.PRICE = ['Barcode has no price for this date.'];
                return;
            }

            partnerItem.value.STYLE = barcodeLatestLog.STYLE;
            partnerItem.value.DATE = barcodeLatestLog.DATE;
            partnerItem.value.SERIAL = barcodeLatestLog.SERIAL;
            partnerItem.value.COLOR = barcodeLatestLog.COLOR;
            partnerItem.value.PREV_LOC = barcodeLatestLog.comment.LOCATION;
            partnerItem.value.PRICE = props.previousLocation.PRICE_TYPE == "S"
                ? barcodeLatestLog.style.style_prices[0].SM_PRICE
                : barcodeLatestLog.style.style_prices[0].LM_SALE_PRICE;
        } catch (e) {
            console.log(e);
            if (e.status == 422) {
                partnerItemErrors.value.BARCODE = e.errors.barcode;
            }
            else if (e.status == 400) {
                partnerItemErrors.value.BARCODE = [e.message];
            }
            else if (e.status == 404) {
                partnerItemErrors.value.BARCODE = ['Barcode does not exist.'];
            }
            else {
                emit('error', e.message);
            }
            return;
        }
    } else if (partnerType.value == "NOBC") {
        try {
            const latestPrice = (await getStylePrices(partnerItem.value.STYLE, {
                rows: 0,
                filters: {
                    START_DATE: {
                        value: item.value.SOLD_DT,
                        matchMode: 'lte',
                    },
                    END_DATE: {
                        value: item.value.SOLD_DT,
                        matchMode: 'gte',
                    },
                },
            }))[0] ?? null;

            if (!latestPrice) {
                partnerItemErrors.value.PRICE = ["No price found for STYLE in this date."];
                return;
            }

            partnerItem.value.PREV_LOC = props.previousLocation.LOCATION;

            if (props.previousLocation.PRICE_TYPE == "S") {
                partnerItem.value.PRICE = latestPrice.SM_PRICE;
            } else {
                partnerItem.value.PRICE = latestPrice.LM_SALE_PRICE;
            }
        } catch (e) {
            console.log(e);
            if (e.status == 404) {
                partnerItemErrors.value.STYLE = ["No STYLE found."];
            } else {
                emit("error", e.message);
            }
            return;
        }
    }

    isPartnerValid.value = true;
    window.alert(
        "Successfully validated b1t1 partner. Prices have been set. You may proceed."
    );
}

async function markBarcodeForMovement() {
    itemErrors.value = {};
    partnerItemErrors.value = {};

    // Color is required
    if (!item.value.COLOR) {
        itemErrors.value.COLOR = ["Color is required."];
        return;
    }

    // Sales/Recon/Return sold date validation
    if (['SALES', 'RETURN', 'RECON'].includes(props.comment.MOVEMENT_TYPE)) {
        // Sold Date is required for sales and returns
        if (
            ['SALES', 'RETURN'].includes(props.comment.MOVEMENT_TYPE) &&
            !item.value.SOLD_DT
        ) {
            itemErrors.value.SOLD_DT = ['Sold Date is required.'];
            return;
        }

        // All Sold Date Validations inside
        if (item.value.SOLD_DT) {
            // Sold Date must be same month and year as COMMENT RPT DATE
            const soldDateObj = moment.utc(item.value.SOLD_DT);
            const commentRptDateObj = moment.utc(props.comment.COMMENT_RPT_DATE);

            if (
                soldDateObj.month() != commentRptDateObj.month() ||
                soldDateObj.year() != commentRptDateObj.year()
            ) {
                itemErrors.value.SOLD_DT = [`Sold date must be within month of ${commentRptDateObj.format("YYYY-MM")}.`];
                return;
            }

            // Return specific validation
            if (props.comment.MOVEMENT_TYPE == 'RETURN') {
                if (props.itemToMoveType == 'BARCODE') {
                    // Sold Date must be after the latest log's sold date
                    const latestLogSoldDateObj = moment.utc(props.barcodeLatestLog.SOLD_DT);
                    if (soldDateObj.isBefore(latestLogSoldDateObj)) {
                        itemErrors.value.SOLD_DT = [`Sold date must be equal or after previous sold date ${props.barcodeLatestLog.SOLD_DT}.`];
                        return;
                    }
                }
            }

            // Sales/Recon specific validation 
            if (['SALES', 'RECON'].includes(props.comment.MOVEMENT_TYPE)) {
                // If there is a bulk operation ongoing, then the sold date must be the same
                if (soldDateMonthToEnforce.value) {
                    const soldDateMonthToEnforceObj = moment.utc(soldDateMonthToEnforce.value);
                    if (!soldDateMonthToEnforceObj.isSame(soldDateObj, "day")) {
                        itemErrors.value.SOLD_DT = [`Sold date must be same as previous entry ${soldDateMonthToEnforce.value}.`];
                        return;
                    }
                }

                if (props.itemToMoveType == 'BARCODE') {
                    // Sold Date must be after the latest log's comment date
                    const latestLogCommentDateObj = moment.utc(props.barcodeLatestLog.comment.COMMENT_RPT_DATE);
                    if (soldDateObj.isBefore(latestLogCommentDateObj)) {
                        itemErrors.value.SOLD_DT = [`Sold date must be after previous comment date ${props.barcodeLatestLog.comment.COMMENT_RPT_DATE}.`];
                        return;
                    }
                }
            }
        }
    }

    // Sales/Recon specific validation
    if (['SALES', 'RECON'].includes(props.comment.MOVEMENT_TYPE)) {
        // Price is required
        if (!item.value.PRICE) {
            itemErrors.value.PRICE = ['Price is required.'];
            return;
        }

        // Receipt No. should have same length as RECEIPT LENGTH of location.
        if ((item.value.RECEIPT_NO?.length || 0) != (props.previousLocation.RECEIPT_LEN || 0)) {
            itemErrors.value.RECEIPT_NO = [`Receipt must be length ${props.previousLocation.RECEIPT_LEN || 0}.`];
            return;
        }
    }

    // Partner added validation (for Buy 1 Take 1)
    if ([4, 6].includes(item.value.SALE_TYPE)) {
        if (!isPartnerValid.value) {
            window.alert("Partner is not yet validated, please click on Check Partner first.");
            return;
        }

        // Price is required
        if (!partnerItem.value.PRICE) {
            partnerItemErrors.value.PRICE = ['Price is required.'];
            return;
        }

        // Color is required
        if (!partnerItem.value.COLOR) {
            partnerItemErrors.value.COLOR = ['Color is required.'];
            return;
        }

        // For Sale Type 4 (B1T1 SPLIT), prices must be same
        if (item.value.SALE_TYPE == 4 && item.value.PRICE != partnerItem.value.PRICE) {
            partnerItemErrors.value.PRICE = [ `Price must be same as partner ${item.value.PRICE}.` ];
            return;
        }
    }

    if (isPartnerValid.value) {
        let finalPriceToUse = item.value.PRICE;
        if (item.value.SALE_TYPE == 6) {
            finalPriceToUse = Math.max(
                item.value.PRICE,
                partnerItem.value.PRICE
            );
        }

        /**
         * Special cases for price in buy 1 take 1:
         * - if SM, set the price to the bag with higher price
         * - if non-SM, set the price to same as discount
         */

        item.value.DISCOUNT =
            Math.floor((finalPriceToUse / 2) * 100) / 100;
        partnerItem.value.DISCOUNT =
            Math.ceil((finalPriceToUse / 2) * 100) / 100;

        if (props.previousLocation.LOCATION.startsWith("SM_")) {
            item.value.PRICE = finalPriceToUse;
            partnerItem.value.PRICE = finalPriceToUse;
        } else {
            item.value.PRICE = item.value.DISCOUNT;
            partnerItem.value.PRICE = partnerItem.value.DISCOUNT;
        }

        emit("buy-one-take-one-success");
        return;
    }

    emit("success");

    soldDateMonthToEnforce.value = item.value.SOLD_DT;
}

function onPopupEnter() {
    focusnext("otherSelectUnifiedDoneButton");
}
</script>
