<template>
    <fieldset>
        <legend><i class="icon ph-bold ph-barcode me-2"></i>SM SKU</legend>
        <DataTable
            lazy
            :loading="loadingFlags.has('fetchSmSkus')"
            :value="styleSmSkus"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchSmSkus(); }"
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="e => { onSort(e); fetchSmSkus(); }"
            selection-mode="single"
            v-model:selection="smSku"
            table-class="table table-bordered table-hover"
            class="mb-3">
            <Column field="style"
                header="Style"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="(_) => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }"
                        class="form-control"
                        placeholder="Search id..."
                    />
                </template>
            </Column>
            <Column field="color"
                header="Color"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search color..."
                    />
                </template>
            </Column>
            <Column field="sm_sku"
                header="SM SKU"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search SM SKU..."
                    />
                </template>
            </Column>
            <Column field="created_by"
                header="Created By"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search created by..."
                    />
                </template>
            </Column>
            <template #footer>
                <div class="d-flex justify-content-between">
                    <div>
                        {{ totalRecords ?? 0 }} total records
                    </div>
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-sm btn-primary"
                            @click="exportSpreadsheet">
                            Export to Spreadsheet
                        </button>
                    </div>
                </div>
            </template>
            <template #empty>No SM Skus found.</template>
        </DataTable>
        <form @submit.prevent="!smSku ? createSmSku() : updateSmSku()">
            <FormInput
                label="Style"
                type="text"
                v-model="form.style"
                is-horizontal
                id-prefix="style"
                class="mb-md-1 mb-2"
                input-class="form-control-sm"
                :errors="errors.style"
                label-class="col-xl-4 col-md-5 label-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <div class="text-end mb-2">
                <button type="button"
                    class="btn btn-sm btn-primary"
                    @click="(_) => { isSelectStylePopupOpen = true; }">
                    Select
                </button>
            </div>
            <FormSelect
                label="Color"
                v-model="form.color"
                :options="colors"
                is-horizontal
                id-prefix="color"
                class="mb-md-1 mb-2"
                select-class="form-select-sm"
                :errors="errors.color"
                label-class="col-xl-4 col-md-5 label-sm"
                select-container-class="col-xl-8 col-md-7 col-12"
            />
            <FormInput
                label="SM SKU"
                type="text"
                v-model="form.sm_sku"
                is-horizontal
                id-prefix="smSku"
                class="mb-md-1 mb-2"
                input-class="form-control-sm"
                :errors="errors.sm_sku"
                label-class="col-xl-4 col-md-5 label-sm"
                input-container-class="col-xl-8 col-md-7 col-12"
            />
            <div class="text-end">
                <hr class="my-3" />
                <button
                    type="submit"
                    class="btn btn-primary me-1 mb-1 btn-sm"
                >
                    <i class="icon ph-bold ph-file-plus me-2"></i>{{ createOrUpdateText }}
                </button>
                <button
                    type="reset"
                    class="btn btn-danger me-1 mb-1 btn-sm"
                    :disabled="!smSku"
                    @click="deleteSmSku"
                >
                    <i class="icon ph-bold ph-x me-2"></i>Delete
                </button>
                <button
                    type="reset"
                    class="btn btn-outline-secondary me-1 mb-1 btn-sm"
                    @click="clear"
                >
                    <i class="icon ph-bold ph-x me-2"></i>Clear
                </button>
            </div>
        </form>
    </fieldset>

    <SelectStyle
        :is-open="isSelectStylePopupOpen"
        @close="(_) => { isSelectStylePopupOpen = false}"
        @select="(selectedStyle) => {
            form.style = selectedStyle.STYLE;
            isSelectStylePopupOpen = false;
        }"
    />
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useStyleSmSkus } from "@/composables/data/styleSmSkus";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import SelectStyle from "@/components/utils/style/SelectStyle.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const props = defineProps({
    style: String,
    colors: Array,
    class: String,
});

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    'style',
    'color',
    'sm_sku',
    'created_by',
]);

const {
    styleSmSkus,
    totalRecords,
    getStyleSmSkus,
    postStyleSmSku,
    putStyleSmSku,
    deleteStyleSmSku,
} = useStyleSmSkus();

const smSku = ref(null);

const form = ref({
    style: null,
    color: null,
    sm_sku: null,
});

const errors = ref({});

const fetchTimeout = ref(false);

const createOrUpdateText = computed(() => !smSku.value ? "Create" : "Update");

const isSelectStylePopupOpen = ref(false);

async function fetchSmSkus() {
    styleSmSkus.value = null;

    loadingFlags.add("fetchSmSkus");
    try {
        await getStyleSmSkus({
            ...params.value,
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchSmSkus");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchSmSkus, 800);
}

watch(
    () => props.style,
    async () => {
        filters.value.style = {
            value: props.style,
            matchMode: props.style ? 'equals' : 'contains',
        };

        form.value = {
            style: props.style,
            color: null,
            sm_sku: null,
        };
        errors.value = {};
        fetchSmSkus();
    }
);

watch(smSku, () => {
    form.value = Object.assign({
        style: props.style,
        color: null,
        sm_sku: null,
    }, smSku.value);
    errors.value = {};
});

async function createSmSku() {
    errors.value = {};

    loadingFlags.add("createSmSku");
    try {
        const newSMSku = await postStyleSmSku(form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully created SM SKU ${newSMSku.sm_sku}.`
        );
        smSku.value = newSMSku;
        fetchSmSkus();
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("createSmSku");
}

async function updateSmSku() {
    errors.value = {};

    loadingFlags.add("updateSmSku");
    try {
        const newSMSku = await putStyleSmSku(smSku.value.id, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully updated SM SKU to ${newSMSku.sm_sku}.`
        );
        smSku.value = newSMSku;
        fetchSmSkus();
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateSmSku");
}

async function deleteSmSku() {
    if (!window.confirm(`Are you sure you want to delete SM SKU ${smSku.value.sm_sku}?`)) {
        return;
    }

    errors.value = {};

    loadingFlags.add("deleteSmSku");
    try {
        await deleteStyleSmSku(smSku.value.id, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully deleted SM SKU ${smSku.value.sm_sku}.`
        );
        smSku.value = null;
        fetchSmSkus();
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("deleteSmSku");
}

function clear() {
    form.value = {
        style: props.style,
        color: null,
        sm_sku: null,
    };
    smSku.value = null;
}

onMounted(() => {
    multiSortMeta.value = [
        {
            field: 'style',
            order: 1
        }
    ];
    onSort();
    fetchSmSkus();
});

function exportSpreadsheet() {
    window.open(route('api.style-sm-skus.spreadsheet.show', {
        filters: params.value.filters,
        multiSortMeta: params.value.multiSortMeta,
    }));
}
</script>
