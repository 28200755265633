<template>
    <div class="row g-3">
        <div class="col-md-4">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i> Select Location
                </legend>
                <LocationTable
                    :hide-properties="new Set(['TYPE', 'PRICE_TYPE'])"
                    v-model="selectedLocation"
                    :refresh-flag="tableRefreshFlag"
                />
            </fieldset>
        </div>
        <div class="col-md-4">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-map-pin-area me-2"></i> Update Details
                </legend>
                <FormInput
                    type="text"
                    v-model="location.LOCATION_KEY2"
                    label="Location Key2"
                    id-prefix="locationKey2"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
                <FormInput
                    type="text"
                    v-model="location.LOCATION_KEY3"
                    label="Location Key3"
                    id-prefix="locationKey3"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
                <FormInput
                    type="text"
                    v-model="location.COMPANY_NAME"
                    label="Company Name"
                    id-prefix="companyName"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
                <FormInput
                    type="text"
                    v-model="location.OFFICE_ADDRESS"
                    label="Office Address"
                    id-prefix="officeAddress"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
                <FormInput
                    type="text"
                    v-model="location.STORE_CLASS"
                    label="Store Class"
                    id-prefix="storeClass"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
                <FormInput
                    type="text"
                    v-model="location.STORE_CODE"
                    label="Store Code"
                    id-prefix="storeCode"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-md-5 label-sm"
                    input-class="form-control-sm"
                    input-container-class="col-xl-8 col-md-7 col-12"
                />
                <div class="text-end mt-3">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="!selectedLocation || isUpdating"
                        @click="saveLocation"
                    >
                        <span
                            v-if="isUpdating"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        >
                        </span>
                        <i class="icon ph-bold ph-pencil-simple-line me-2"></i>Update
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";

import { useFlashMessages } from "@/composables/flashmessages";
import { useLocations } from "@/composables/data/locations";

import FormInput from "@/components/utils/FormInput.vue";
import LocationTable from "@/components/utils/tables/LocationTable.vue";

import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const { updateLocation } = useLocations();

const tableRefreshFlag = ref(false);

const selectedLocation = ref(null);
const location = ref({
    LOCATION_KEY2: null,
    LOCATION_KEY3: null,
    COMPANY_NAME: null,
    OFFICE_ADDRESS: null,
    STORE_CLASS: null,
    STORE_CODE: null,
});

const isUpdating = ref(false);

watch(selectedLocation, () => {
    location.value = {
        LOCATION: selectedLocation.value?.LOCATION,
        LOCATION_KEY2: selectedLocation.value?.LOCATION_KEY2,
        LOCATION_KEY3: selectedLocation.value?.LOCATION_KEY3,
        COMPANY_NAME: selectedLocation.value?.COMPANY_NAME,
        OFFICE_ADDRESS: selectedLocation.value?.OFFICE_ADDRESS,
        STORE_CLASS: selectedLocation.value?.STORE_CLASS,
        STORE_CODE: selectedLocation.value?.STORE_CODE,
    };
});

async function saveLocation() {
    isUpdating.value = true;
    try {
        await updateLocation(location.value.LOCATION, location.value);
        addFlashMessage("SUCCESS", "Successfully updated location.");
        tableRefreshFlag.value = !tableRefreshFlag.value;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
    isUpdating.value = false;
}
</script>
