<template>
    <DataTable
        :value="joborderReports"
        paginator
        :rows="10"
        :pt="{ table: { class: 'table table-bordered table-hover' } }"
    >
        <Column
            field="TIME_PERIOD"
            header="Time Period"
        />
        <Column
            field="START_DATE"
            header="Start Date"
        />
        <Column
            field="END_DATE"
            header="End Date"
        />
        <Column
            field="DEPT"
            header="Department"
        />
        <Column
            field="EMPID"
            header="Employee ID"
        />
        <Column
            field="FNAME"
            header="First Name"
        />
        <Column
            field="LNAME"
            header="Last Name"
        />
        <Column
            field="GL_ID"
            header="GL ID"
        />
        <Column
            field="JONO"
            header="Jono"
        />
        <Column
            field="STYLE"
            header="Style"
        />
        <Column
            field="RATE"
            header="Rate"
        />
        <Column
            field="DATE"
            header="Date"
        />
        <Column
            field="SUM_HOURS"
            header="Hours"
        />
        <Column
            field="SUM_COST"
            header="Cost"
        />
        <template #empty>
            <div class="text-center">No data yet.</div>
        </template>
        <template #footer>
            <div class="text-end">
                <p><b>Total Hours:</b> {{ totalHours.toFixed(2) }}</p>
                <p><b>Total Cost:</b> {{ totalCost.toFixed(2) }}</p>
            </div>
        </template>
    </DataTable>
    <div class="text-end">
        <button
            type="button"
            class="btn btn-sm btn-primary"
            :disabled="!fromTimePeriodId || !toTimePeriodId || !jonoContains"
            @click="exportSpreadsheet"
        >
            <i class="icon ph-bold ph-table"></i>Export Spreadsheet
        </button>
    </div>
</template>

<script setup>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { useJoborderReports } from '@/composables/data/joborderReports';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { computed, watch } from 'vue';

const props = defineProps({
    fromTimePeriodId: Number,
    toTimePeriodId: Number,
    jonoContains: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { joborderReports, getJoborderReports } = useJoborderReports();

const totalHours = computed(
    () => joborderReports.value?.reduce(
        (totalHours, joborderReport) => totalHours + joborderReport.SUM_HOURS,
        0
    ) ?? 0
);

const totalCost = computed(
    () => joborderReports.value?.reduce(
        (totalCost, joborderReport) => totalCost + joborderReport.SUM_COST,
        0
    ) ?? 0
);

async function fetchJoborderReports() {
    loadingFlags.add("fetchJoborderReports");
    try {
        await getJoborderReports({
            from_time_period: props.fromTimePeriodId,
            to_time_period: props.toTimePeriodId,
            jono: {
                matchMode: 'contains',
                value: props.jonoContains,
            }
        });
    } catch (e) {
        toasts.add(
            'ERROR',
            'Error',
            e.message
        );
    }
    loadingFlags.delete("fetchJoborderReports");
}

watch(() => [props.fromTimePeriodId, props.toTimePeriodId, props.jonoContains], fetchJoborderReports);

function exportSpreadsheet() {
    window.open(route("api.joborders.reports.spreadsheet.show", {
        from_time_period: props.fromTimePeriodId,
        to_time_period: props.toTimePeriodId,
        jono: {
            matchMode: 'contains',
            value: props.jonoContains,
        },
    }));
}
</script>
