<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-barcode me-2"></i>Mark Missing Barcodes as LOST
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <div class="row mb-3">
                        <div class="col-xl-6">
                            <div class="row g-3 my-3">
                                <div class="col-md-6">
                                    <FormInput
                                        type="text"
                                        v-model="timePeriodDisplay"
                                        disabled
                                        label="Time Period"
                                        id-prefix="timePeriodDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                    <FormInput
                                        type="text"
                                        v-model="locationDisplay"
                                        disabled
                                        label="Location"
                                        id-prefix="locationDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <FormInput
                                        type="text"
                                        v-model="timePeriodStartDateDisplay"
                                        disabled
                                        label="Start Date"
                                        id-prefix="timePeriodStartDateDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                    <FormInput
                                        type="text"
                                        v-model="timePeriodEndDateDisplay"
                                        disabled
                                        label="End Date"
                                        id-prefix="timePeriodEndDateDisplay"
                                        is-horizontal
                                        class="mb-md-1 mb-2"
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <ReconLostCommentsTable
                                :start-date="timePeriod?.START_DATE"
                                :end-date="timePeriod?.END_DATE"
                                v-model="selectedComment"
                                @error="(message) => { emit('error', message); }"
                                class="mb-3"
                            />
                        </div>
                    </div>
                    <FlashMessageAlerts
                        :messages="flashMessages"
                        :dismissible="true"
                    />
                    <DataTable
                        :loading="isFetchingMissingBarcodes"
                        :value="missingBarcodes"
                        paginator
                        :rows="10"
                        v-model:filters="filters"
                        filter-display="row"
                        v-model:selection="selectedBarcodes"
                        :pt="{ table: { class: 'table table-sm table-bordered table-hover recon-table' } }">
                        <Column
                            selectionMode="multiple"
                            headerStyle="width: 3rem">
                        </Column>
                        <Column
                            field="STYLE"
                            header="Style"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="DATE"
                            header="Date"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="SERIAL"
                            header="Serial"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="COLOR"
                            header="Color"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="SEQ_NO"
                            header="Seq"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="COMMENT_RPT_DATE"
                            header="Comment Date"
                            sortable
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }">
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <template #footer>
                            {{ missingBarcodes?.length ?? 0 }} total barcodes.
                        </template>
                        <template #empty>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-database me-2"></i>No data yet.
                            </div>
                        </template>
                    </DataTable>
                    <div class="text-end py-4">
                        <button type="button"
                            class="btn btn-danger"
                            :disabled="
                                !selectedComment ||
                                selectedBarcodes?.length == 0 ||
                                isDeleting
                            "
                            @click="markAllAsLost">
                            <span v-if="isDeleting"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-trash me-2"></i>Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useTimePeriods } from "@/composables/data/timePeriods";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventory } from "@/composables/data/inventory";
import { useReconActions } from "@/composables/data/reconActions";
import ReconLostCommentsTable from "./ReconLostCommentsTable.vue";
import FormInput from "@/components/utils/FormInput.vue";
import FlashMessageAlerts from "@/components/utils/FlashMessageAlerts.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    timePeriod: Number,
    location: String,
    inventoryDone: String,
    isOpen: Boolean,
    class: String,
});

const emit = defineEmits(["close", "done", "error"]);

const flashMessages = ref([]);

const { timePeriod, getTimePeriod } = useTimePeriods();

const timePeriodDisplay = computed(() => timePeriod.value?.TIME_PERIOD);
const locationDisplay = computed(() => props.location);
const timePeriodStartDateDisplay = computed(() => timePeriod.value?.START_DATE);
const timePeriodEndDateDisplay = computed(() => timePeriod.value?.END_DATE);

const selectedComment = ref(null);

const isFetchingMissingBarcodes = ref(false);

const { filters } = useDataTableParams([
    "STYLE",
    "DATE",
    "SERIAL",
    "COLOR",
    "SEQ_NO",
    "COMMENT_RPT_DATE",
]);

const { missingBarcodes, getMissingBarcodesInLocation } = useInventory();

const { postBarcodeMultipleMissingDeletion } = useReconActions();

const selectedBarcodes = ref(null);

const isDeleting = ref(false);

async function fetchTimePeriod() {
    timePeriod.value = null;

    if (!props.timePeriod) {
        return;
    }

    try {
        await getTimePeriod(props.timePeriod);
    } catch (e) {
        emit("error", e.message);
    }
}

async function fetchMissingBarcodes() {
    missingBarcodes.value = null;

    if (!props.location || !props.inventoryDone) {
        return;
    }

    isFetchingMissingBarcodes.value = true;
    try {
        await getMissingBarcodesInLocation(
            props.location,
            props.timePeriod,
            props.inventoryDone
        );
    } catch (e) {
        emit("error", e.message);
    }
    isFetchingMissingBarcodes.value = false;
}

watch(() => props.timePeriod, fetchTimePeriod);
watch(() => props.location, fetchMissingBarcodes);
watch(
    () => props.isOpen,
    () => {
        selectedComment.value = null;
        selectedBarcodes.value = null;
        if (props.isOpen) {
            fetchMissingBarcodes();
        }
    }
);

async function markAllAsLost() {
    isDeleting.value = true;
    try {
        const response = await postBarcodeMultipleMissingDeletion(
            selectedComment.value.COMMENT_ID,
            selectedBarcodes.value.map((reconRawFull) => reconRawFull.id)
        );
        emit("done", response.data.message);
        flashMessages.value = [];
    } catch (e) {
        flashMessages.value.push({ type: "ERROR", content: e.message });
    }
    isDeleting.value = false;
}
</script>
