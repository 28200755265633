<template>

    <div class="row mb-3">
        <div class="col-12">
            <form @submit.prevent="saveNewBarcodeInput">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-keyboard me-2"></i>Input Styles
                    </legend>
                    <div class="row input-style-container">
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="text"
                                label="Style"
                                v-model="newBarcodeInputForm.style"
                                id-prefix="style"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="newBarcodeInputFormErrors.style"
                                @keydown-enter="focusNextInput('colorFormInput')"
                            />
                        </div>
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="text"
                                label="Color"
                                v-model="newBarcodeInputForm.color"
                                id-prefix="color"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="newBarcodeInputFormErrors.color"
                                @keydown-enter="focusNextInput('dateFormInput')"
                            />
                        </div>
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="year"
                                label="Date"
                                v-model="newBarcodeInputForm.date"
                                id-prefix="date"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="newBarcodeInputFormErrors.date"
                                @keydown-enter="focusNextInput('countFormInput')"
                            />
                        </div>
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="number"
                                label="Count"
                                v-model="newBarcodeInputForm.count"
                                id-prefix="count"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="newBarcodeInputFormErrors.count"
                                @keydown-enter="focusNextInput('priceFormInput')"
                            />
                        </div>
                        <div class="col mb-md-0 mb-2">
                            <FormInput
                                type="number"
                                step=".02"
                                label="Price"
                                v-model="newBarcodeInputForm.price"
                                id-prefix="price"
                                class="mb-3"
                                input-class="form-control-sm"
                                :errors="newBarcodeInputFormErrors.price"
                                @keydown-enter="focusNextInput('enterToTableButton')"
                            />
                        </div>
                    </div>
                    <hr class="my-4" />
                    <div class="row g-2">
                        <div class="col-md-8 col-lg-4">
                            <div class="input-group input-group-sm">
                                <input type="file" accept=".xlsx,.xls"
                                    ref="barcodesToGenerateExcelFileInput"
                                    @change="e => { barcodesToGenerateExcelFile = e.target.files[0]; }"
                                    class="form-control form-control-sm" />
                                <button type="button"
                                    class="btn btn-sm btn-primary"
                                    @click="importExcelFileAsNewBarcodeInputs">
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Import .xlsx
                                </button>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-8 text-end">
                            <button type="button"
                                class="btn btn-sm btn-primary me-2"
                                @click="generateEmptySpreadsheet">
                                <i class="icon ph-bold ph-file-xls me-2"></i>Generate Empty Spreadsheet
                            </button>
                            <button type="submit"
                                class="btn btn-sm btn-primary"
                                id="enterToTableButton">
                                <i class="icon ph-bold ph-table me-2"></i>Enter to Table
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-xl-6">
            <fieldset class="h-100 d-flex flex-column justify-content-between">
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>Barcodes to Generate
                </legend>
                <NewBarcodeInputsTable :refresh-flag="refreshFlag" />
                <div>
                    <hr class="my-4">
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-sm btn-outline-danger me-2 mb-1"
                            @click="clearBarcodesToGenerate">
                            <i class="icon ph-bold ph-x me-2"></i>Clear
                        </button>
                        <button type="button"
                            class="btn btn-sm btn-primary me-2 mb-1"
                            @click="generateBarcodes">
                            <i class="icon ph-bold ph-barcode me-2"></i>Generate Barcodes
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-6">
            <fieldset class="h-100 d-flex flex-column justify-content-between">
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>Generated Barcodes
                </legend>
                <NewBarcodeOutputsTable :refresh-flag="refreshFlag" />
                <div>
                    <hr class="my-4" />
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-sm btn-outline-danger me-2 mb-1"
                            @click="clearGeneratedBarcodes">
                            <i class="icon ph-bold ph-x me-2"></i>Clear
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>

    <ImporContentErrorsPopup :is-open="isImportContentErrorsPopupOpen"
        @close="(_) => { isImportContentErrorsPopupOpen = false; }"
        :errors="importContentErrors" />
</template>

<script setup>
import { ref } from 'vue';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useNewBarcodeInputs } from '@/composables/data/newBarcodeInputs';
import { useNewBarcodeOutputs } from '@/composables/data/newBarcodeOutputs';
import FormInput from '@/components/utils/FormInput.vue';
import NewBarcodeInputsTable from '@/components/utils/tables/NewBarcodeInputsTable.vue';
import NewBarcodeOutputsTable from '@/components/utils/tables/NewBarcodeOutputsTable.vue';
import ImporContentErrorsPopup from './ImportContentErrorsPopup.vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const {
    postNewBarcodeInput,
    postNewBarcodeInputMovements,
    postNewBarcodeInputClearance,
    postNewBarcodeInputSpreadsheetImportation,
} = useNewBarcodeInputs();

const { postNewBarcodeOutputClearance } = useNewBarcodeOutputs();

const newBarcodeInputForm = ref({
    style: null,
    date: null,
    color: null,
    count: null,
    price: 0,
});

const newBarcodeInputFormErrors = ref({});

const barcodesToGenerateExcelFileInput = ref(null);
const barcodesToGenerateExcelFile = ref(null);

const importContentErrors = ref(null);
const isImportContentErrorsPopupOpen = ref(false);

const refreshFlag = ref(false);

async function saveNewBarcodeInput() {
    newBarcodeInputFormErrors.value = {};

    loadingFlags.add("saveNewBarcodeInput");
    try {
        await postNewBarcodeInput(newBarcodeInputForm.value);
        toasts.add("SUCCESS", "Success", "Successfully saved style for barcode generation.");
        newBarcodeInputForm.value = {
            style: null,
            date: null,
            color: null,
            count: null,
            price: 0,
        };
        refreshFlag.value = !refreshFlag.value;
    } catch(e) {
        newBarcodeInputFormErrors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("saveNewBarcodeInput");
}

async function importExcelFileAsNewBarcodeInputs() {
    importContentErrors.value = null;

    loadingFlags.add("importExcelFileAsNewBarcodeInputs");
    try {
        const formData = new FormData();
        formData.append("spreadsheet", barcodesToGenerateExcelFile.value);
        await postNewBarcodeInputSpreadsheetImportation(formData);
        toasts.add("SUCCESS", "Success", "Successfully imported spreadsheet.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        if (e.errors?.content) {
            importContentErrors.value = e.errors.content.map((rowErrors) => {
                return Object.entries(rowErrors.errors).map(([field, errors]) => ({
                    row: rowErrors.row,
                    field,
                    message: errors.join(". ")
                }));
            }).reduce((importContentErrors, rowErrors) => [...importContentErrors, ...rowErrors], []);
            isImportContentErrorsPopupOpen.value = true;
        }
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("importExcelFileAsNewBarcodeInputs");
}

async function generateBarcodes() {
    loadingFlags.add("generateBarcodes");
    try {
        await postNewBarcodeInputMovements();
        toasts.add("SUCCESS", "Success", "Successfully generated barcodes.");
        refreshFlag.value = !refreshFlag.value;
    } catch(e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("generateBarcodes");
}

async function clearBarcodesToGenerate() {
    loadingFlags.add("clearBarcodesToGenerate");
    try {
        await postNewBarcodeInputClearance();
        toasts.add("SUCCESS", "Success", "Successfully cleared barcodes to generate.");
        refreshFlag.value = !refreshFlag.value;
    } catch(e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("clearBarcodesToGenerate");
}

async function clearGeneratedBarcodes() {
    loadingFlags.add("clearGeneratedBarcodes");
    try {
        await postNewBarcodeOutputClearance();
        toasts.add("SUCCESS", "Success", "Successfully cleared generated barcodes.");
        refreshFlag.value = !refreshFlag.value;
    } catch(e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("clearGeneratedBarcodes");
}

function generateEmptySpreadsheet() {
    window.open(route("api.new-barcode-inputs.spreadsheet-format.show"));
}
</script>
