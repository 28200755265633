<template>
    <div class="row g-3 mb-3">
        <div class="col-xl-4">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                </legend>
                <FormSelect
                    v-model="paytype"
                    :options="['BIMONTHLY', 'WEEKLY']"
                    label="Select Paytype"
                    id-prefix="paytype"
                    class="mb-md-1 mb-2"
                    is-horizontal
                    label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                    select-class="form-select-sm"
                    select-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                />
                <TimePeriodTable
                    v-model="timePeriod"
                    show-from-current-date
                    :paytype="paytype ?? 'BIMONTHLY'"
                    :hide-properties="new Set(['STATUS'])"
                    :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                />
            </fieldset>
        </div>
        <div class="col-xl-8">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-funnel-simple me-2"></i>Filters
                </legend>
                <div class="row g-3 mb-3">
                    <div class="col-md-4">
                        <div class="d-flex flex-row">
                            <Checkbox
                                v-model="showNonResigned"
                                binary
                                inputId="showNonResignedInput"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showNonResignedInput">Not RESIGNED</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-row">
                            <Checkbox
                                v-model="showEmptyTIN"
                                binary
                                inputId="showEmptyTINInput"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showEmptyTINInput">Empty TIN</label>
                        </div>
                    </div>
                </div>
                <hr class="my-4" />
                <div class="row g-3 mb-sm-5 mb-3">
                    <div class="col-xl-4 col-lg-5 col-sm-6 col-12">
                        <div class="d-flex flex-row">
                            <RadioButton
                                v-model="showNoSSSContribution"
                                :value="true"
                                inputId="showNoSSSContributionTrue"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showNoSSSContributionTrue">Show No SSS Contribution</label>
                        </div>
                        <div class="d-flex flex-row mb-1">
                            <RadioButton
                                v-model="showNoSSSContribution"
                                :value="false"
                                inputId="showNoSSSContributionFalse"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showNoSSSContributionFalse">Show W/ SSS Contribution</label>
                        </div>
                        <Button
                            type="button"
                            class="btn btn-sm btn-outline-danger"
                            @click="
                                (_) => {
                                    showNoSSSContribution = null;
                                    fetchEmployees();
                                }
                            ">
                            <i class="icon ph-bold ph-x-circle me-2"></i>Remove Filter
                        </Button>
                    </div>
                    <div class="col-xl-4 col-lg-5 col-sm-6 col-12">
                        <div class="d-flex flex-row">
                            <RadioButton
                                v-model="showNoPhilhealthContribution"
                                :value="true"
                                inputId="showNoPhilhealthContributionTrue"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showNoPhilhealthContributionTrue">Show No PH Contribution</label>
                        </div>
                        <div class="d-flex flex-row mb-1">
                            <RadioButton
                                v-model="showNoPhilhealthContribution"
                                :value="false"
                                inputId="showNoPhilhealthContributionFalse"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showNoPhilhealthContributionFalse">Show W/ PH Contribution</label>
                        </div>
                        <Button type="button"
                            class="btn btn-sm btn-outline-danger"
                            @click="
                                (_) => {
                                    showNoPhilhealthContribution = null;
                                    fetchEmployees();
                                }
                            ">
                            <i class="icon ph-bold ph-x-circle me-2"></i>Remove
                            Filter
                        </Button>
                    </div>
                </div>
                <div class="row g-3">
                    <div class="col-xl-4 col-lg-5 col-sm-6 col-12">
                        <div class="d-flex flex-row">
                            <RadioButton
                                v-model="showNoPagibigContribution"
                                :value="true"
                                inputId="showNoPagibigContributionTrue"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showNoPagibigContributionTrue">Show No PAGIBIG Contribution</label>
                        </div>
                        <div class="d-flex flex-row mb-1">
                            <RadioButton
                                v-model="showNoPagibigContribution"
                                :value="false"
                                inputId="showNoPagibigContributionFalse"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showNoPagibigContributionFalse">Show W/ PAGIBIG Contribution</label>
                        </div>
                        <Button type="button"
                            class="btn btn-sm btn-outline-danger"
                            @click="
                                (_) => {
                                    showNoPagibigContribution = null;
                                    fetchEmployees();
                                }
                            ">
                            <i class="icon ph-bold ph-x-circle me-2"></i>Remove Filter
                        </Button>
                    </div>
                    <div class="col-xl-4 col-lg-5 col-sm-6 col-12">
                        <div class="d-flex flex-row">
                            <RadioButton
                                v-model="showNoWTX"
                                :value="true"
                                inputId="showNoWTXTrue"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showNoWTXTrue">Show No WTX</label>
                        </div>
                        <div class="d-flex flex-row mb-1">
                            <RadioButton
                                v-model="showNoWTX"
                                :value="false"
                                inputId="showNoWTXFalse"
                                @change="fetchEmployees"
                            />
                            <label class="ms-1" for="showNoWTXFalse">Show W/ WTX</label>
                        </div>
                        <Button type="button"
                            class="btn btn-sm btn-outline-danger"
                            @click="
                                (_) => {
                                    showNoWTX = null;
                                    fetchEmployees();
                                }
                            ">
                            <i class="icon ph-bold ph-x-circle me-2"></i>Remove Filter
                        </Button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-user me-2"></i>Employees Table
        </legend>
        <DataTable :loading="loadingFlags.has('fetchEmployees')"
            lazy
            :value="employees"
            :total-records="totalRecords"
            paginator
            :rows="10"
            class="employee-expansion"
            @page="
                (e) => {
                    onPage(e);
                    fetchEmployees();
                }
            "
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            v-model:expanded-rows="expandedRows"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column expander style="width: 2rem" />
            <Column
                field="EMPID"
                header="ID"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PAYTYPE"
                header="Paytype"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="FNAME"
                header="First Name"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="MNAME"
                header="Middle Name"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="LNAME"
                header="Last Name"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="GENDER"
                header="Gender"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' }
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PRESENT_ADDRESS"
                header="Present Address"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' }
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="STATUS"
                header="Status"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' }
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="DEPT"
                header="Department"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' }
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="TITLE"
                header="Title"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="HIREDATE"
                header="Hiredate"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="LASTDATE"
                header="Last Date"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' }
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column field="SSS" header="SSS" :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' } }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PHILHEALTH"
                header="Philhealth"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="PAGIBIG"
                header="PAGIBIG"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="TIN"
                header="TIN"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #expansion="{ data }">
                <h6 class="title">
                    Rate Information for {{ data.FNAME }} {{ data.LNAME }}
                    <template v-if="!timePeriod">(Latest = '9999-12-31')</template>
                    <template v-else>(as of {{ timePeriod.END_DATE }})</template>
                </h6>
                <hr />
                <div class="row g-3">
                    <div class="col-md-4">
                        <h6>Main Information</h6>
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Field</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-end">
                                    <th>Department</th>
                                    <td>{{ data.employee_rate?.DEPT ?? "N/A" }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>Company Code</th>
                                    <td>{{ data.employee_rate?.COMPANY_CODE ?? "N/A" }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>Days of Work</th>
                                    <td>{{ data.employee_rate?.DAYSOFWORK ?? "N/A" }} </td>
                                </tr>
                                <tr class="text-end">
                                    <th>Rate</th>
                                    <td>{{ data.employee_rate?.RATE ?? "N/A" }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-4">
                        <h6>Deductions</h6>
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Field</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-end">
                                    <th>Philhealth Contribution</th>
                                    <td>{{ data.employee_rate ?.PH_CONTRIBUTION ?? "N/A" }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>PAGIBIG Contribution</th>
                                    <td>{{ data.employee_rate ?.PAGIBIG_CONTRIBUTION ?? "N/A" }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>SSS Contribution</th>
                                    <td>{{ data.employee_rate ?.SSS_CONTRIBUTION ?? "N/A" }}</td>
                                </tr>
                                <tr class="text-end">
                                    <th>WTX</th>
                                    <td>{{ data.employee_rate?.WTX ?? "N/A" }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
            <template #footer>
                {{ totalRecords ?? 0 }} total employees
            </template>
        </DataTable>
        <div class="text-end">
            <Button class="btn btn-primary btn-sm" @click="exportSpreadsheet">
                <i class="icon ph-bold ph-file-xls me-2"></i>Export to Spreadsheet
            </Button>
        </div>
    </fieldset>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useFlashMessages } from '@/composables/flashmessages';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployees } from '@/composables/data/employees';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import FormSelect from '@/components/utils/FormSelect.vue';
import TimePeriodTable from '@/components/utils/tables/TimePeriodTable.vue';
import moment from 'moment';
import scrollToTop from '@/utils/scrollToTop';

const { addFlashMessage } = useFlashMessages();

const paytype = ref(null);
const timePeriod = ref(null);

const { params, filters, onPage, onFilter } = useDataTableParams([
    'EMPID',
    'PAYTYPE',
    'FNAME',
    'MNAME',
    'LNAME',
    'GENDER',
    'PRESENT_ADDRESS',
    'STATUS',
    'DEPT',
    'TITLE',
    'HIREDATE',
    'LASTDATE',
    'SSS',
    'PHILHEALTH',
    'PAGIBIG',
    'TIN',
]);

const { employees, totalRecords, getEmployees } = useEmployees();

const expandedRows = ref(null);

const loadingFlags = useLoadingFlagsStore();

const fetchTimeout = ref(false);

const showNonResigned = ref(false);
const showEmptyTIN = ref(false);
const showNoSSSContribution = ref(null);
const showNoPhilhealthContribution = ref(null);
const showNoPagibigContribution = ref(null);
const showNoWTX = ref(null);

const hardFilters = computed(() => ({
    ...(showNonResigned.value ? {
        DEPT: {
            value: 'RESIGNED',
            matchMode: 'notEquals'
        }
    } : {}),
    ...(showEmptyTIN.value ?  {
        TIN: {
            value: '--placeholder--',
            matchMode: 'isEmpty'
        }
    } : {}),
    ...(timePeriod.value != null ? {
        PAYTYPE: {
            value: timePeriod.value.PAYTYPE,
            matchMode: 'equals',
        },
        'employeeRates.START_DATE': {
            value: timePeriod.value.END_DATE,
            matchMode: 'lte'
        },
        'employeeRates.END_DATE': {
            value: timePeriod.value.END_DATE,
            matchMode: 'gte'
        },
    } : {}),
    ...(showNoSSSContribution.value != null ||
        showNoPhilhealthContribution.value != null ||
        showNoPagibigContribution.value != null ||
        showNoWTX.value != null ?
            timePeriod.value == null ? {
                'employeeRates.END_DATE': {
                    value: '9999-12-31',
                    matchMode: 'equals'
                }
            } : {} : {}),
    ...(showNoSSSContribution.value != null ? {
        'employeeRates.SSS_CONTRIBUTION': {
            value: 0,
            matchMode: showNoSSSContribution.value ? 'equals' : 'notEquals'
        }
    } : {}),
    ...(showNoPhilhealthContribution.value != null ? {
        'employeeRates.PH_CONTRIBUTION': {
            value: 0,
            matchMode: showNoPhilhealthContribution.value ? 'equals' : 'notEquals'
        }
    } : {}),
    ...(showNoPagibigContribution.value != null ? {
        'employeeRates.PAGIBIG_CONTRIBUTION': {
            value: 0,
            matchMode: showNoPagibigContribution.value ? 'equals' : 'notEquals'
        }
    } : {}),
    ...(showNoWTX.value != null ? {
        'employeeRates.WTX': {
            value: 0,
            matchMode: showNoWTX.value ? 'equals' : 'notEquals'
        }
    } : {}),
}));

async function fetchEmployees() {
    employees.value = null;

    loadingFlags.add('fetchEmployees');
    try {
        await getEmployees({
            with: 'employeeRates',
            ...params.value,
            hardFilters: hardFilters.value
        });

        employees.value = employees.value.map(employee => {
            return {
                ...employee,
                employee_rate: timePeriod.value != null ?
                    (employee.employee_rates.filter(rate => {
                        const rateStartDate = moment(rate.START_DATE);
                        const rateEndDate = moment(rate.END_DATE);
                        const timePeriodEndDate = moment(timePeriod.value.END_DATE);

                        return timePeriodEndDate.isSameOrAfter(rateStartDate) &&
                            timePeriodEndDate.isSameOrBefore(rateEndDate);
                    })[0] ?? null) :
                    (employee.employee_rates.filter(rate => rate.END_DATE == '9999-12-31')[0] ?? null)
            }
        });
    } catch(e) {
        addFlashMessage('ERROR', e.message);
        scrollToTop();
    }
    loadingFlags.delete('fetchEmployees');
}

function tableOnFilter(e) {
    onFilter(e);
    if(fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchEmployees, 300);
}

onMounted(fetchEmployees);

watch(timePeriod, fetchEmployees);

function exportSpreadsheet() {
    window.open(route('api.employees.general-employee-spreadsheet.show', {
        filters: filters.value,
        hardFilters: hardFilters.value,
    }));
}
</script>
