<template>
    <fieldset :class="class">
        <legend><i class="icon ph-bold ph-barcode me-2"></i>Style KCC</legend>
        <form>
            <FormInput
                label="KCC SKU"
                type="text"
                v-model="form.KCC_SKU"
                is-horizontal
                id-prefix="kccSku"
                class="mb-2"
                input-class="form-control-sm"
                :errors="errors.KCC_SKU"
            />
            <div class="text-end">
                <hr class="my-3" />
                <button type="button"
                    class="btn btn-sm btn-primary me-1"
                    :disabled="!style"
                    @click="updateKCCSku">
                    <i class="icon ph-bold ph-floppy-disk me-2"></i>Save
                </button>
            </div>
        </form>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useStyleKCCSkus } from "@/composables/data/styleKCCSkus";

import FormInput from "@/components/utils/FormInput.vue";

const props = defineProps({
    style: String,
    class: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { styleKCCSku, getStyleKCCSku, putStyleKCCSku } = useStyleKCCSkus();

const form = ref({
    KCC_SKU: null,
});

const errors = ref({});

watch(
    () => props.style,
    async () => {
        errors.value = {};
        styleKCCSku.value = null;
        form.value = {
            KCC_SKU: null,
        };

        if (!props.style) {
            return;
        }

        loadingFlags.add("fetchStyleKCCSku");
        try {
            await getStyleKCCSku(props.style);
            form.value = styleKCCSku.value;
        } catch (e) {
            if (e.status != 404) {
                toasts.add('ERROR', 'Error', e.message);
            }
        }
        loadingFlags.delete("fetchStyleKCCSku");
    }
);

async function updateKCCSku() {
    errors.value = {};

    loadingFlags.add("updateKCCSku");
    try {
        const newKCCSku = await putStyleKCCSku(props.style, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully updated KCC SKU to ${newKCCSku.KCC_SKU}.`
        );
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateKCCSku");
}
</script>
