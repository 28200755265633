import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useSamples() {
    const samples = ref(null);
    const sample = ref(null);
    const totalRecords = ref(null);

    const getSamples = async (params) => {
        try {
            const samplesResponse = await axios.get(route('api.samples.index', params));
            samples.value = samplesResponse.data.data;
            totalRecords.value = samplesResponse.data.meta.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching samples.');
        }
    };

    const getSample = async (sampleId, params) => {
        try {
            const sampleResponse = await axios.get(route('api.samples.show', {
                sample: sampleId,
                ...params
            }));
            sample.value = sampleResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching sample.');
        }
    };

    const postSample = async (sample) => {
        try {
            const sampleResponse = await axios.post(route('api.samples.store'), sample);
            return sampleResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while saving sample.');
        }
    };

    const putSample = async (sampleId, sample) => {
        try {
            const sampleResponse = await axios.put(route('api.samples.update', {
                sample: sampleId
            }), sample);
            return sampleResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating sample.');
        }
    };
    
    return {
        samples,
        totalRecords,
        sample,
        getSamples,
        getSample,
        postSample,
        putSample
    };
}
