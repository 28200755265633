<template>
    <div class="row mb-3">
        <div class="col-xl-4">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-map-pin me-2"></i>Select a Location
                </legend>
                <LocationSelectTableCard v-model="selectedLocation" />
            </fieldset>
        </div>
        <div class="col-xl-8">
            <InventoryCheckDescriptionsTableCard
                :location="selectedLocation?.LOCATION"
                :display-current-selected-flag="displayCurrentSelectedFlag"
                v-model="selectedInventoryCheckDescription">
                <template #footer>
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-primary btn-sm me-1"
                            :disabled="!selectedInventoryCheckDescription"
                            @click="exportInventoryChecks">
                            <i class="icon ph-bold ph-upload me-2"></i>Export Inventory Checks
                        </button>
                        <button type="button"
                            class="btn btn-primary btn-sm me-1"
                            :disabled="!selectedInventoryCheckDescription"
                            @click="exportNOBCInventoryChecks">
                            <i class="icon ph-bold ph-upload me-2"></i>Export NOBC Inventory Checks
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="!selectedLocation && !selectedInventoryCheckDescription"
                            @click="(_) => { isCreateOrUpdateInventoryCheckDescriptionPopupOpen = true; }">
                            <i class="icon ph-bold ph-plus-circle me-2"></i>{{ selectedInventoryCheckDescription ? "Update" : "Create" }} Inventory Check Description
                        </button>
                    </div>
                </template>
            </InventoryCheckDescriptionsTableCard>
        </div>
    </div>

    <UploadBarcodesToInventoryCheckTableCards
        :inventory-check-description="selectedInventoryCheckDescription"
        @success="
            (
                savedBarcodesReturned,
                savedNoBarcodesReturned,
                duplicateBarcodesReturned,
                legacyBarcodesReturned
            ) => {
                savedBarcodes = savedBarcodesReturned;
                savedNoBarcodes = savedNoBarcodesReturned;
                duplicateBarcodes = duplicateBarcodesReturned;
                legacyBarcodes = legacyBarcodesReturned;
            }
        "
        class="mb-3"
    />

    <div class="row g-3 mt-1">
        <div class="col-xl-3">
            <ResultBarcodesTableCard
                :barcodes="savedBarcodes"
                title="Saved Barcodes"
                class="h-100"
            />
        </div>
        <div class="col-xl-3">
            <ResultLegacyBarcodesTableCard
                :legacy-barcodes="legacyBarcodes"
                class="h-100"
            />
        </div>
        <div class="col-xl-3">
            <ResultNoBarcodesTableCard
                :noBarcodes="savedNoBarcodes"
                class="h-100"
            />
        </div>
        <div class="col-xl-3">
            <ResultBarcodesTableCard
                :barcodes="duplicateBarcodes"
                title="Duplicate Barcodes"
                class="h-100"
            />
        </div>
    </div>

    <CreateOrUpdateInventoryCheckDescriptionPopup
        :is-open="isCreateOrUpdateInventoryCheckDescriptionPopupOpen"
        :location="selectedLocation?.LOCATION"
        v-model="selectedInventoryCheckDescription"
        @success="
            (_) => {
                displayCurrentSelectedFlag = !displayCurrentSelectedFlag;
                isCreateOrUpdateInventoryCheckDescriptionPopupOpen = false;
            }
        "
        @close="
            (_) => {
                isCreateOrUpdateInventoryCheckDescriptionPopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { ref } from "vue";
import LocationSelectTableCard from "@/components/utils/LocationSelectTableCard.vue";
import InventoryCheckDescriptionsTableCard from "./InventoryCheckDescriptionsTableCard.vue";
import CreateOrUpdateInventoryCheckDescriptionPopup from "./CreateOrUpdateInventoryCheckDescriptionPopup.vue";
import UploadBarcodesToInventoryCheckTableCards from "./UploadBarcodesToInventoryCheckTableCards.vue";
import ResultBarcodesTableCard from "./ResultBarcodesTableCard.vue";
import ResultNoBarcodesTableCard from "./ResultNoBarcodesTableCard.vue";
import ResultLegacyBarcodesTableCard from "./ResultLegacyBarcodesTableCard.vue";

const selectedLocation = ref(null);

const selectedInventoryCheckDescription = ref(null);

const isCreateOrUpdateInventoryCheckDescriptionPopupOpen = ref(false);

const displayCurrentSelectedFlag = ref(null); // For the table when creating/updating

const savedBarcodes = ref(null);
const savedNoBarcodes = ref(null);
const duplicateBarcodes = ref(null);
const legacyBarcodes = ref(null);

function exportInventoryChecks() {
    window.open(
        route("api.inventory-check-descriptions.report.show", {
            inventory_check_description:
                selectedInventoryCheckDescription.value.id,
        })
    );
}

function exportNOBCInventoryChecks() {
    window.open(
        route("api.inventory-check-descriptions.nobc-report.show", {
            inventory_check_description:
                selectedInventoryCheckDescription.value.id,
        })
    );
}
</script>
