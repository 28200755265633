<template>
    <div v-if="hasEventsToday" :class="class">
        <div class="results-summary-container rounded-5 shadow">
            <div class="confetti">
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
            </div>
            <div class="results-summary-container__result">
                <div class="heading-tertiary">
                    <h2 class="text-center p-3 fw-bold mb-0 pt-4 title-event">TODAYS EVENTS</h2>
                </div>
                <div class="result-box">
                    <div
                        id="carouselExampleAutoplaying"
                        class="carousel slide"
                        data-bs-ride="carousel"
                    >
                        <div class="carousel-inner pt-4">
                            <div
                                class="carousel-item"
                                data-bs-interval="5000"
                                :class="{ active: birthdayEvents?.length > 0 }"
                                v-if="birthdayEvents?.length > 0"
                            >
                                <div class="card-header">
                                    <h2 class="text-center mb-0 birthday-font">Happy Birthday</h2>
                                </div>
                                <div class="card-body pt-4">
                                    <div class="row g-0 justify-content-center mb-3 birthday">
                                        <div v-for="birthdayEvent of birthdayEvents" class="p-2 text-center col-6">
                                            <div class="employee-container">
                                                <div class="img-container mb-3">
                                                    <img
                                                        src="/img/bagnoimage.jpg"
                                                        height="150"
                                                        width="150"
                                                        class="mx-2 rounded-circle shadow"
                                                    />
                                                </div>
                                                <div class="employee-details text-center">
                                                    <h5 class="mb-0 fw-bold">
                                                        {{ birthdayEvent.associated_employee.FNAME }}
                                                        {{ birthdayEvent.associated_employee.LNAME }}
                                                    </h5>
                                                    <small>{{ birthdayEvent.associated_employee.DEPT }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="carousel-item"
                                data-bs-interval="5000"
                                :class="{ active: birthdayEvents?.length == 0 && anniversaryEvents?.length > 0 }"
                                v-if="anniversaryEvents?.length > 0"
                            >
                                <div class="card-header">
                                    <h2 class="text-center mb-0 anniversary-font">Happy Anniversary</h2>
                                </div>
                                <div class="card-body pt-4">
                                    <div class="row g-0 justify-content-center mb-3 anniversary">
                                        <div v-for="anniversaryEvent of anniversaryEvents" class="p-2 text-center col-6">
                                            <div class="employee-container">
                                                <div class="img-container mb-3">
                                                    <img
                                                        src="/img/bagnoimage.jpg"
                                                        height="150"
                                                        width="150"
                                                        class="mx-2 rounded-circle shadow"
                                                    />
                                                </div>
                                                <div class="employee-details text-center">
                                                    <h5 class="mb-0 fw-bold">
                                                        {{ anniversaryEvent.associated_employee.FNAME }}
                                                        {{ anniversaryEvent.associated_employee.LNAME }}
                                                    </h5>
                                                    <small>{{ anniversaryEvent.associated_employee.DEPT }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="carousel-item"
                                data-bs-interval="5000"
                                :class="{ active: birthdayEvents?.length == 0 && anniversaryEvents?.length == 0 && regularizationEvents?.length > 0 }"
                                v-if="regularizationEvents?.length > 0"
                            >
                                <div class="card-header">
                                    <h2 class="text-center mb-0 regularization-font">Employee Regularization</h2>
                                </div>
                                <div class="card-body pt-4">
                                    <div class="row g-0 flex-wrap justify-content-center mb-3 regularization">
                                        <div v-for="regularizationEvent of regularizationEvents" class="p-2 text-center col-6">
                                            <div class="employee-container">
                                                <div class="img-container mb-3">
                                                    <img
                                                        src="/img/bagnoimage.jpg"
                                                        height="150"
                                                        width="150"
                                                        class="mx-2 rounded-circle shadow"
                                                    />
                                                </div>
                                                <div class="employee-details text-center">
                                                    <h5 class="mb-0 fw-bold">
                                                        {{ regularizationEvent.associated_employee.FNAME }}
                                                        {{ regularizationEvent.associated_employee.LNAME }}
                                                    </h5>
                                                    <small>{{ regularizationEvent.associated_employee.DEPT }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useCompanyEvents } from "@/composables/data/companyEvents";

import moment from "moment";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { companyEvents, getCompanyEvents } = useCompanyEvents();

const birthdayEvents = ref(null);
const anniversaryEvents = ref(null);
const regularizationEvents = ref(null);

const hasEventsToday = computed(
    () =>
        birthdayEvents.value?.length > 0 ||
        anniversaryEvents.value?.length > 0 ||
        regularizationEvents.value?.length > 0
);

async function fetchEventsToday() {
    const dateToday = moment.utc().format("YYYY-MM-DD");

    loadingFlags.add("fetchEventsToday");
    try {
        await getCompanyEvents({
            start_date: dateToday,
            end_date: dateToday,
            filters: {
                type: {
                    value: "BIRTHDAY",
                    matchMode: "equals",
                },
            },
            with: "associated_employee",
        });
        birthdayEvents.value = [...companyEvents.value];

        await getCompanyEvents({
            start_date: dateToday,
            end_date: dateToday,
            filters: {
                type: {
                    value: "ANNIV",
                    matchMode: "equals",
                },
            },
            with: "associated_employee",
        });
        anniversaryEvents.value = [...companyEvents.value];

        await getCompanyEvents({
            start_date: dateToday,
            end_date: dateToday,
            filters: {
                type: {
                    value: "REGULAR",
                    matchMode: "equals",
                },
            },
            with: "associated_employee",
        });
        regularizationEvents.value = [...companyEvents.value];
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchEventsToday");
}

onMounted(fetchEventsToday);
</script>

<style scoped>
@media (min-width: 768px) {
    .carousel-caption {
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
    }
}

.birthday-font,
.anniversary-font,
.regularization-font {
    font-size: 2.5rem;
    font-family: "Niconne", cursive;
}

.anniversary-font,
.anniversary .employee-container h5 {
    color: green;
}

.regularization-font,
.regularization .employee-container h5 {
    color: #1982ff;
}

.birthday-font,
.birthday .employee-container h5 {
    color: #eb2f61;
}

.card-header {
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.employee-container {
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.title-event {
    font-family: "Mynerve", cursive;
    color: #2a1770;
    border-bottom: 2px dashed #2a1770;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, -0px);
    }
}

.results-summary-container {
    display: flex;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    overflow: hidden;
    position: relative;
}

.results-summary-container__result {
    width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 30px;
    background: #fff;
}

.carousel-item .card-body {
    max-height: 500px;
    overflow-y: auto;
}

.carousel-item .card-body::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
}

.carousel-item .card-body::-webkit-scrollbar-thumb {
    opacity: 0;
}

.carousel-item .card-body:hover::-webkit-scrollbar-thumb {
    opacity: 1;
    border-radius: 10px;
    background-color: #fff;
    background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#11043e), to(#11043e), color-stop(0.6, #11043e));
}

.confetti {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 100px;
}

.confetti-piece {
    position: absolute;
    width: 10px;
    height: 20px;
    background-color: hsl(39, 100%, 56%);
    top: 0;
    opacity: 0;
    animation: makeItRain 3000ms infinite linear;
}

.confetti-piece:nth-child(1) {
    left: 7%;
    transform: rotate(-10deg);
    animation-delay: 182ms;
    animation-duration: 2000ms;
}

.confetti-piece:nth-child(2) {
    left: 14%;
    transform: rotate(20deg);
    animation-delay: 161ms;
    animation-duration: 2076ms;
}

.confetti-piece:nth-child(3) {
    left: 21%;
    transform: rotate(-51deg);
    animation-delay: 481ms;
    animation-duration: 2103ms;
}

.confetti-piece:nth-child(4) {
    left: 28%;
    transform: rotate(61deg);
    animation-delay: 334ms;
    animation-duration: 1008ms;
}

.confetti-piece:nth-child(5) {
    left: 35%;
    transform: rotate(-52deg);
    animation-delay: 302ms;
    animation-duration: 1776ms;
}

.confetti-piece:nth-child(6) {
    left: 42%;
    transform: rotate(38deg);
    animation-delay: 180ms;
    animation-duration: 1168ms;
}

.confetti-piece:nth-child(7) {
    left: 49%;
    transform: rotate(11deg);
    animation-delay: 395ms;
    animation-duration: 1200ms;
}

.confetti-piece:nth-child(8) {
    left: 56%;
    transform: rotate(49deg);
    animation-delay: 14ms;
    animation-duration: 1887ms;
}

.confetti-piece:nth-child(9) {
    left: 63%;
    transform: rotate(-72deg);
    animation-delay: 149ms;
    animation-duration: 1805ms;
}

.confetti-piece:nth-child(10) {
    left: 70%;
    transform: rotate(10deg);
    animation-delay: 351ms;
    animation-duration: 2059ms;
}

.confetti-piece:nth-child(11) {
    left: 77%;
    transform: rotate(4deg);
    animation-delay: 307ms;
    animation-duration: 1132ms;
}

.confetti-piece:nth-child(12) {
    left: 84%;
    transform: rotate(42deg);
    animation-delay: 464ms;
    animation-duration: 1776ms;
}

.confetti-piece:nth-child(13) {
    left: 91%;
    transform: rotate(-72deg);
    animation-delay: 429ms;
    animation-duration: 1818ms;
}

.confetti-piece:nth-child(14) {
    left: 94%;
    transform: rotate(-72deg);
    animation-delay: 429ms;
    animation-duration: 818ms;
}

.confetti-piece:nth-child(15) {
    left: 96%;
    transform: rotate(-72deg);
    animation-delay: 429ms;
    animation-duration: 2818ms;
}

.confetti-piece:nth-child(16) {
    left: 98%;
    transform: rotate(-72deg);
    animation-delay: 429ms;
    animation-duration: 2818ms;
}

.confetti-piece:nth-child(17) {
    left: 50%;
    transform: rotate(-72deg);
    animation-delay: 429ms;
    animation-duration: 2818ms;
}

.confetti-piece:nth-child(18) {
    left: 60%;
    transform: rotate(-72deg);
    animation-delay: 429ms;
    animation-duration: 1818ms;
}

.confetti-piece:nth-child(odd) {
    background-color: hsl(0, 100%, 67%);
}

.confetti-piece:nth-child(even) {
    z-index: 1;
}

.confetti-piece:nth-child(4n) {
    width: 6px;
    height: 14px;
    animation-duration: 4000ms;
    background-color: #c33764;
}

.confetti-piece:nth-child(5n) {
    width: 3px;
    height: 10px;
    animation-duration: 4000ms;
    background-color: #b06ab3;
}

.confetti-piece:nth-child(3n) {
    width: 4px;
    height: 12px;
    animation-duration: 2500ms;
    animation-delay: 3000ms;
    background-color: #dd2476;
}

.confetti-piece:nth-child(3n-7) {
    background-color: hsl(166, 100%, 37%);
}

@keyframes makeItRain {
    from {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    to {
        transform: translateY(250px);
    }
}
</style>
