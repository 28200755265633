import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useUsers() {
    const users = ref(null);
    const totalRecords = ref(null);

    const getUsers = async (params) => {
        try {
            const getUsersResponse = await axios.get(route('api.users.index', params));
            users.value = getUsersResponse.data.data;
            totalRecords.value = getUsersResponse.data.meta?.total ?? users.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching users.');
        }
    };

    return {
        users,
        totalRecords,
        getUsers,
    };
}
