<template>
    <div class="row">
        <div class="col-xl-4 col-lg-6 mb-3">
            <CreateFormCard
                @success="
                    (_) => {
                        refreshFlag = !refreshFlag;
                    }
                "
            />
        </div>
        <div class="col-xl-8 col-lg-6">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Tabulation Year Equivalences
                </legend>
                <TabulationYearEquivalencesTable :refresh-flag="refreshFlag" />
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

import TabulationYearEquivalencesTable from "@/components/utils/tables/TabulationYearEquivalencesTable.vue";
import CreateFormCard from "./CreateFormCard.vue";

const refreshFlag = ref(false);
</script>
