<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-map-pin me-2"></i>Update Location Store Code
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <div class="row">
                        <div class="col-5">
                            <LocationSelectTableCard
                                v-model="selectedLocation"
                                class="w-100"
                            />
                        </div>
                        <div class="col-7">
                            <Card class="w-100">
                                <template #header>System Location Information</template>
                                <div>
                                    <FormInput
                                        type="text"
                                        label="STORE_CODE:"
                                        v-model="selectedLocation.STORE_CODE"
                                        id-prefix="systemStoreCode"
                                        class="mb-md-1 mb-2"
                                        is-horizontal
                                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                                        disabled
                                    />
                                    <FormInput
                                        type="text"
                                        label="LOCATION:"
                                        v-model="selectedLocation.LOCATION"
                                        id-prefix="systemLocation"
                                        class="mb-md-1 mb-2"
                                        is-horizontal
                                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                                        disabled
                                    />
                                    <FormInput
                                        type="text"
                                        label="DESCRIPTION:"
                                        v-model="selectedLocation.DESCRIPTION"
                                        id-prefix="systemDescription"
                                        class="mb-md-1 mb-2"
                                        is-horizontal
                                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                                        disabled
                                    />
                                </div>
                            </Card>
                            <Card class="mt-2 w-100">
                                <template #header>CSV Location Information</template>
                                <div>
                                    <FormInput
                                        type="text"
                                        label="STORE_CODE:"
                                        v-model="csvData.store_code"
                                        id-prefix="csvStoreCode"
                                        class="mb-md-1 mb-2"
                                        is-horizontal
                                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                                        disabled
                                    />
                                    <FormInput
                                        type="text"
                                        label="STORE_NAME:"
                                        v-model="csvData.store_name"
                                        id-prefix="csvStoreName"
                                        class="mb-md-1 mb-2"
                                        is-horizontal
                                        label-class="col-xl-4 col-lg-2 col-md-3 col-12 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-8 col-lg-10 col-md-9 col-12"
                                        disabled
                                    />
                                </div>
                            </Card>
                            <div class="text-end">
                                <button
                                    type="button"
                                    class="btn btn-primary mt-3 btn-sm"
                                    :disabled="!selectedLocation.LOCATION"
                                    @click="update"
                                >
                                    <i class="icon ph-bold ph-note-pencil me-2"></i>Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";

import Card from "@/components/utils/Card.vue";
import FormInput from "@/components/utils/FormInput.vue";
import LocationSelectTableCard from "@/components/utils/LocationSelectTableCard.vue";

const props = defineProps({
    isOpen: Boolean,
    csvData: Object,
});

const emit = defineEmits(["success", "error", "close", "location"]);

const selectedLocation = ref({
    LOCATION: null,
    STORE_CODE: null,
    DESCRIPTION: null,
});

watch(
    () => props.csvData.system_location,
    () => {
        if (props.csvData.system_location !== "No record.") {
            selectedLocation.value = {
                LOCATION: props.csvData.system_location,
                STORE_CODE: props.csvData.system_store_code,
                DESCRIPTION: props.csvData.system_description,
            };
        }
    }
);

function close() {
    selectedLocation.value = {
        LOCATION: null,
        STORE_CODE: null,
        DESCRIPTION: null,
    };
    emit("close");
}

async function update() {
    try {
        const response = await axios.patch(
            route("api.location.update-store-code", {
                location: selectedLocation.value.LOCATION,
            }),
            { STORE_CODE: props.csvData.store_code }
        );

        emit("success", response.data.message);
        emit("location", response.data.location);
        close();
    } catch (e) {
        console.log(e);
        emit(
            "error",
            "Failed to update STORE_CODE. Please contact your administrator."
        );
    }
}
</script>

<style scoped>
.container {
    max-width: 800px;
}
</style>
