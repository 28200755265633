import { defineStore } from "pinia";

export const useToastsStore = defineStore('toasts', {
    state: () => {
        return {
            messages: []
        };
    },
    actions: {
        add(type, header, message) {
            const newMessageId = (new Date()).getTime();
            this.messages.push({
                id: newMessageId,
                type,
                header,
                message,
            });

            setTimeout(() => {
                this.messages = this.messages.filter((message) => message.id != newMessageId);
            }, 3000);
        }
    }
});
