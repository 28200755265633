<template>
    <div class="row mb-3">
        <div class="col-12">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-swatches me-2"></i>Samples Table
                </legend>
                <SampleTableCard
                    v-model="selectedSampleFromTable"
                    :refresh-flag="refreshSamplesTableFlag"
                    @error=" (message) => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }"
                    class="mb-3"
                />
            </fieldset>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-12 col-xl-7">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-note-pencil me-2"></i>
                    Create or Update Sample
                </legend>
                <SampleFormCard
                    :selected-sample="selectedSampleFromTable"
                    @success="
                        (message, sample) => {
                            addFlashMessage('SUCCESS', message);
                            scrollToTop();
                            selectedSampleFromTable = sample;
                            refreshSamplesTableFlag = !refreshSamplesTableFlag;
                        }
                    "
                    @error="
                        (message) => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }
                    "
                    @reset="(_) => (selectedSampleFromTable = null)"
                />
            </fieldset>
        </div>
        <div class="col-12 col-xl-5">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-image me-2"></i>Sample Images
                </legend>
                <ImagesCard
                    :sample-id="selectedSampleFromTable?.SAMPLE_ID"
                />
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import SampleTableCard from "./SampleTableCard.vue";
import SampleFormCard from "./SampleFormCard.vue";
import ImagesCard from "./ImagesCard.vue";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const selectedSampleFromTable = ref(null);
const refreshSamplesTableFlag = ref(false);
</script>
