<template>
    <div class="row g-3 mb-3">
        <div class="col-lg-4">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>BARCODE Reports
                </legend>
                <div class="row g-1">
                    <div class="col-4 mb-3">
                        <button type="submit"
                            class="btn btn-sm btn-primary w-100 px-1"
                            @click="searchBarcode">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>SEARCH
                        </button>
                    </div>
                    <div class="col-8 mb-3">
                        <FormInput
                            type="text"
                            v-model="barcodeInput"
                            label="Barcode"
                            hide-label
                            id-prefix="barcodeInput"
                            @keydown-enter="searchBarcode"
                            input-class="form-control-sm"
                        />
                    </div>
                </div>
                <div class="row g-1">
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!style || !date || !serial"
                            @click="deleteBarcode">
                            DELETE BARCODE
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!style || !date || !serial"
                            @click="(_) => { isUpdateColorPopupOpen = true; }">
                            CHANGE COLOR
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!selectedInventoryRecord?.SOLD_DT"
                            @click="(_) => { isUpdateSoldDatePopupOpen = true; }">
                            CHANGE SOLD_DT
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!selectedInventoryRecord"
                            @click="(_) => { isUpdateSaleTypePopupOpen = true; }">
                            CHANGE SALE_TYPE
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!selectedInventoryRecord?.PRICE || selectedInventoryRecord.PRICE == 0"
                            @click="(_) => { isUpdatePricePopupOpen = true; }">
                            CHANGE PRICE
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!selectedInventoryRecord?.SOLD_DT"
                            @click="(_) => { isUpdateSellerPopupOpen = true; }">
                            CHANGE SELLER
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!style || !date || !serial"
                            @click="deleteCurrentSaleOrReturnOfBarcode">
                            DELETE LAST (SALE/RETURN)
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!style || !date || !serial"
                            @click="deleteCurrentRecordOfBarcode">
                            DELETE LAST RECORD
                        </button>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12 d-grid">
                        <button class="btn btn-primary btn-sm w-100 px-1"
                            :disabled="!style || !date || !serial"
                            @click="deleteCurrentLostOfBarcode">
                            UNLOST
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-4">
            <InventoryChecksOfBarcodeTableCard
                :barcode="barcodeSearch"
                :refresh-flag="refreshFlag"
                class="h-100"
            />
        </div>
        <div class="col-lg-4">
            <LegacyBarcodesTableCard
                :barcode="barcodeSearch"
                :refresh-flag="refreshFlag"
                class="h-100"
            />
        </div>
    </div>
    <InventoryRecordsOfBarcodeTableCard
        :style="style"
        :date="date"
        :serial="serial"
        :refresh-flag="refreshFlag"
        v-model="selectedInventoryRecord"
    />
    <!-- For updating color-->
    <SelectColor
        :is-open="isUpdateColorPopupOpen"
        @close="(_) => { isUpdateColorPopupOpen = false; }"
        @select="updateColorOfBarcode"
    />
    <UpdateSoldDateFormPopup
        :is-open="isUpdateSoldDatePopupOpen"
        :inventory-record="selectedInventoryRecord"
        @close="(_) => { isUpdateSoldDatePopupOpen = false; }"
        @success="(_) => {
            refreshFlag = !refreshFlag;
            isUpdateSoldDatePopupOpen = false;
        }"
    />
    <UpdateSaleTypeFormPopup
        :is-open="isUpdateSaleTypePopupOpen"
        :inventory-record="selectedInventoryRecord"
        @close="(_) => { isUpdateSaleTypePopupOpen = false; }"
        @success="(_) => {
            refreshFlag = !refreshFlag;
            isUpdateSaleTypePopupOpen = false;
        }"
    />
    <UpdatePriceFormPopup
        :is-open="isUpdatePricePopupOpen"
        :inventory-record="selectedInventoryRecord"
        @close="(_) => { isUpdatePricePopupOpen = false; }"
        @success="(_) => {
            refreshFlag = !refreshFlag;
            isUpdatePricePopupOpen = false;
        }"
    />
    <UpdateSellerFormPopup
        :is-open="isUpdateSellerPopupOpen"
        :inventory-record="selectedInventoryRecord"
        @close="(_) => { isUpdateSellerPopupOpen = false; }"
        @success="(_) => {
            refreshFlag = !refreshFlag;
            isUpdateSellerPopupOpen = false;
        }"
    />
</template>

<script setup>
import { ref } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useLegacyBarcodes } from "@/composables/data/legacyBarcodes";
import { useInventory } from "@/composables/data/inventory";
import FormInput from "@/components/utils/FormInput.vue";
import InventoryChecksOfBarcodeTableCard from "./InventoryChecksOfBarcodeTableCard.vue";
import InventoryRecordsOfBarcodeTableCard from "./InventoryRecordsOfBarcodeTableCard.vue";
import SelectColor from "@/components/utils/SelectColor.vue";
import UpdateSoldDateFormPopup from "./UpdateSoldDateFormPopup.vue";
import UpdateSaleTypeFormPopup from "./UpdateSaleTypeFormPopup.vue";
import UpdatePriceFormPopup from "./UpdatePriceFormPopup.vue";
import UpdateSellerFormPopup from "./UpdateSellerFormPopup.vue";
import { deconstructBarcode } from "@/utils/barcodes";
import LegacyBarcodesTableCard from "./LegacyBarcodesTableCard.vue";

const {
    updateColor,
    postSingleDeletion,
    deleteCurrentSaleOrReturn,
    postCurrentRecordDeletion,
    deleteCurrentLost,
} = useInventory();

const { getNewBarcode } = useLegacyBarcodes();

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const barcodeInput = ref(null);
const barcodeSearch = ref(null);

const style = ref(null);
const date = ref(null);
const serial = ref(null);

const refreshFlag = ref(false);

const selectedInventoryRecord = ref(null);

const isUpdateColorPopupOpen = ref(false);
const isUpdateSoldDatePopupOpen = ref(false);
const isUpdateSaleTypePopupOpen = ref(false);
const isUpdatePricePopupOpen = ref(false);
const isUpdateSellerPopupOpen = ref(false);

async function searchBarcode() {
    style.value = null;
    date.value = null;
    serial.value = null;
    barcodeSearch.value = barcodeInput.value;
    refreshFlag.value = !refreshFlag.value;
    selectedInventoryRecord.value = null;

    if (!barcodeInput.value) {
        toasts.add("ERROR", "Error", "Empty barcode.");
        return;
    }

    loadingFlags.add("searchBarcode");
    // Check if barcode is legacy, then replace
    try {
        const newBarcode = await getNewBarcode(barcodeInput.value);
        alert(`Replacing Legacy Barcode ${barcodeInput.value} with ${newBarcode}.`);
        barcodeInput.value = newBarcode;
    } catch (e) {}

    barcodeSearch.value = barcodeInput.value;

    try {
        const deconstructedBarcode = deconstructBarcode(barcodeInput.value);
        style.value = deconstructedBarcode.style;
        date.value = deconstructedBarcode.date;
        serial.value = deconstructedBarcode.serial;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("searchBarcode");
}

async function updateColorOfBarcode(color) {
    isUpdateColorPopupOpen.value = false;

    loadingFlags.add("updateColorOfBarcode");
    try {
        await updateColor(style.value, date.value, serial.value, color.COLOR);
        toasts.add("SUCCESS", "Success", "Successfully updated color of barcode.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateColorOfBarcode");
}

async function deleteBarcode() {
    if (window.prompt("Type DELETE to delete barcode.") != "DELETE") {
        return;
    }

    const description = window.prompt("Specify reason for deleting.");

    if (!description) {
        return;
    }

    loadingFlags.add("deleteBarcode");
    try {
        await postSingleDeletion(
            style.value,
            date.value,
            serial.value,
            description
        );
        toasts.add("SUCCESS", "Success", "Successfully deleted barcode.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("deleteBarcode");
}

async function deleteCurrentRecordOfBarcode() {
    if (window.prompt("Type DELETE BC to delete last record.") != "DELETE BC") {
        return;
    }

    const description = window.prompt("Specify reason for deleting.");

    if (!description) {
        return;
    }

    loadingFlags.add("deleteCurrentRecordOfBarcode");
    try {
        await postCurrentRecordDeletion(
            style.value,
            date.value,
            serial.value,
            description
        );
        toasts.add("SUCCESS", "Success", "Successfully deleted last record.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("deleteCurrentRecordOfBarcode");
}

async function deleteCurrentSaleOrReturnOfBarcode() {
    if (window.prompt("Type DELETE BC to delete last sale/return.") != "DELETE BC") {
        return;
    }

    loadingFlags.add("deleteCurrentSaleOrReturnOfBarcode");
    try {
        await deleteCurrentSaleOrReturn(style.value, date.value, serial.value);
        toasts.add("SUCCESS", "Success", "Successfully deleted last sale/return.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("deleteCurrentSaleOrReturnOfBarcode");
}

async function deleteCurrentLostOfBarcode() {
    if (window.prompt("Type UNLOST to mark barcode as unlost.") != "UNLOST") {
        return;
    }

    loadingFlags.add("deleteCurrentLostOfBarcode");
    try {
        await deleteCurrentLost(style.value, date.value, serial.value);
        toasts.add("SUCCESS", "Success", "Successfully marked barcode as UNLOST.");
        refreshFlag.value = !refreshFlag.value;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("deleteCurrentLostOfBarcode");
}
</script>
