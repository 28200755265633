<template>
    <div class="row">
        <div class="col-md-6 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                </legend>
                <TimePeriodSelectTableCard
                    :show-only-type="'MONTHLY'"
                    v-model="selectedTimePeriod"
                    @error="
                        (message) => {
                            addFlashMessage('ERROR', message);
                            scrollToTop();
                        }
                    "
                />
            </fieldset>
        </div>
        <div class="col-md-6 mb-3">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar me-2"></i>Upload CSV File
                </legend>
                <FormSelect
                    label="REPORT TYPE:"
                    v-model="selectedReportType"
                    :options="report_type"
                    id-prefix="reportType"
                    is-horizontal
                    class="mb-3"
                    select-class="form-select-sm"
                />
                <form @submit.prevent="csvFileOnSubmit">
                    <div class="mb-3">
                        <small>* File must not be larger than 2MB.</small><br />
                        <small>* File must be in .csv file type.</small>
                        <input
                            type="file"
                            required
                            ref="csvFileInput"
                            accept=".csv"
                            @change="csvFileOnChange"
                            id="csvFileInput"
                            class="form-control form-control-sm"
                        />
                    </div>
                    <div>
                        <button
                            type="submit"
                            class="btn btn-primary btn-sm mb-1 ms-2"
                            :disabled="
                                !selectedReportType ||
                                !selectedTimePeriod.TIME_PERIOD ||
                                isSubmitting
                            "
                        >
                            <i class="icon ph-bold ph-upload me-2"></i>Upload SM {{ selectedReportType ?? "Report" }}
                            <div
                                class="spinner-border spinner-border-sm"
                                role="status"
                                v-if="isSubmitting"
                            >
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary ms-2 btn-sm mb-1 ms-2"
                            :hidden="selectedReportType !== 'LIQUIDATION'"
                            :disabled="!csvFile"
                            @click="csvFileCheckStoreCode"
                        >
                            <i class="icon ph-bold ph-checks me-2"></i>Check Store Code
                        </button>
                    </div>
                </form>
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div
            :class="
                selectedReportType === 'LIQUIDATION'
                    ? 'col-lg-5 mb-3'
                    : 'col-lg-12 mb-3'
            "
        >
            <SalesReportUploadedFilesTableCard
                :report-type="selectedReportType"
                :refresh-flag="uploadedFilesTableRefreshFlag"
            />
        </div>
        <div v-if="selectedReportType === 'LIQUIDATION'" class="col-lg-7">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-file-archive me-2"></i>Extracted Store Locations
                </legend>
                <div class="mb-3">
                    <button
                        class="btn btn-primary btn-sm"
                        @click="clearStoreLocationTable"
                    >
                        <i class="icon ph-bold ph-x me-2"></i>Clear Table
                    </button>
                </div>
                <DataTable
                    :value="storeLocationStatus"
                    :pt="{ table: { class: ' table table-bordered table-hover' } }"
                    paginator
                    :rows="10"
                >
                    <Column field="store_code" header="CSV Store Code"></Column>
                    <Column field="store_name" header="CSV Store Name"></Column>
                    <Column field="system_location" header="System Location">
                        <template #body="data">
                            <div
                                :class="{
                                    'no-record': data.data.system_location === 'No record.'
                                }"
                            >
                                {{ data.data.system_location }}
                            </div>
                        </template>
                    </Column>
                    <Column
                        field="system_description"
                        header="System Store Description"
                    >
                        <template #body="data">
                            <div
                                :class="{
                                    'no-record': data.data.system_location === 'No record.',
                                }"
                            >
                                {{ data.data.system_description }}
                            </div>
                        </template>
                    </Column>
                    <Column header="Update Store Code">
                        <template #body="{ data }">
                            <button
                                class="btn btn-primary btn-sm p-1"
                                @click="updateLocationStoreCode(data)"
                            >
                                <i class="icon ph-bold ph-note-pencil me-2"></i>update
                            </button>
                        </template>
                    </Column>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-map-pin me-2"></i>No location.
                        </div>
                    </template>
                </DataTable>
            </fieldset>
        </div>
    </div>

    <update-store-code
        :is-open="isUpdatingStoreCode"
        :csv-data="selectedLocationToUpdate"
        @close="
            (_) => {
                isUpdatingStoreCode = false;
            }
        "
        @error="
            (message) => {
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
        @success="
            (message) => {
                addFlashMessage('SUCCESS', message);
                scrollToTop();
                csvFileCheckStoreCode();
            }
        "
    />
</template>
<script setup>
import axios from "axios";
import { ref } from "vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import TimePeriodSelectTableCard from "@/components/utils/TimePeriodSelectTableCard.vue";
import UpdateStoreCode from "@/components/admin/updateStoreCode/UpdateStoreCode.vue";
import SalesReportUploadedFilesTableCard from "./SalesReportUploadedFilesTableCard.vue";

import scrollToTop from "@/utils/scrollToTop";

import Column from "primevue/column";
import DataTable from "primevue/datatable";

import { useFlashMessages } from "@/composables/flashmessages";

const { addFlashMessage } = useFlashMessages();

const report_type = ["LIQUIDATION", "PERIODIC"];

const selectedTimePeriod = ref({
    TIME_PERIOD: null,
    START_DATE: null,
    END_DATE: null,
});

const isSubmitting = ref(false);
const selectedReportType = ref();
const csvFileInput = ref();
const csvFile = ref(null);
const storeLocationStatus = ref([]);
const isUpdatingStoreCode = ref(false);
const selectedLocationToUpdate = ref({});
const uploadedFilesTableRefreshFlag = ref(false);

function csvFileOnChange(event) {
    const file = event.target.files[0];
    csvFile.value = file;
}

async function csvFileOnSubmit() {
    isSubmitting.value = true;
    try {
        const formData = new FormData();
        formData.append("csv", csvFile.value);
        formData.append("time_period", selectedTimePeriod.value.TIME_PERIOD);
        formData.append("report_type", selectedReportType.value);
        const response = await axios.post(
            route("api.sm-sales-report.store"),
            formData
        );
        addFlashMessage("SUCCESS", response.data.message);
        if (response.data.errors) {
            Object.entries(response.data.errors).forEach(([field, errors]) => {
                errors.forEach((error) => {
                    addFlashMessage("ERROR", error);
                });
            });
        }
        csvFileInput.value.value = null;
        csvFile.value = null;
        uploadedFilesTableRefreshFlag.value =
            !uploadedFilesTableRefreshFlag.value;
    } catch (e) {
        console.log(e.response);
        if (e.response.status == 400) {
            addFlashMessage("ERROR", e.response.data.message);
        } else {
            addFlashMessage(
                "ERROR",
                "Error while submitting CSV. Please contact your administrator."
            );
        }
    }
    isSubmitting.value = false;
}

async function csvFileCheckStoreCode() {
    try {
        const formData = new FormData();
        formData.append("csv", csvFile.value);
        const response = await axios.post(
            route("api.check-store-code"),
            formData
        );
        storeLocationStatus.value = response.data.data;
        if (response.data.errors) {
            Object.entries(response.data.errors).forEach(([field, errors]) => {
                errors.forEach((error) => {
                    addFlashMessage("ERROR", error);
                });
            });
        }
    } catch (e) {
        console.log(e.response);
        if (e.response.status == 400) {
            addFlashMessage("ERROR", e.response.data.message);
        } else {
            addFlashMessage(
                "ERROR",
                "Error while submitting CSV. Please contact your administrator."
            );
        }
    }
}

function updateLocationStoreCode(data) {
    isUpdatingStoreCode.value = true;
    selectedLocationToUpdate.value = data;
}

function clearStoreLocationTable() {
    storeLocationStatus.value = [];
}
</script>

<style>
.no-record {
    background-color: #ffdddd;
}
</style>
