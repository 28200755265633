<template>
    <div class="row">
        <div class="col-xl-8">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-sliders me-2"></i>Filters
                </legend>
                <div class="row g-3 mb-3">
                    <div class="col-lg-4">
                        <FormInput
                            type="text"
                            v-model="filterForm.EMPID.value"
                            disabled
                            label="Employee ID"
                            id-prefix="employeeId"
                            :form-text="
                                selectedObjects.employee
                                    ? `${selectedObjects.employee.FNAME} ${selectedObjects.employee.LNAME}`
                                    : null
                            "
                            class="mb-1"
                            input-class="form-control-sm"
                        />
                        <div class="text-end">
                            <button
                                type="button"
                                class="btn btn-sm btn-primary"
                                @click="
                                    (_) => {
                                        isSelectEmployeePopupOpen = true;
                                    }
                                "
                            >
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <FormInput
                            type="date"
                            v-model="filterForm.DATE.value[0]"
                            label="Start Date"
                            id-prefix="startDate"
                            input-class="form-control-sm"
                        />
                    </div>
                    <div class="col-lg-4">
                        <FormInput
                            type="date"
                            v-model="filterForm.DATE.value[1]"
                            label="End Date"
                            id-prefix="endDate"
                            class="mb-1"
                            input-class="form-control-sm"
                        />
                        <div class="text-end">
                            <button
                                type="button"
                                class="btn btn-sm btn-primary"
                                @click="
                                    (_) => {
                                        isSelectTimePeriodPopupOpen = true;
                                    }
                                "
                            >
                                <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <FormInput
                            type="text"
                            v-model="filterForm.JONO.value"
                            label="JONO"
                            id-prefix="jono"
                            input-class="form-control-sm"
                        />
                    </div>
                    <div class="col-lg-4">
                        <FormInput
                            type="text"
                            v-model="filterForm.STYLE.value"
                            label="STYLE"
                            id-prefix="style"
                            class="mb-1"
                            input-class="form-control-sm"
                        />
                        <div class="text-end">
                            <button
                                type="button"
                                class="btn btn-sm btn-primary"
                                @click="
                                    (_) => {
                                        isSelectJoborderPopupOpen = true;
                                    }
                                "
                            >
                                <i class="icon ph-bold ph-suitcase me-2"></i>Select Joborder
                            </button>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-secondary me-2 btn-sm"
                        @click="clearFilters"
                    >
                        <i class="icon ph-bold ph-x me-2"></i> Clear Form
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="applyFilters"
                    >
                        <i class="icon ph-bold ph-checks me-2"></i>Apply Filters
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-12">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-clock-user me-2"></i>Employee Hours
                </legend>
                <EmployeeHoursTable :filters="filters" />
            </fieldset>
        </div>
    </div>

    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close="
            (_) => {
                isSelectEmployeePopupOpen = false;
            }
        "
        @select="
            (employee) => {
                filterForm.EMPID.value = employee.EMPID;
                selectedObjects.employee = employee;
                isSelectEmployeePopupOpen = false;
            }
        "
    />

    <SelectTimePeriodPopup
        :is-open="isSelectTimePeriodPopupOpen"
        can-select-paytype
        @close="
            (_) => {
                isSelectTimePeriodPopupOpen = false;
            }
        "
        @select="
            (timePeriod) => {
                filterForm.DATE.value = [
                    timePeriod.START_DATE,
                    timePeriod.END_DATE,
                ];
                isSelectTimePeriodPopupOpen = false;
            }
        "
    />

    <SelectJobOrder
        :is-open="isSelectJoborderPopupOpen"
        @close="
            (_) => {
                isSelectJoborderPopupOpen = false;
            }
        "
        @select="
            (joborder) => {
                filterForm.JONO.value = joborder.JONO;
                filterForm.STYLE.value = joborder.STYLE;
                isSelectJoborderPopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { ref } from "vue";

import FormInput from "@/components/utils/FormInput.vue";

import EmployeeHoursTable from "@/components/utils/tables/EmployeeHoursTable.vue";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";
import SelectTimePeriodPopup from "@/components/utils/popups/SelectTimePeriodPopup.vue";
import SelectJobOrder from "@/components/utils/SelectJobOrder.vue";

const filterForm = ref({
    EMPID: {
        value: null,
        matchMode: "equals",
    },
    DATE: {
        value: [null, null],
        matchMode: "between",
    },
    JONO: {
        value: null,
        matchMode: "equals",
    },
    STYLE: {
        value: null,
        matchMode: "equals",
    },
});

const selectedObjects = ref({
    employee: null,
});

const filters = ref(null);

const isSelectEmployeePopupOpen = ref(false);
const isSelectTimePeriodPopupOpen = ref(false);
const isSelectJoborderPopupOpen = ref(false);

function clearFilters() {
    filterForm.value = {
        EMPID: {
            value: null,
            matchMode: "equals",
        },
        DATE: {
            value: [null, null],
            matchMode: "between",
        },
        JONO: {
            value: null,
            matchMode: "equals",
        },
        STYLE: {
            value: null,
            matchMode: "equals",
        },
    };
}

function applyFilters() {
    filters.value = JSON.parse(JSON.stringify(filterForm.value));
}
</script>
