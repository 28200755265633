<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-list me-2"></i>Select Order Supplier
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable
                        :loading="isLoading"
                        lazy
                        :value="orderSuppliers"
                        :total-records="totalRecords"
                        paginator
                        :rows="10"
                        @page="
                            (e) => {
                                onPage(e);
                                fetchOrderSuppliers();
                            }
                        "
                        filter-display="row"
                        v-model:filters="filters"
                        @filter="tableOnFilter"
                        selection-mode="single"
                        v-model:selection="selectedOrderSupplier"
                        data-key="SUPPLIER_ID"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }"
                    >
                        <Column
                            field="SUPPLIER_ID"
                            header="ID"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }"
                        >
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="SUPPLIER_NAME"
                            header="Name"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }"
                        >
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="SUPPLIER_LOCATION"
                            header="Location"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }"
                        >
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="CONTACT_PERSON"
                            header="Contact Person"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }"
                        >
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <Column
                            field="CONTACT_NUMBER"
                            header="Contact Number"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }"
                        >
                        </Column>
                        <Column
                            field="CONTACT_EMAIL"
                            header="Contact Email"
                            :pt="{
                                filterInput: { class: 'input-group input-group-sm' },
                                filterMenuButton: { class: 'd-none' },
                                headerFilterClearButton: { class: 'd-none' },
                            }"
                        >
                            <template #filter="{ filterModel, filterCallback }">
                                <InputText
                                    type="text"
                                    v-model="filterModel.value"
                                    @input="filterCallback()"
                                    class="form-control"
                                    placeholder="Search"
                                />
                            </template>
                        </Column>
                        <template #empty>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-database me-2"></i>No data yet.
                            </div>
                        </template>
                    </DataTable>
                    <div class="text-end">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            :disabled="!selectedOrderSupplier"
                            @click="
                                (_) => {
                                    emit('select', selectedOrderSupplier);
                                }
                            "
                        >
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select Order Supplier
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useOrderSuppliers } from "@/composables/data/orderSuppliers";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["select", "error", "close"]);

const { params, filters, onPage, onFilter } = useDataTableParams([
    "SUPPLIER_ID",
    "SUPPLIER_NAME",
    "SUPPLIER_LOCATION",
    "CONTACT_PERSON",
    "CONTACT_NUMBER",
    "CONTACT_EMAIL",
]);

const { orderSuppliers, totalRecords, getOrderSuppliers } = useOrderSuppliers();

const selectedOrderSupplier = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(false);

async function fetchOrderSuppliers() {
    orderSuppliers.value = null;

    isLoading.value = true;
    try {
        await getOrderSuppliers(params.value);
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchOrderSuppliers, 300);
}

onMounted(fetchOrderSuppliers);
</script>

