import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useStyleRobinsonsGenericSkus() {
    const styleRobinsonsGenericSku = ref(null);

    const getStyleRobinsonsGenericSku = async (style) => {
        try {
            const getStyleRobinsonsGenericSkuResponse = await axios.get(route("api.styles.robinsons-generic-sku.show", {
                style
            }));
            styleRobinsonsGenericSku.value = getStyleRobinsonsGenericSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching Robinsons Generic SKU.');
        }
    };

    const putStyleRobinsonsGenericSku  = async (style, styleRobinsonsGenericSku) => {
        try {
            const putStyleRobinsonsGenericSkuResponse = await axios.put(route("api.styles.robinsons-generic-sku.update", {
                style
            }), styleRobinsonsGenericSku);
            return putStyleRobinsonsGenericSkuResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating Robinsons Generic SKU.');
        }
    };
    
    return {
        styleRobinsonsGenericSku,
        getStyleRobinsonsGenericSku,
        putStyleRobinsonsGenericSku,
    };
}
