<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-plus me-2"></i>Create/Update Raw Order
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <FormInput
                        type="text"
                        v-model="form.ORDER_ID"
                        disabled
                        label="Order ID"
                        id-prefix="orderId"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormInput
                        type="date"
                        v-model="form.DATE_ORDERED"
                        label="Date Ordered"
                        id-prefix="dateOrdered"
                        :errors="errors.DATE_ORDERED"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <div class="row gx-1">
                        <div class="col">
                            <FormInput
                                type="text"
                                v-model="form.SUPPLIER_ID"
                                disabled
                                label="Supplier"
                                id-prefix="supplier"
                                :errors="errors.SUPPLIER_ID"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-5 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-7 col-md-7 col-12"
                                :form-text="selectedRelationshipsDisplay.supplier?.SUPPLIER_NAME"
                            />
                        </div>
                        <div class="col-auto">
                            <div class="text-end mb-md-1 mb-2">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary px-1"
                                    @click="
                                        (_) => {
                                            isSelectOrderSupplierPopupOpen = true;
                                        }
                                    "
                                >
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                                </button>
                            </div>
                        </div>
                    </div>
                    <FormInput
                        type="text"
                        v-model="form.STYLE"
                        label="Style"
                        id-prefix="style"
                        :errors="errors.STYLE"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <div class="row gx-1">
                        <div class="col">
                            <FormInput
                                type="text"
                                v-model="form.ORDER_PO_ID"
                                label="Order PO ID"
                                id-prefix="orderPOID"
                                :errors="errors.ORDER_PO_ID"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-5 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-7 col-md-7 col-12"
                                :form-text="selectedRelationshipsDisplay.purchase_order?.PURCHASE_ORDER_NUMBER"
                            />
                        </div>
                        <div class="col-auto">
                            <div class="text-end mb-md-1 mb-2">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary px-1"
                                    @click="
                                        (_) => {
                                            isSelectPurchaseOrderPopupOpen = true;
                                        }
                                    "
                                >
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                                </button>
                            </div>
                        </div>
                    </div>
                    <FormInput
                        type="number"
                        step=".01"
                        v-model="form.UNIT_PRICE"
                        label="Unit Price"
                        id-prefix="unitPrice"
                        :errors="errors.UNIT_PRICE"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormInput
                        type="number"
                        v-model="form.QUANTITY"
                        label="Quantity"
                        id-prefix="quantity"
                        :errors="errors.QUANTITY"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <div v-if="rawOrder">
                        <label>Match: </label>
                        <span
                            v-if="rawOrder.QUANTITY == rawOrder.BREAKDOWNS_SUM_QUANTITY"
                            class="badge bg-success ms-2"
                        >
                            Match
                        </span>
                        <span v-else class="badge bg-danger ms-2">
                            Not Match
                        </span>
                    </div>
                    <div class="text-end mt-3">
                        <button
                            type="button"
                            class="btn btn-primary me-2 btn-sm mb-1"
                            :disabled="
                                loadingFlags.has('insertRawOrder') ||
                                loadingFlags.has('updateRawOrder')
                            "
                            @click="
                                (_) => {
                                    if (!rawOrder) {
                                        insertRawOrder();
                                    } else {
                                        updateRawOrder();
                                    }
                                }
                            "
                        >
                            <span
                                v-if="
                                    loadingFlags.has('insertRawOrder') ||
                                    loadingFlags.has('updateRawOrder')
                                "
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            >
                            </span>
                            <i class="icon ph-bold ph-floppy-disk me-2"></i>Save
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-secondary me-2 btn-sm mb-1"
                            @click="reset"
                        >
                            <i class="icon ph-bold ph-x me-2"></i>Clear
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <SelectOrderSupplierPopup
        :is-open="isSelectOrderSupplierPopupOpen"
        @close="
            (_) => {
                isSelectOrderSupplierPopupOpen = false;
            }
        "
        @select="
            (selectedOrderSupplier) => {
                form.SUPPLIER_ID = selectedOrderSupplier.SUPPLIER_ID;
                selectedRelationshipsDisplay.supplier = selectedOrderSupplier;
                isSelectOrderSupplierPopupOpen = false;
            }
        "
    />

    <SelectPurchaseOrderPopup
        :is-open="isSelectPurchaseOrderPopupOpen"
        @close="
            (_) => {
                isSelectPurchaseOrderPopupOpen = false;
            }
        "
        @select="
            (selectedPurchaseOrder) => {
                form.ORDER_PO_ID = selectedPurchaseOrder.ORDER_PO_ID;
                selectedRelationshipsDisplay.purchase_order = selectedPurchaseOrder;
                isSelectPurchaseOrderPopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { ref, watch } from "vue";

import { useRawOrders } from "@/composables/data/rawOrders";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";

import FormInput from "@/components/utils/FormInput.vue";
import SelectPurchaseOrderPopup from "@/components/utils/popups/SelectPurchaseOrderPopup.vue";
import SelectOrderSupplierPopup from "@/components/utils/popups/SelectOrderSupplierPopup.vue";

const props = defineProps({
    purchaseOrder: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["success", "close"]);

const toasts = useToastsStore();

const { postRawOrder, putRawOrder } = useRawOrders();

const rawOrder = defineModel();

const form = ref({
    ORDER_ID: null,
    DATE_ORDERED: null,
    SUPPLIER_ID: null,
    STYLE: null,
    ORDER_PO_ID: null,
    UNIT_PRICE: null,
    QUANTITY: null,
});

const selectedRelationshipsDisplay = ref({});

const errors = ref({});

const isSelectOrderSupplierPopupOpen = ref(false);
const isSelectPurchaseOrderPopupOpen = ref(false);

const loadingFlags = useLoadingFlagsStore();

watch(rawOrder, () => {
    form.value = Object.assign(
        {
            ORDER_ID: null,
            DATE_ORDERED: null,
            SUPPLIER_ID: null,
            STYLE: null,
            ORDER_PO_ID: props.purchaseOrder?.ORDER_PO_ID,
            UNIT_PRICE: null,
            QUANTITY: null,
        },
        rawOrder.value
    );
    selectedRelationshipsDisplay.value.supplier = rawOrder.value?.supplier;
    selectedRelationshipsDisplay.value.purchase_order = rawOrder.value?.purchase_order ?? props.purchaseOrder;
    errors.value = {};
});

watch(
    () => props.purchaseOrder,
    () => {
        if (!rawOrder.value) {
            form.value.ORDER_PO_ID = props.purchaseOrder?.ORDER_PO_ID;
            selectedRelationshipsDisplay.value.purchase_order = props.purchaseOrder;
        }
    }
);

function reset() {
    rawOrder.value = null;
    if (!rawOrder.value) {
        form.value = {
            ORDER_ID: null,
            DATE_ORDERED: null,
            SUPPLIER_ID: null,
            STYLE: null,
            ORDER_PO_ID: props.purchaseOrder?.ORDER_PO_ID,
            UNIT_PRICE: null,
            QUANTITY: null,
        };
        selectedRelationshipsDisplay.value.purchase_order = props.purchaseOrder;
    }
    errors.value = {};
}

async function insertRawOrder() {
    errors.value = {};

    loadingFlags.add("insertRawOrder");
    try {
        const newRawOrder = await postRawOrder(form.value);
        toasts.add("SUCCESS", "Success", "Successfully saved raw order");
        rawOrder.value = newRawOrder;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("insertRawOrder");
}

async function updateRawOrder() {
    errors.value = {};

    loadingFlags.add("updateRawOrder");
    try {
        const newRawOrder = await putRawOrder(
            rawOrder.value.ORDER_ID,
            form.value
        );
        toasts.add("SUCCESS", "Success", "Successfully updated raw order");
        rawOrder.value = newRawOrder;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("updateRawOrder");
}
</script>

<style scoped>
.container {
    max-width: 500px;
}
</style>
