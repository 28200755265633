<template>
    <div class="row mb-3">
        <div class="col-lg-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-calendar-blank me-2"></i>Input Details
                </legend>
                <div class="row g-3">
                    <div class="col-lg-6">
                        <FormSelect
                            v-model="form.paytype"
                            :options="['BIMONTHLY', 'WEEKLY']"
                            label="Paytype"
                            id-prefix="paytype"
                            class="mb-3"
                            select-class="form-select-sm"
                        />
                        <DepartmentGroupFormSelect
                            :paytype="form.paytype"
                            v-model="form.departmentGroup"
                        />
                    </div>
                    <div class="col-lg-6">
                        <FormInput
                            type="date"
                            v-model="form.startDate"
                            label="DOW Start Date"
                            id-prefix="startDate"
                            class="mb-3"
                            input-class="form-control-sm"
                        />
                        <FormInput
                            type="date"
                            v-model="form.endDate"
                            label="DOW End Date"
                            id-prefix="endDate"
                            class="mb-3"
                            input-class="form-control-sm"
                        />
                    </div>
                </div>
                <div class="text-end">
                    <button type="button" class="btn btn-primary btn-sm"
                        @click="applyFilters">
                        <i class="icon ph-bold ph-gear me-2"></i>Generate Report
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-calendar-blank me-2"></i>Report
        </legend>
        <DaysOfWorkAndYearsOfServiceTable
            :paytype="reportParams.paytype"
            :department-group-id="reportParams.departmentGroup?.id"
            :start-date="reportParams.startDate"
            :end-date="reportParams.endDate"
            :refresh-flag="refreshFlag"
            v-model:params="params"
            v-model:total-records="totalRecords"
        />
        <div class="text-end">
            <button type="button" class="btn btn-primary btn-sm"
                :disabled="!totalRecords"
                @click="exportSpreadsheet">
                <i class="icon ph-bold ph-file-xls me-2"></i>Export Spreadsheet
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { ref } from 'vue';

import { useToastsStore } from '@/stores/toasts';

import DepartmentGroupFormSelect from '@/components/utils/dropdowns/DepartmentGroupFormSelect.vue';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';

import DaysOfWorkAndYearsOfServiceTable from './DaysOfWorkAndYearsOfServiceTable.vue';

const toasts = useToastsStore();

const form = ref({
    startDate: null,
    endDate: null,
    paytype: null,
    departmentGroup: null,
});

const reportParams = ref({
    startDate: null,
    endDate: null,
    paytype: null,
    departmentGroup: null,
});

const refreshFlag = ref(false);

const params = ref(null);
const totalRecords = ref(null);

function applyFilters() {
    if (!form.value.startDate) {
        toasts.add("ERROR", "Error", "Please specify start date.");
    }

    if (!form.value.endDate) {
        toasts.add("ERROR", "Error", "Please specify end date.");
    }

    refreshFlag.value = !refreshFlag.value;
    reportParams.value = Object.assign({}, form.value);
}

function exportSpreadsheet() {
    window.open(route('api.employees.days-of-work-and-years-of-service-report.spreadsheet.show', {
        paytype: reportParams.value.paytype,
        department_group_id: reportParams.value.departmentGroup?.id,
        dow_start_date: reportParams.value.startDate,
        dow_end_date: reportParams.value.endDate,
        filters: params.value?.filters,
        multiSortMeta: params.value?.multiSortMeta,
    }));
}
</script>
