<style scoped>
.wrapper {
    width: 100%;
    max-height: 15rem;
    overflow-y: auto;
}

.wrapper table thead {
    position: sticky;
    top: 0;
    margin: 0;
    background-color: lightslategrey;
    color: #fff;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
<template>
    <fieldset :class="class">
        <legend><i class="icon ph-bold ph-handbag me-2"></i>ITEM</legend>
        <div class="row">
            <div class="col-md-7">
                <div class="wrapper">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>ITEM NAME</th>
                                <th>UNIT</th>
                                <th>DESCRIPTION</th>
                                <th>CREATED BY</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="accountItemList.length == 0">
                                <td colspan="6" class="text-center">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-database me-2"></i>No item.
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-else
                                v-for="item in accountItemList"
                                :class="{
                                    'bg-primary text-white':
                                        item.id === accountItem.id,
                                }"
                                @click="selectAccountItem(item)"
                                class="cursor-pointer"
                            >
                                <td>{{ item.ITEM_NAME }}</td>
                                <td>{{ item.UNIT }}</td>
                                <td>{{ item.DESCRIPTION }}</td>
                                <td>{{ item.CREATED_BY }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-5">
                <FormInput
                    type="text"
                    label="ITEM NAME:"
                    v-model="accountItem.ITEM_NAME"
                    id-prefix="itemName"
                    class="mb-md-1 mb-2 align-items-center"
                    is-horizontal
                    label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                    input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                    input-class="form-control-sm"
                    :disabled="disableControl || accountItem.id !== null"
                    :errors="errors.ITEM_NAME"
                    @keydown-enter="focusNextInput('unitFormInput')"
                />
                <FormSelect
                    label="UNIT:"
                    :options="unitList"
                    :option-to-string="(unit) => unit.UNIT"
                    :option-value="(unit) => unit.UNIT"
                    v-model="accountItem.UNIT"
                    id-prefix="unit"
                    class="mb-md-1 mb-2 align-items-center"
                    is-horizontal
                    label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                    select-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                    select-class="form-select-sm"
                    :disabled="disableControl"
                    :errors="errors.UNIT"
                    @change="focusNextInput('descriptionFormInput')"
                    @keydown-enter="focusNextInput('descriptionFormInput')"
                />
                <FormTextarea
                    label="DESCRIPTION:"
                    id-prefix="description"
                    v-model="accountItem.DESCRIPTION"
                    input-class="form-control-sm w-100"
                    is-horizontal
                    class="mb-1"
                    :disabled="disableControl"
                    :errors="errors.DESCRIPTION"
                />
            </div>
        </div>
        <div class="text-end mt-3">
            <button
                class="btn btn-primary me-2 mb-1 btn-sm"
                @click="handleResetAccountItem"
                :disabled="disableControl"
            >
                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>RESET
            </button>
            <button
                class="btn btn-primary me-2 mb-1 btn-sm"
                :hidden="accountItem.id !== null"
                :disabled="disableControl"
                @click="handleInsertAccountItem"
            >
                <i class="icon ph-bold ph-plus me-2"></i>INSERT
            </button>
            <button
                class="btn btn-primary me-2 mb-1 btn-sm"
                :hidden="accountItem.id === null"
                :disabled="disableControl"
                @click="handleUpdateAccountItem"
            >
                <i class="icon ph-bold ph-pencil-simple-line me-2"></i>UPDATE
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";

import FormInput from "../../../utils/FormInput.vue";
import FormSelect from "../../../utils/FormSelect.vue";
import FormTextarea from "../../../utils/FormTextarea.vue";

import axios from "axios";

import focusNextInput from "../../../../utils/focusnext";
import scrollToTop from "../../../../utils/scrollToTop";

const props = defineProps({
    account: Object,
});

const emit = defineEmits(["success", "error", "accountItemSelected"]);

const errors = ref({});

const accountItemList = ref([]);
const unitList = ref([]);
const accountItem = ref({
    id: null,
    ITEM_NAME: null,
    UNIT: null,
    DESCRIPTION: null,
});
const disableControl = ref(true);

onMounted(() => {
    getUnitList();
});

async function getUnitList() {
    try {
        const response = await axios.get(route("api.units.index"));
        unitList.value = response.data.data;
    } catch (e) {
        console.log(e);
        scrollToTop();
        emit(
            "error",
            "An error occurred while fetching units. Please contact your administrator."
        );
    }
}

watch(
    () => props.account.ACCOUNT_ID,
    async () => {
        disableControls(props.account.ACCOUNT_ID);
        handleResetAccountItem();
        getAccountItemList();
    }
);

watch(accountItem, () => {
    emit("accountItemSelected", accountItem.value);
});

async function getAccountItemList() {
    if (props.account.ACCOUNT_ID === "New Emp") {
        accountItemList.value = [];
        handleResetAccountItem();
        return;
    }

    try {
        const response = await axios.get(route("api.account-items.index"), {
            params: {
                ACCOUNT_ID: props.account.ACCOUNT_ID,
            },
        });
        accountItemList.value = response.data.data;
    } catch (e) {
        console.log(e);
        scrollToTop();
        emit(
            "error",
            "An error occurred while fetching account items. Please contact your administrator."
        );
    }
}

function selectAccountItem(item) {
    accountItem.value = {
        id: item.id,
        ITEM_NAME: item.ITEM_NAME,
        UNIT: item.UNIT,
        DESCRIPTION: item.DESCRIPTION,
    };
}

function handleResetAccountItem() {
    accountItem.value = {
        id: null,
        ITEM_NAME: null,
        UNIT: null,
        DESCRIPTION: null,
    };
}

async function handleInsertAccountItem() {
    try {
        const response = await axios.post(route("api.account-items.store"), {
            ACCOUNT_ID: props.account.ACCOUNT_ID,
            ITEM_NAME: accountItem.value.ITEM_NAME,
            UNIT: accountItem.value.UNIT,
            DESCRIPTION: accountItem.value.DESCRIPTION,
        });
        errors.value = {};
        scrollToTop();
        emit("success", response.data);
        getAccountItemList();
        handleResetAccountItem();
    } catch (e) {
        console.log(e);
        if (e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            emit(
                "error",
                "An error occurred while saving account item. Please contact your administrator."
            );
        }
    }
}

async function handleUpdateAccountItem() {
    try {
        const response = await axios.patch(
            route("api.account-items.update", { id: accountItem.value.id }),
            {
                UNIT: accountItem.value.UNIT,
                DESCRIPTION: accountItem.value.DESCRIPTION,
            }
        );
        errors.value = {};
        scrollToTop();
        emit("success", response.data);
        getAccountItemList();
    } catch (e) {
        console.log(e);
        if (e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            emit(
                "error",
                "An error occurred while saving account item. Please contact your administrator."
            );
        }
    }
}

function disableControls(id) {
    if (id === "New Emp") {
        disableControl.value = true;
    } else {
        disableControl.value = false;
    }
}
</script>
