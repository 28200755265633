<template>
    <div>
        <message-box
            :isOpen="openMessageBox"
            :onClose="() => setCloseMessageBox()">
            <section>
                <h5>Are you sure you want to approve the voucher for {{ titleMessageBox }}?</h5>
                <div class="d-flex justify-content-center">
                    <div class="d-flex justify-content-between w-50">
                        <button type="button"
                            class="btn btn-primary btn-sm w-50 m-2"
                            @click="handleAnswer()">
                            <i class="icon ph-bold ph-checks me-2"></i>Yes
                        </button>
                        <button type="button"
                            class="btn btn-primary btn-sm w-50 m-2"
                            @click="setCloseMessageBox()">
                            <i class="icon ph-bold ph-x me-2"></i>No
                        </button>
                    </div>
                </div>
            </section>
        </message-box>
        <div class="row">
            <div class="col-xl-10">
                <fieldset class="mb-3">
                    <legend>
                        <i class="icon ph-bold ph-receipt me-2"></i>Voucher Details
                    </legend>
                    <VouchersTable
                        v-model="selectedVoucher"
                        :refresh-flag="vouchersTableRefreshFlag"
                        :params="tableExtraParams"
                        :filters="tableExtraFilters"
                    />
                    <div class="row justify-content-end">
                        <div class="col-xl-6 d-none">
                            <div class="row">
                                <div class="col">
                                    <div class="row mb-md-1 mb-2">
                                        <label for="exampleFormControlInput1" class="label-sm col-xl-4 col-md-5">Manager RVW</label>
                                        <div class="col-xl-8 col-md-7">
                                            <input
                                                type="text"
                                                class="form-control form-control-sm"
                                                v-model="managerApprove"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button type="button"
                                        class="btn btn-primary btn-sm w-100"
                                        @click="handleApproval('MANAGEMENT')"
                                        :disabled="!selectedVoucher || managerApprove">
                                        <i class="icon ph-bold ph-checks me-2"></i>APPROVE
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="row">
                                <div class="col">
                                    <div class="row mb-md-1 mb-2">
                                        <label for="exampleFormControlInput1" class="label-sm col-xl-4 col-md-5">Accting RVW</label>
                                        <div class="col-xl-8 col-md-7">
                                            <input
                                                type="text"
                                                class="form-control form-control-sm"
                                                v-model="accountingApprove"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button type="button"
                                        class="btn btn-primary btn-sm w-100"
                                        @click="handleApproval('ACCOUNTING')"
                                        :disabled="!selectedVoucher || accountingApprove">
                                        <i class="icon ph-bold ph-checks me-2"></i>APPROVE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="mb-3">
                    <legend>
                        <i class="icon ph-bold ph-receipt me-2"></i>GL ITEMS
                    </legend>
                    <div class="row">
                        <div class="col-xl-4">
                            <div class="row mb-md-1 mb-2">
                                <label class="label-sm col-md-5">Voucher Amt</label>
                                <div class="col-md-7">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="voucherAmt"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="row mb-md-1 mb-2">
                                <label class="label-sm col-md-5">Debit - Credit</label>
                                <div class="col-md-7">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="glBalance.BALANCE"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="row mb-md-1 mb-2">
                                <label class="label-sm col-md-5">Total Debit</label>
                                <div class="col-md-7">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="glBalance.DEBIT"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="row mb-md-1 mb-2">
                                <label class="label-sm col-md-5">Total Credit</label>
                                <div class="col-md-7">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="glBalance.CREDIT"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- TODO: Hidden for now, not needed as of now but might arise. -->
                        <div class="col-xl-4 d-none">
                            <div class="row">
                                <label class="label-sm col-md-5" for="CHECK_CLEAR">Check Clear</label>
                                <div class="col-md-7">
                                    <select name="CHECK_CLEAR"
                                        class="form-select form-select-sm"
                                        v-model="checkClear">
                                        <option value=""></option>
                                        <option value="Y">Y</option>
                                        <option value="N">N</option>
                                    </select>
                                </div>
                                <div class="col-12 text-end mt-1">
                                    <button type="button"
                                        class="btn btn-primary btn-sm w-auto"
                                        @click="clearCheck()">
                                        <i class="icon ph-bold ph-broom me-2"></i>Clear Check
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 import-hours-nideka-table-wrapper my-3">
                            <table class="employee-violations-table table table-sm table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">SEQ</th>
                                        <th scope="col">GL_ID</th>
                                        <th scope="col">CLASS</th>
                                        <th scope="col">ACCTNAME</th>
                                        <th scope="col">DEBIT</th>
                                        <th scope="col">CREDIT</th>
                                        <th scope="col">JOBOR</th>
                                        <th scope="col">JONO</th>
                                        <th scope="col">STYLE</th>
                                        <th scope="col">PAYEE</th>
                                        <th scope="col">GL_PAY</th>
                                        <th scope="col">PAYEE_NAME</th>
                                        <th scope="col">PAYEE_DATE</th>
                                        <th scope="col">CHECK</th>
                                        <th scope="col">TAXRA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="obj in glData"
                                        :key="obj.GL_SEQ_NO"
                                        :class="{ 'selected-row': obj.GL_SEQ_NO === selectedSeqNo }"
                                        @click="fetchGLEntryInfo(obj.GL_SEQ_NO)">
                                        <td>{{ obj.GL_SEQ_NO }}</td>
                                        <td>{{ obj.GL_ID }}</td>
                                        <td>{{ obj.CLASS }}</td>
                                        <td>{{ obj.ACCTNAME }}</td>
                                        <td>{{ formatWithComma(obj.DEBIT) }}</td>
                                        <td>{{ formatWithComma(obj.CREDIT) }}</td>
                                        <td>{{ obj.JOBORDER_ID }}</td>
                                        <td>{{ obj.JONO }}</td>
                                        <td>{{ obj.STYLE }}</td>
                                        <td>{{ obj.GL_PAY_TYPE }}</td>
                                        <td>{{ obj.GL_PAY_ID }}</td>
                                        <td>{{ obj.PAYEE_NAME }}</td>
                                        <td>{{ obj.PAYEE_DATE }}</td>
                                        <td>{{ obj.CHECK_CLEAR }}</td>
                                        <td>{{ obj.TAXRATE }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12">
                            <div class="row g-2 mb-2">
                                <div class="col-lg-5">
                                    <div class="row g-2">
                                        <div class="col-xl-9">
                                            <div class="row g-2">
                                                <div class="col-5">
                                                    <FormSelect
                                                        v-model="className"
                                                        :options="glAcctnameClass"
                                                        label="Class"
                                                        id-prefix="class"
                                                        select-class="form-select-sm"
                                                    />
                                                </div>
                                                <div class="col-5">
                                                    <FormSelect
                                                        v-model="acctname"
                                                        :options="glAcctname"
                                                        @change="glAccountSelection"
                                                        :option-value="option => option.ACCTNAME"
                                                        :option-to-string="option => option.ACCTNAME"
                                                        label="Account Name"
                                                        id-prefix="accountName"
                                                        select-class="form-select-sm"
                                                    />
                                                </div>
                                                <div class="col-2">
                                                    <FormInput
                                                        v-model="glId"
                                                        label="ID"
                                                        id-prefix="glId"
                                                        input-class="form-control-sm"
                                                        :errors="voucherGlErrors?.GL_ID"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 mt-auto">
                                            <FormSelect
                                                v-model="debitCredit"
                                                :options="[
                                                    {
                                                        value: 'debit',
                                                        label: 'DEBIT',
                                                    },
                                                    {
                                                        value: 'credit',
                                                        label: 'CREDIT',
                                                    }
                                                ]"
                                                :option-value="option => option.value"
                                                :option-to-string="option => option.label"
                                                id-prefix="debitCredit"
                                                select-class="form-select-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="row g-2">
                                        <div class="col-xl-6">
                                            <div class="row g-2">
                                                <div class="col-5">
                                                    <FormInput
                                                        v-model="debitCreditAmt"
                                                        label="Amount"
                                                        id-prefix="glId"
                                                        input-class="form-control-sm"
                                                        :errors="voucherGlErrors?.GL_AMT"
                                                    />
                                                </div>
                                                <div class="col-4 mt-auto">
                                                    <FormSelect
                                                        v-model="taxrate"
                                                        :options="[
                                                            {
                                                                value: 0,
                                                                label: 'NO VAT',
                                                            },
                                                            {
                                                                value: 10,
                                                                label: '10% VAT',
                                                            },
                                                            {
                                                                value: 11,
                                                                label: '11% VAT',
                                                            },
                                                            {
                                                                value: 12,
                                                                label: '12% VAT',
                                                            },
                                                        ]"
                                                        :option-value="option => option.value"
                                                        :option-to-string="option => option.label"
                                                        @change="handleTaxrate"
                                                        id-prefix="taxrate"
                                                        select-class="form-select-sm"
                                                        :errors="voucherGlErrors?.TAXRATE"
                                                    />
                                                </div>
                                                <div class="col-3 mt-auto">
                                                    <p class="mb-1">{{ taxrateText.toFixed(2) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 mt-auto">
                                            <div class="row g-2">
                                                <div class="col">
                                                    <button type="button"
                                                        class="btn btn-primary btn-sm px-1 w-100"
                                                        :disabled="!selectedSeqNo"
                                                        @click="deleteGLEntry()">
                                                        <i class="icon ph-bold ph-trash me-2"></i>DELETE
                                                    </button>
                                                </div>
                                                <div class="col" v-if="!selectedSeqNo">
                                                    <button v-if="!selectedSeqNo"
                                                        type="button"
                                                        class="btn btn-primary btn-sm px-1 w-100"
                                                        @click="insertGlEntry('insert')"
                                                        :disabled="!selectedVoucher || !glId || !debitCredit">
                                                        <i class="icon ph-bold ph-plus-square me-2"></i>INSERT
                                                    </button>
                                                </div>
                                                <div class="col" v-if="selectedSeqNo">
                                                    <button v-if="selectedSeqNo"
                                                        type="button"
                                                        class="btn btn-primary btn-sm px-1 w-100"
                                                        @click="insertGlEntry('update')"
                                                        :disabled="!selectedVoucher || !glId || !debitCredit">
                                                        <i class="icon ph-bold ph-arrow-clockwise me-2"></i>UPDATE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-2">
                                <div class="col-lg-5">
                                    <div class="row g-2 mb-2">
                                        <div class="col-5">
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm w-100 px-1"
                                                @click="(_) => { isSelectJobOrderOpen = true; }"
                                            >
                                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search Joborder
                                            </button>
                                        </div>
                                        <div class="col-7">
                                            <div class="row g-2">
                                                <div class="col-3">
                                                    <FormInput
                                                        v-model="jobOrder"
                                                        hide-label
                                                        id-prefix="jobOrder"
                                                        input-class="form-control-sm"
                                                        :errors="voucherGlErrors?.JOBORDER_ID"
                                                    />
                                                </div>
                                                <div class="col-4 d-flex align-items-center">
                                                    <label class="label-sm">{{ jono }}</label>
                                                </div>
                                                <div class="col-5 d-flex align-items-center">
                                                    <label class="label-sm">{{ style }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-2">
                                        <div class="col-5">
                                            <FormSelect
                                                v-model="payeeType"
                                                :options="[
                                                    {
                                                        value: 'E',
                                                        label: 'EXTERNAL',
                                                    },
                                                    {
                                                        value: 'I',
                                                        label: 'INTERNAL',
                                                    },
                                                ]"
                                                :option-value="option => option.value"
                                                :option-to-string="option => option.label"
                                                @change="(_) => {
                                                    searchPayee(payeeType);
                                                }"
                                                hide-label
                                                id-prefix="payeeType"
                                                select-class="form-select-sm"
                                                :errors="voucherGlErrors?.GL_PAY_TYPE"
                                            />
                                        </div>
                                        <div class="col-7">
                                            <div class="row g-2">
                                                <div class="col-3">
                                                    <FormInput
                                                        v-model="glPay"
                                                        hide-label
                                                        id-prefix="glPay"
                                                        input-class="form-control-sm"
                                                        :errors="voucherGlErrors?.GL_PAY_ID"
                                                    />
                                                </div>
                                                <div class="col-9 d-flex align-items-center">
                                                    <label class="label-sm">{{ payeeName }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="row g-2 mb-2">
                                        <div class="col-5">
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm w-100 px-1"
                                                @click="openSelectAccount('CHECK')"
                                            >
                                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search Bank
                                            </button>
                                        </div>
                                        <div class="col-7">
                                            <div class="row g-2">
                                                <div class="col-3">
                                                    <FormInput
                                                        v-model="checkBank"
                                                        hide-label
                                                        id-prefix="checkBank"
                                                        input-class="form-control-sm"
                                                    />
                                                </div>
                                                <div class="col-9 d-flex align-items-center">
                                                    <label class="label-sm">{{ bankName }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <FormInput
                                        v-model="invoiceNumber"
                                        label="Invoice Num"
                                        is-horizontal
                                        label-class="col-lg-5"
                                        input-container-class="col-lg-7"
                                        id-prefix="invoiceNumber"
                                        input-class="form-control-sm"
                                    />
                                </div>
                                <div class="col-lg-3">
                                    <FormInput
                                        v-model="checkNumber"
                                        label="Check #"
                                        is-horizontal
                                        label-class="col-lg-5"
                                        input-container-class="col-lg-7"
                                        id-prefix="checkNumber"
                                        input-class="form-control-sm"
                                    />
                                    <FormInput
                                        type="date"
                                        v-model="payeeDate"
                                        label="Payee Date"
                                        is-horizontal
                                        label-class="col-lg-5"
                                        input-container-class="col-lg-7"
                                        id-prefix="payeeDate"
                                        input-class="form-control-sm"
                                        :errors="voucherGlErrors?.PAYEE_DATE"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-xl-2 mb-3">
                <div class="row">
                    <div class="col-xl-12 col-md-6">
                        <fieldset class="mb-3">
                            <legend>
                                <i class="icon ph-bold ph-calendar me-2"></i>Search by Date
                            </legend>
                            <div class="row mb-md-1 mb-2">
                                <FormInput
                                    type="date"
                                    v-model="startDate"
                                    label="Start Date"
                                    id-prefix="startDate"
                                    input-class="form-control-sm"
                                />
                            </div>
                            <div class="row">
                                <FormInput
                                    type="date"
                                    v-model="endDate"
                                    label="End Date"
                                    id-prefix="endDate"
                                    input-class="form-control-sm"
                                />
                                <div class="col-12 text-end mt-3">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        id="date-search-button"
                                        @click="searchByDate"
                                    >
                                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>SEARCH
                                        <i v-if="tableExtraFilters?.VOUCHER_DATE" class="ph ph-check"></i>
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-xl-12 col-md-6">
                        <fieldset class="mb-3">
                            <legend>
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>SEARCH
                            </legend>
                            <div class="row">
                                <div class="col-12 mb-1">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm w-100"
                                        @click="toggleFilterNeedingAccountingCode"
                                    >
                                        ACCOUNTING CODE
                                        <i v-if="tableExtraParams?.NEEDS_ACCOUNTING_CODE" class="ph ph-check"></i>
                                    </button>
                                </div>
                                <div class="col-12">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm w-100"
                                        @click="toggleFilterNeedingAccountingApproval"
                                    >
                                        ACCOUNTING CODE APPROVAL
                                        <i v-if="tableExtraParams?.NEEDS_ACCOUNTING_APPROVAL" class="ph ph-check"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3 g-3">
                                <div class="col-12">
                                    <FormSelect
                                        v-model="companyCode"
                                        :options="companies"
                                        :option-to-string="option => option.COMPANY_DESCRIPTION"
                                        :option-value="option => option.COMPANY_CODE"
                                        is-horizontal
                                        select-class="form-select-sm"
                                        label-class="col-lg-5"
                                        input-container-class="col-lg-7"
                                        label="Company Code"
                                        id-prefix="companyCode"
                                        @change="includeCompanyCodeSearch"
                                    />
                                </div>
                                <div class="col-12">
                                    <button type="button"
                                        class="btn btn-secondary btn-sm w-100"
                                        @click="clearExtraFilters">
                                        CLEAR FILTERS
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-xl-12">
                        <fieldset>
                            <legend>
                                Actions
                            </legend>
                            <div class="d-grid gap-1">
                                <button type="button"
                                    class="btn btn-primary w-100 btn-sm"
                                    @click="(_) => { isUploadVoucherOpen = true; }">
                                    <i class="icon ph-bold ph-upload-simple me-2"></i>UPLOAD VOUCHERS
                                </button>
                                <button type="button"
                                    class="btn btn-primary w-100 btn-sm"
                                    @click="(_) => { isUploadGovtVouchersOpen = true; }">
                                    <i class="icon ph-bold ph-upload-simple me-2"></i>UPLOAD GOV'T VOUCHERS
                                </button>
                                <button type="button"
                                    class="btn btn-primary w-100 btn-sm"
                                    @click="(_) => { isVoucherImagesPopupOpen = true; }"
                                    :disabled="!selectedVoucher">
                                    <i class="icon ph-bold ph-eye me-2"></i>SHOW VOUCHER
                                </button>
                                <button type="button"
                                    class="btn btn-primary w-100 btn-sm"
                                    :disabled="!selectedVoucher"
                                    @click="printCashVoucher()">
                                    <i class="icon ph-bold ph-printer me-2"></i>PRINT CASH VOUCHER
                                </button>
                                <button type="button"
                                    class="btn btn-primary w-100 btn-sm"
                                    :disabled="!selectedVoucher"
                                    @click="printCheckVoucher()">
                                    <i class="icon ph-bold ph-printer me-2"></i>PRINT CHECK VOUCHER
                                </button>
                                <button type="button"
                                    class="btn btn-primary w-100 btn-sm"
                                    :disabled="!selectedVoucher"
                                    @click="printBDOCheck()">
                                    <i class="icon ph-bold ph-printer me-2"></i>PRINT BDO CHECK
                                </button>
                                <button type="button"
                                    class="btn btn-primary w-100 btn-sm"
                                    :disabled="!selectedVoucher"
                                    @click="redirectToUpdateVoucher">
                                    <i class="icon ph-bold ph-pencil-line me-2"></i>UPDATE VOUCHER ADMIN
                                </button>
                                <button type="button"
                                    class="btn btn-primary w-100 btn-sm"
                                    :disabled="!selectedVoucher"
                                    data-bs-toggle="modal"
                                    data-bs-target="#printVoucherModal">
                                    <i class="icon ph-bold ph-receipt me-2"></i>VOUCHER RPT
                                </button>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <SelectAccount
            :isOpen="isSelectAccountOpen"
            :onClose="_ => { isSelectAccountOpen = false; }"
            :onSelectAccount="handleSelectAccount"
        />
        <SelectEmployee
            :isOpen="isSelectEmployeeOpen"
            @close="_ => { isSelectEmployeeOpen = false; }"
            @select="(employee) => {
                handleSelectEmployee(employee);
                isSelectEmployeeOpen = false;
            }"
        />
        <SelectJobOrder
            :is-open="isSelectJobOrderOpen"
            @select="handleSelectJobOrder"
            @close="_ => { isSelectJobOrderOpen = false; }"
        />
        <PrintModal
            :pdf-link="printVoucherPDFLink"
            :spreadsheet-link="printVoucherSpreadsheetLink"
            title="Print Voucher"
            id="printVoucherModal"
        />
        <BulkImageUploadPopup
            :is-open="isUploadVoucherOpen"
            :imageable-type="'App\\Models\\Voucher'"
            @close="_ => {
                isUploadVoucherOpen = false;
            }"
            :extract-id-callback="(name) => {
                return name.split('.')[0];
            }"
            :validate-inputs-callback="validateVoucherFileInputsCallback"
            :validate-input-callback="validateVoucherFileInputCallback"
        />
        <BulkImageUploadPopup
            :is-open="isUploadGovtVouchersOpen"
            imageable-type="GovernmentVoucher"
            @close="_ => {
                isUploadGovtVouchersOpen = false;
            }"
            :extract-id-callback="extractGovtVoucherIdCallback"
            :validate-inputs-callback="validateGovtVoucherFileInputsCallback"
            :validate-input-callback="validateGovtVoucherFileInputCallback"
        />
        <VoucherImagesPopup
            :voucher-id="selectedVoucher?.VOUCHER_ID"
            :is-open="isVoucherImagesPopupOpen"
            @close="_ => { isVoucherImagesPopupOpen = false; }"
        />
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, onBeforeUnmount } from "vue";
import numberToWords from 'number-to-words';

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useCompanies } from "@/composables/data/companies";
import { useVouchers } from "@/composables/data/vouchers";

import FormSelect from "@/components/utils/FormSelect.vue";
import VouchersTable from "@/components/utils/tables/VouchersTable.vue";
import SelectAccount from '@/components/utils/SelectAccount.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';
import SelectJobOrder from '@/components/utils/SelectJobOrder.vue';
import PrintModal from '@/components/utils/PrintModal.vue'
import FormInput from "@/components/utils/FormInput.vue";
import BulkImageUploadPopup from "@/components/utils/popups/BulkImageUploadPopup.vue";
import VoucherImagesPopup from "@/components/utils/popups/VoucherImagesPopup.vue";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { companies, getCompanies } = useCompanies();

const selectedVoucher = ref(null);

watch(selectedVoucher, () => {
    clearVoucherSelection();
    fetchGLInfo();
});

const tableExtraParams = ref({
    NEEDS_ACCOUNTING_APPROVAL: false,
    NEEDS_ACCOUNTING_CODE: false,
});
const tableExtraFilters = ref({
    VOUCHER_ID: null,
    VOUCHER_DATE: null,
});

const vouchersTableRefreshFlag = ref(false);

const openMessageBox = ref(false);
const titleMessageBox = ref('');
const glData = ref([]);
const startDate = ref("");
const endDate = ref("");
const companyCode = ref(null);
const managerApprove = ref(null);
const accountingApprove = ref(null);
const voucherAmt = ref(0);
const className = ref("");
const acctname = ref("");
const debitCreditAmt = ref('');
const debitCredit = ref('debit');
const taxrate = ref(0);
const taxrateText = ref(0);
const jobOrder = ref("");
const jono = ref('NONE');
const style = ref('NONE');
const glPay = ref('');
const glId = ref('');
const payeeName = ref('NONE');
const payeeType = ref('');
const checkNumber = ref(null);
const checkBank = ref(null);
const checkClear = ref('');
const bankName = ref('NONE');
const invoiceNumber = ref('');
const payeeDate = ref(null);
const selectedSeqNo = ref(null);
const voucherGlErrors = ref(null);
const username = ref('');
const glAcctnameClass= ref([]);
const glAcctname= ref([]);
const isSelectAccountOpen = ref(false);
const isSelectEmployeeOpen = ref(false);
const isSelectJobOrderOpen = ref(false);
const selectedPayeeType = ref('');
const isListVisible = ref(false);
const glBalance = ref({
    DEBIT: 0,
    CREDIT: 0,
    BALANCE: 0
})
const isUploadVoucherOpen = ref(false);
const isUploadGovtVouchersOpen = ref(false);
const isVoucherImagesPopupOpen = ref(false);

function searchByDate() {
    let hasErrors = false;

    if (!startDate.value) {
        toasts.add("ERROR", "Error", "Please specify start date.");
        hasErrors = true;
    }

    if (!endDate.value) {
        toasts.add("ERROR", "Error", "Please specify end date.");
        hasErrors = true;
    }

    if (hasErrors) {
        return;
    }

    tableExtraFilters.value.VOUCHER_DATE = {
        value: [startDate.value, endDate.value],
        matchMode: 'between',
    };
    vouchersTableRefreshFlag.value = !vouchersTableRefreshFlag.value;
}

function toggleFilterNeedingAccountingCode() {
    tableExtraParams.value.NEEDS_ACCOUNTING_CODE = !tableExtraParams.value.NEEDS_ACCOUNTING_CODE;
    vouchersTableRefreshFlag.value = !vouchersTableRefreshFlag.value;
}

function toggleFilterNeedingAccountingApproval() {
    tableExtraParams.value.NEEDS_ACCOUNTING_APPROVAL = !tableExtraParams.value.NEEDS_ACCOUNTING_APPROVAL;
    vouchersTableRefreshFlag.value = !vouchersTableRefreshFlag.value;
}

function includeCompanyCodeSearch() {
    tableExtraFilters.value.VOUCHER_ID = {
        value: companyCode.value,
        matchMode: 'startsWith',
    };
    vouchersTableRefreshFlag.value = !vouchersTableRefreshFlag.value;
}

function clearExtraFilters() {
    startDate.value = null;
    endDate.value = null;
    tableExtraParams.value = {
        NEEDS_ACCOUNTING_APPROVAL: false,
        NEEDS_ACCOUNTING_CODE: false,
    };
    tableExtraFilters.value = {
        VOUCHER_ID: null,
        VOUCHER_DATE: null,
    };
    companyCode.value = null;
}

async function fetchGLInfo() {
    if (!selectedVoucher.value) {
        return;
    }

    loadingFlags.add("fetchGLInfo");
    try {
        const response = await axios.get(route('api.voucher-manager-approval.show', { id: selectedVoucher.value.VOUCHER_ID }))
        managerApprove.value = response.data[0].APPROVED_BY;
    } catch (error) {
        console.log(error);
        toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
    }

    try {
        const response = await axios.get(route('api.voucher-accounting-approval.show', { id: selectedVoucher.value.VOUCHER_ID }))
        accountingApprove.value = response.data[0].APPROVED_BY;
    } catch (error) {
        console.log(error);
        toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
    }

    try {
        checkClear.value = 'N';
        voucherAmt.value = formatWithComma(selectedVoucher.value.VOUCHER_AMT);
        clearGLFields();
    } catch (error) {
        console.log(error);
        toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
    }

    try {
        const response = await axios.get(route('api.voucher.gl', {
            VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
        }));
        glData.value = response.data.info;
        glBalance.value = {
            DEBIT: formatWithComma(response.data.totalDebit),
            CREDIT: formatWithComma(response.data.totalCredit),
            BALANCE: formatWithComma(response.data.balance)
        }
        updateVoucherAmountTotal(response.data);
    } catch (error) {
        console.log(error);
        toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
    }
    loadingFlags.delete("fetchGLInfo");
}

const closeList = (event) => {
    const dropdownContainer = document.querySelector('.dropdownContainer');
    if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        isListVisible.value = false;
    }
};

onMounted(() => {
    const voucherId = new URLSearchParams(window.location.search).get("VOUCHER_ID");

    if (voucherId) {
        tableExtraFilters.value = {
            VOUCHER_ID: {
                value: voucherId,
                matchMode: 'equals',
            },
        };
    }

    document.addEventListener('click', closeList);
});

onBeforeUnmount(() => {
    document.removeEventListener('click', closeList);
});

onMounted(async () => {
    username.value = "/" + document.querySelector('#update-voucher').getAttribute('data-username');
    // Initial values for startDate and endDate
    const today = new Date();
    endDate.value = formatDate(today);
    startDate.value = formatDate(getDateDaysAgo(today, 10));
    const id = document.querySelector('#update-voucher').getAttribute('data-item');
    if (id) {

    }

    fetchCompanies();
    getGlClass();
    getGlName();
})

watch(className, () => {
    getGlName();
})

watch(taxrate, () => {
    handleTaxrate();
})

async function fetchCompanies() {
    loadingFlags.add("fetchCompanies");
    try {
        await getCompanies();
    } catch (error) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchCompanies");
}

async function getGlClass() {
    try {
        const response = await axios.post(route('api.gl.filter'), {
            class: true
        });
        glAcctnameClass.value = response.data[0];
    } catch (error) {
        console.log(error);
        toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
    }
}

async function getGlName() {
    try {
        const response = await axios.post(route('api.gl.filter'), {
            search: className.value !== "" ? className.value : 'empty',
        });
        glAcctname.value = response.data[0];
    } catch (error) {
        console.log(error);
        toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
    }
}

function handleApproval(type) {
    titleMessageBox.value = type;

    if (type == 'MANAGEMENT') {
        openMessageBox.value = true;
        return;
    }

    handleAnswer();
}

async function handleAnswer () {
    if (titleMessageBox.value == 'MANAGEMENT') {
        try {
            const response = await axios.post(route('api.voucher-manager-approval.store'), {
                VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
                APPROVED_BY: username.value,
            });
            if (response.status === 200 || response.status === 201) {
                toasts.add("SUCCESS", "Success", "VOUCHER has been approved by MANAGEMENT.");
                vouchersTableRefreshFlag.value = !vouchersTableRefreshFlag.value;
                fetchGLInfo();
            }
        } catch (error) {
            console.log(error);
            toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
        }
        openMessageBox.value = false;
    } else {
        try {
            const response = await axios.post(route('api.voucher-accounting-approval.store'), {
                VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
                APPROVED_BY: username.value,
            });
            if (response.status === 200 || response.status === 201) {
                toasts.add("SUCCESS", "Success", "VOUCHER has been approved by ACCOUNTING supervisor.");
                vouchersTableRefreshFlag.value = !vouchersTableRefreshFlag.value;
                fetchGLInfo();
            }
        } catch (error) {
            console.log(error);
            toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
        }
        openMessageBox.value = false;
    }
}

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

function getDateDaysAgo(date, days) {
    const result = new Date(date);
    result.setDate(date.getDate() - days);
    return result;
}

async function fetchGLEntryInfo(id) {
    selectedSeqNo.value = id;
    glData.value.forEach((element) => {
        if (id == element.GL_SEQ_NO) {
            if (element.DEBIT > 0) {
                debitCredit.value = 'debit';
                debitCreditAmt.value = formatWithComma(element.DEBIT);
            }
            if(element.CREDIT > 0) {
                debitCredit.value = 'credit';
                debitCreditAmt.value = formatWithComma(element.CREDIT);
            }
            jono.value = element.JONO;
            glId.value = element.GL_ID;
            style.value = element.STYLE;
            taxrate.value = element.TAXRATE;
            glPay.value = element.GL_PAY_ID;
            className.value = element.CLASS;
            acctname.value = element.ACCTNAME;
            bankName.value = element.BANK_NAME;
            jobOrder.value = element.JOBORDER_ID;
            payeeName.value = element.PAYEE_NAME;
            payeeDate.value = element.PAYEE_DATE;
            checkBank.value = element.CHECK_BANK;
            payeeType.value = element.GL_PAY_TYPE;
            checkNumber.value = element.CHECK_NUM === null ? "" : element.CHECK_NUM;
            invoiceNumber.value = element.INVOICE_NUM;
            checkClear.value = element.CHECK_CLEAR;
            handleTaxrate();
        }
    });
}

function printCashVoucher() {
    if (!selectedVoucher.value) {
        toasts.add("ERROR", "Error", "No voucher selected. Nothing to print.");
        return;
    }

    window.open(route('print.cashVoucher', {
        VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
    }), '_blank');
}

async function printCheckVoucher() {
    if (!selectedVoucher.value) {
        toasts.add("ERROR", "Error", "No voucher selected. Nothing to print.");
        return;
    }

    window.open(route('print.checkVoucher', {
        VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
    }), '_blank');
}

function printBDOCheck() {
    if (!selectedVoucher.value) {
        toasts.add("ERROR", "Error", "No voucher selected. Nothing to print.");
        return;
    }

    const vouchertype = selectedVoucher.value.VOUCHER_ID.substring(2,4);

    if (vouchertype !== 'CK') {
        toasts.add("ERROR", "Error", "Only CHECK vouchers can be printed.");
    } else {
        //Format the amount into text
        const formattedAmount = formatAmountInWords(selectedVoucher.value.VOUCHER_AMT);
        //Generate the PDF
        window.open(route('print.bdoCheck', {
            VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
            AMT: formattedAmount,
        }), '_blank');
    }
}

const formatAmountInWords = (amount) => {
    try {
        const [wholePart, decimalPart] = amount.split('.');
        const wholeWords = numberToWords.toWords(parseInt(wholePart));
        const capitalizedWords = `${wholeWords.charAt(0).toUpperCase()}${wholeWords.slice(1)}`;

        return `${capitalizedWords} and ${decimalPart == undefined || decimalPart == "" ? 0 : decimalPart}/100 pesos only`;
    } catch (error) {
        return '0.00';
    }
};

const printVoucherPDFLink = computed(() => {
    if (!selectedVoucher.value) {
        return;
    }

    return route('print.finVoucherAllGeneric2', {
        VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
    });
});

const printVoucherSpreadsheetLink = computed(() => {
    if (!selectedVoucher.value) {
        return;
    }

    return route('api.vouchers.voucherrpt-spreadsheet', {
        VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
    });
});

async function clearCheck() {
    if (!selectedVoucher.value) {
        toasts.add("ERROR", "Error", e.message);
        return;
    }

    loadingFlags.add("clearCheck");
    try {
        if (selectedSeqNo.value == null) {
            toasts.add("ERROR", "Error", "No GL Item selected.");
        } else {
            const response = await axios.get(route('api.gl-accounts.show', { id:  glId.value }));
            if (response.data[0].GL_TYPE === null && checkBank.value === 0) {
                toasts.add("ERROR", "Error", "No BANK_ID specified.");
            } else if (response.data[0].GL_TYPE === null && checkNumber.value === "") {
                toasts.add("ERROR", "Error", "No CHECK NUM specified.");
            } else if (checkClear.value !== 'N' && checkClear.value !== 'Y') {
                toasts.add("ERROR", "Error", "Check Clear must be 'Y' or 'N'");
            } else {
                updateCheckClear();
            }
        }
    } catch (error) {
        toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
    }
    loadingFlags.delete("clearCheck");
}

async function updateCheckClear() {
    loadingFlags.add("updateCheckClear");
    try {
        const response = await axios.patch(route('api.vouchergl.update', {id : `${selectedVoucher.value.VOUCHER_ID}_${selectedSeqNo.value}` }), {
            CHECK_CLEAR: checkClear.value
        });
        if (response.data.message === 'Success') {
            toasts.add("SUCCESS", "Success", "GL Entry, CHECK_CLEAR flag updated.");
            fetchGLInfo();
        } else {
            toasts.add("ERROR", "Error", "Failed to update GL Entry.");
        }
    } catch (error) {
        toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
    }
    loadingFlags.delete("updateCheckClear");
}

async function deleteGLEntry() {
    if (!selectedVoucher.value) {
        toasts.add("ERROR", "Error", e.message);
        return;
    }

    loadingFlags.add("deleteGLEntry");
    try {
        if (selectedSeqNo.value == null) {
            toasts.add("ERROR", "Error", "No GL item selected.");
        } else {
            const response = await axios.delete(route('api.vouchergl.destroy', {id : `${selectedVoucher.value.VOUCHER_ID}_${selectedSeqNo.value}` }));
            if (response.data.message === 'Success') {
                toasts.add("SUCCESS", "Success", "GL Entry, CHECK_CLEAR flag updated.");
                fetchGLInfo();
            } else {
                toasts.add("ERROR", "Error", "Failed to delete GL Entry.");
            }
        }
        vouchersTableRefreshFlag.value = !vouchersTableRefreshFlag.value;
    } catch (error) {
        toasts.add("ERROR", "Error", error.response?.data.message ?? error.message);
    }
    loadingFlags.delete("deleteGLEntry");
}

async function insertGlEntry(type) {
    voucherGlErrors.value = null;

    loadingFlags.add("insertGlEntry");
    try {
        const lastEntry = glData.value[glData.value.length - 1] ?? null;
        const maxSeq = (lastEntry?.GL_SEQ_NO ?? 0) + 1;
        const formDataGL = {
            VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
            GL_SEQ_NO: maxSeq,
            GL_ID: glId.value,
            GL_AMT: debitCredit.value === 'debit' ? parseFloat(formatWithoutComma(debitCreditAmt.value)).toFixed(2) : parseFloat(-formatWithoutComma(debitCreditAmt.value)).toFixed(2),
            CREATED_BY: username.value,
            JOBORDER_ID: jobOrder.value,
            GL_PAY_TYPE: payeeType.value,
            GL_PAY_ID: glPay.value,
            PAYEE_DATE: payeeDate.value ? payeeDate.value : lastEntry?.PAYEE_DATE,
            CHECK_BANK: checkBank.value ? checkBank.value : 0,
            CHECK_NUM: checkNumber.value,
            CHECK_CLEAR: checkClear.value,
            TAXRATE: taxrate.value,
            LAST_UPDATED_BY: username.value,
            INVOICE_NUM: invoiceNumber.value,
        }
        if (type === 'insert') {
            await axios.post(route('api.vouchergl.store'), formDataGL);
            clearGLFields();
            toasts.add("SUCCESS", "Success", "GL Entry added.");
        } else {
            await axios.patch(route('api.vouchergl.update', {id : `${selectedVoucher.value.VOUCHER_ID}_${selectedSeqNo.value}` }), {
                GL_ID: glId.value,
                GL_AMT: debitCredit.value === 'debit' ? parseFloat(formatWithoutComma(debitCreditAmt.value)).toFixed(2) : parseFloat(-formatWithoutComma(debitCreditAmt.value)).toFixed(2),
                JOBORDER_ID: jobOrder.value,
                GL_PAY_TYPE: payeeType.value,
                GL_PAY_ID: glPay.value,
                PAYEE_DATE: payeeDate.value,
                CHECK_BANK: checkBank.value ? checkBank.value : 0,
                CHECK_NUM: checkNumber.value,
                CHECK_CLEAR: checkClear.value,
                TAXRATE: taxrate.value,
                LAST_UPDATED_BY: username.value,
                INVOICE_NUM: invoiceNumber.value,
            });
            toasts.add("SUCCESS", "Success", "GL entry, CHECK_CLEAR flag updated.");
        }
        fetchGLInfo();
        vouchersTableRefreshFlag.value = !vouchersTableRefreshFlag.value;
    } catch (e) {
        console.log(e);
        voucherGlErrors.value = e.response?.data.errors;
        toasts.add("ERROR", "Error", e.response?.data.message ?? e.message);
    }
    loadingFlags.delete("insertGlEntry");
}

function updateVoucherAmountTotal(data) {
    selectedVoucher.value.DEBIT = parseFloat(data.totalDebit);
    selectedVoucher.value.CREDIT = parseFloat(data.totalCredit);
}

function glAccountSelection() {
    const foundGLAccount = glAcctname.value.find(gl => gl.ACCTNAME === acctname.value);
    glId.value = foundGLAccount.GL_ID;
}

function handleTaxrate() {
    if(taxrate.value != 0) {
        taxrateText.value = (formatWithoutComma(debitCreditAmt.value) / 1.12) * (taxrate.value / 100);
        return;
    }
    taxrateText.value = 0;
}

function openSelectAccount(type) {
    selectedPayeeType.value = type;
    isSelectAccountOpen.value = true;
}

function handleSelectAccount(selectedAccount) {
    if (selectedPayeeType.value == 'CHECK') {
        checkBank.value = selectedAccount.ACCOUNT_ID;
        bankName.value = selectedAccount.ACCOUNT_NAME;
    } else if (selectedPayeeType.value == 'PAYEE') {
        glPay.value = selectedAccount.ACCOUNT_ID;
        payeeName.value = selectedAccount.ACCOUNT_NAME;
    }
}

function handleSelectEmployee(selectedEmployee) {
    glPay.value = selectedEmployee.EMPID;
    payeeName.value = selectedEmployee.FNAME + " " + selectedEmployee.LNAME
};

function handleSelectJobOrder(selectedJobOrder) {
    jono.value = selectedJobOrder.JONO;
    style.value = selectedJobOrder.STYLE;
    jobOrder.value = selectedJobOrder.JOBORDER_ID;
    isSelectJobOrderOpen.value = false;
};

function searchPayee(type) {
    isListVisible.value = false;
    switch (type) {
        case 'E':
            openSelectAccount('PAYEE');
            break;
        case 'I':
            isSelectEmployeeOpen.value = true;
            break;
        case '':
            toasts.add("ERROR", "Error", "Please select a payee type!");
        default:
            break;
    }
}

function formatWithComma(value){
    return Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

function formatWithoutComma(value) {
    const numericValue = Number(value.replace(/,/g, ''));
    return numericValue.toFixed(2);
}

function clearVoucherSelection() {
    clearGLFields();
    glData.value = [];
    voucherAmt.value = '';
    managerApprove.value = null;
    accountingApprove.value = null;
    glBalance.value = {
        CREDIT: 0,
        DEBIT: 0,
        BALANCE: 0,
    };
}

function clearGLFields() {
    glId.value = '';
    glPay.value = 0;
    taxrate.value = 0;
    jobOrder.value = 0;
    acctname.value = '';
    jono.value = 'NONE';
    checkBank.value = null;
    className.value = '';
    payeeType.value = '';
    payeeDate.value = null;
    style.value = 'NONE';
    checkNumber.value = null;
    debitCredit.value = 'debit';
    bankName.value = 'NONE';
    payeeName.value = 'NONE';
    invoiceNumber.value = '';
    debitCreditAmt.value = '0.00';
    selectedSeqNo.value = null;
}

function redirectToUpdateVoucher() {
    window.open(route('voucher.update-voucher-admin', {
        VOUCHER_ID: selectedVoucher.value.VOUCHER_ID,
    }));
}

const {
    vouchers: vouchersForValidation,
    getVouchers: getVouchersForValidation,
    getVoucher: getVoucherForValidation,
} = useVouchers();

async function validateVoucherFileInputsCallback(voucherIds) {
    await getVouchersForValidation({
        rows: 0,
        filters: {
            VOUCHER_ID: {
                value: voucherIds,
                matchMode: 'in'
            }
        },
    });

    const fetchedVouchers = vouchersForValidation.value.reduce((fetchedVouchers, voucher) => ({
        ...fetchedVouchers,
        [voucher.VOUCHER_ID]: true
    }), {});

    const isValid = [];

    for (const voucherId of voucherIds) {
        if (!(voucherId in fetchedVouchers)) {
            isValid.push(false);
            continue;
        }

        isValid.push(true);
    }

    return isValid;
}

async function validateVoucherFileInputCallback(voucherId) {
    try {
        await getVoucherForValidation(voucherId);
        return true;
    } catch (e) {
        if (e.status == 404) {
            return false;
        } else {
            throw e;
        }
    }
}

function extractGovtVoucherIdCallback(name) {
    const parts = name.split('.');
    return parts.slice(0, parts.length - 1).join('.');
};

function validateGovtVoucherFileInputsCallback(govtVoucherIds) {
    return govtVoucherIds.map((govtVoucherId) => validateGovtVoucherFileInputCallback(govtVoucherId));
}

function validateGovtVoucherFileInputCallback(govtVoucherId) {
    return /^(SSS|SSSCAL|PI|PICAL)(\d{10})(.\d+)?$/.test(govtVoucherId);
}
</script>

<style scoped>
.import-hours-nideka-table-wrapper {
    width: 100%;
    height: 200px;
    overflow-y: auto;
    background-color: #fff;
}

.select-employee-table {
    cursor: pointer;
}

.selected-row,
tr.selected-row:hover td {
    background-color: #2a1770;
    color: #fff;
}

.select-employee-table-wrapper {
    height: 16rem;
    overflow-y: auto;
}

.table-striped tbody:nth-child(even) {
    background-color: white;
}

thead {
    top: 0;
    margin: 0;
    position: sticky;
    background-color: lightslategrey;
    color: #fff;
}

th {
    border-style: solid;
    border-color: #fff;
    border-width: 0 1px;
}

td:hover {
    cursor: pointer;
}

.dropdown {
    cursor: pointer;
    padding: 3px;
}

.dropdown:hover {
    background-color: #0d6efd;
    color: white;
}

.toast-shadow {
    padding: 3;
    z-index: 11;
    background: rgb(0, 0, 0, 0.5);
}
.dropdown-list {
    z-index: 1;
}

.dropdown-text {
    background-color: #f9fafb;
    border: 1px solid #ced4da;
    color: black;
}
</style>
