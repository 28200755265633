<style scoped>
    .sample-image {
        max-height: 150px;
    }
</style>

<template>
    <div v-if="isLoading"
        class="spinner-border"
        role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
    <div v-else-if="imageURLs?.length > 0" class="row g-3">
        <div v-for="(imageURL, index) in imageURLs"
            class="col-md-4 text-center">
            <p v-if="imageLoadingStatuses[index]">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                Loading image #{{ index + 1 }}
            </p>
            <img :src="imageURL"
                :alt="sample.DESCRIPTION + ' sample image ' + index"
                @load="_ => { imageLoadingStatuses[index] = false; }"
                @click="_ => { isImageSelected[index] = !isImageSelected[index] ?? true; }"
                class="img-fluid sample-image"
                :class="{ 'border border-4 border-danger': isImageSelected[index] }"
                @error="_ => { setDefaultImage(index); }"
            />
        </div>
    </div>
    <div v-else>
        No images.
    </div>
    <hr class="my-4">
    <div class="text-end">
        <button type="button"
            class="btn btn-danger btn-sm"
            :disabled="Object.values(isImageSelected).filter(status => status).length == 0 || isDeleting"
            @click="deleteSelectedImages">
            <span v-if="isDeleting"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true">
            </span>
            <i class="icon ph-bold ph-trash me-2"></i>Delete Selected Images
        </button>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useSamples } from '@/composables/data/samples';
import { useImages } from '@/composables/data/images';
import { useToastsStore } from '@/stores/toasts';

const props = defineProps({
    sampleId: Number,
    class: String
});

const toasts = useToastsStore();

const { sample, getSample } = useSamples();

const { deleteImage } = useImages();

const isLoading = ref(false);

const imageLoadingStatuses = ref(null);
const imageURLs = ref(null);

const isDeleting = ref(false);

const isImageSelected = ref({});

async function fetchImages() {
    imageLoadingStatuses.value = null;
    imageURLs.value = null;

    isImageSelected.value = {};

    if (!props.sampleId) {
        return;
    }

    isLoading.value = true;
    try {
        await getSample(props.sampleId, {
            include_relations: 'images'
        });
        setTimeout(() => {
            imageURLs.value = sample.value.images?.filter((image) => image.status == 'DONE').map((image) => image.url);
            imageLoadingStatuses.value = sample.value.images?.map((_) => true);
        });
    } catch (e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.message);
    }
    isLoading.value = false;
}

function setDefaultImage(index) {
    imageLoadingStatuses.value[index] = true;
    imageURLs.value[index] = `${Ziggy.url}/img/bagnoimage.jpg`;
}

async function deleteSelectedImages() {
    isDeleting.value = true;
    try {
        for(const imageIndex of Object.keys(isImageSelected.value).filter(imageIndex => isImageSelected.value[imageIndex])) {
            await deleteImage(sample.value.images[imageIndex].id);
        }
        toasts.add("SUCCESS", "Success", "Successfully deleted images.");
        fetchImages();
    } catch(e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.message);
    }
    isDeleting.value = false;
    isImageSelected.value = {};
    fetchImages();
}

watch(() => props.sampleId, fetchImages);
</script>
