<template>
    <div class="row">
        <div class="col-lg-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-receipt me-2"></i> Update Voucher
                </legend>

                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Voucher ID"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="form.VOUCHER_ID"
                            id-prefix="voucherId"
                            :errors="errors?.VOUCHER_ID"
                            disabled
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Voucher Date"
                            is-horizontal
                            type="date"
                            class="mb-md-1 mb-2"
                            v-model="form.VOUCHER_DATE"
                            id-prefix="voucherDate"
                            :errors="errors?.VOUCHER_DATE"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Voucher Amount"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="form.VOUCHER_AMT"
                            id-prefix="voucherAmt"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors?.VOUCHER_AMT"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Description"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="form.DESCRIPTION"
                            id-prefix="description"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors?.DESCRIPTION"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormTextarea
                            label="Memo"
                            id-prefix="memo"
                            v-model="form.MEMO"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            :errors="errors?.MEMO"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormSelect
                            label="Payee Type"
                            is-horizontal
                            v-model="form.PAYEE_TYPE"
                            :options="payeeTypes"
                            :option-to-string="(payeeType) => payeeType.name"
                            :option-value="(payeeType) => payeeType.value"
                            :errors="errors?.PAYEE_TYPE"
                            class="mb-md-1 mb-2"
                            label-class="col-xl-4 col-md-5 label-sm"
                            select-class="form-select-sm"
                            select-container-class="col-xl-8 col-md-7 col-12"
                        />
                    </div>
                    <div class="col-xl-4">
                        <button class="btn btn-primary btn-sm px-2"
                            @click="searchPayee">
                            <i class="icon ph-bold ph-magnifying-glass me-2"></i>Search
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Payee ID"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="form.PAYEE_ID"
                            id-prefix="payeeId"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors?.PAYEE_ID"
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Payee Name"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="form.PAYEE_NAME"
                            id-prefix="payeeName"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors?.PAYEE_NAME"
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8">
                        <FormInput
                            label="Approved By"
                            is-horizontal
                            class="mb-md-1 mb-2"
                            v-model="form.APPROVED_BY"
                            id-prefix="approvedBy"
                            label-class="col-xl-4 col-md-5 label-sm"
                            input-class="form-control-sm"
                            input-container-class="col-xl-8 col-md-7 col-12"
                            :errors="errors?.APPROVED_BY"
                            disabled
                        />
                    </div>
                    <div class="col-xl-4">
                        <button class="btn btn-primary btn-sm px-2"
                            :disabled="!form?.APPROVED_BY"
                            @click="deleteApproval">
                            <i class="icon ph-bold ph-trash me-2"></i>Delete Approval
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-8 mt-3">
                        <div class="row">
                            <div class="col-xl-8 offset-xl-4">
                                <button class="btn btn-primary btn-sm"
                                    @click="handleSubmit">
                                    <i class="icon ph-bold ph-pencil-line me-2"></i>UPDATE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <SelectAccount
        :is-open="isSelectingAccount"
        :on-close="(_) => (isSelectingAccount = false)"
        :onSelectAccount="handleSelectAccount"
    />
    <SelectEmployee
        :is-open="isSelectingEmployee"
        @close="_ => isSelectingEmployee = false"
        @select="(employee) => {
            handleSelectEmployee(employee);
            isSelectingEmployee = false;
        }"
    />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useVouchers } from '@/composables/data/vouchers';
import FormInput from '@/components/utils/FormInput.vue';
import FormSelect from '@/components/utils/FormSelect.vue';
import SelectAccount from '@/components/utils/SelectAccount.vue';
import SelectEmployee from '@/components/utils/SelectEmployee.vue';
import FormTextarea from '@/components/utils/FormTextarea.vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { voucher, getVoucher, putVoucher } = useVouchers();

const form = ref({
    VOUCHER_ID: null,
    VOUCHER_DATE: null,
    VOUCHER_AMT: null,
    DESCRIPTION: null,
    MEMO: null,
    PAYEE_TYPE: null,
    PAYEE_ID: null,
    APPROVED_BY: null,
    PAYEE_NAME: null,
});

const errors = ref(null);

const isSelectingAccount = ref(false);
const isSelectingEmployee = ref(false);

const payeeTypes = [
    { name: 'External', value: 'E'},
    { name: 'Internal', value: 'I'}
];

async function fetchVoucher() {
    const voucherId = new URLSearchParams(window.location.search).get('VOUCHER_ID');

    loadingFlags.add("fetchVoucher");
    try {
        await getVoucher(voucherId, {
            include_relations: "payee,accountingApproval"
        });
        form.value = Object.assign({}, voucher.value);
        form.value.PAYEE_NAME = voucher.value.payee?.NAME;
        form.value.APPROVED_BY = voucher.value.accountingApproval?.APPROVED_BY;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchVoucher");
}

onMounted(fetchVoucher);

function searchPayee() {
    switch (form.value.PAYEE_TYPE) {
        case 'E':
            isSelectingAccount.value = true;
            break;
        case 'I':
            isSelectingEmployee.value = true;
            break;
        default:
            break;
    }
}

function handleSelectAccount(selectedAccount) {
    form.value.PAYEE_ID = selectedAccount.ACCOUNT_ID;
    form.value.PAYEE_NAME = selectedAccount.ACCOUNT_NAME;
}

function handleSelectEmployee(selectedEmployee) {
    form.value.PAYEE_ID = selectedEmployee.EMPID;
    form.value.PAYEE_NAME = `${selectedEmployee.FNAME} ${selectedEmployee.LNAME}`;
}

async function handleSubmit() {
    loadingFlags.add("handleSubmit");
    try {
        const response = await putVoucher(voucher.value.VOUCHER_ID, form.value);
        toasts.add("SUCCESS", "Success", response.data.message);
        fetchVoucher();
    } catch (e) {
        errors.value = e.errors;
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete("handleSubmit");
}

async function deleteApproval() {
    loadingFlags.add("deleteApproval");
    try {
        await axios.delete(route('api.voucher-accounting-approval.destroy',{
            id: voucher.value.VOUCHER_ID
        }));
        toasts.add('SUCCESS', 'Success', 'Accounting approval deleted.');
        fetchVoucher();
    } catch (e) {
        console.log(e);
        toasts.add('ERROR', 'Error', e.response?.data.message ?? e.message);
    }
    loadingFlags.delete("deleteApproval");
}
</script>
