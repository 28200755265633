import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from 'axios';

export function useSalesActualDetails() {
    const salesActualDetails = ref(null);
    const perMonthEntries = ref(null);

    const getSalesActualDetailsInMonthAndLocation = async (month, location) => {
        try {
            const getSalesActualDetailsInMonthAndLocationResponse =
                await axios.get(route('api.sales-actual-details.by-month.by-location.index', {
                    month_date: month,
                    location_name: location
                }));
            salesActualDetails.value = getSalesActualDetailsInMonthAndLocationResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching sales details.');
        }
    };

    const getPerMonthEntries = async (fromYear, toYear, location) => {
        try {
            const getPerMonthEntriesResponse =
                await axios.get(route('api.sales-actual-details.per-month-entries.index', {
                    from_year: fromYear,
                    to_year: toYear,
                    location: location,
                }));
            perMonthEntries.value = getPerMonthEntriesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching per month entries.');
        }
    };

    const putSalesActualDetailsInMonthAndLocation = async (month, location, salesActualDetails) => {
        try {
            return await axios.put(route('api.sales-actual-details.by-month.by-location.update', {
                month_date: month,
                location_name: location
            }), {
                details: salesActualDetails
            });
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while updating sales details.');
        }
    };
    
    const postSalesActualDetailFile = async (month, location, formData) => {
        try {
            return await axios.post(route('api.sales-actual-details.by-month.by-location.sales-file.store', {
                month_date: month,
                location_name: location
            }), formData);
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while uploading file for sales details.');
        }
    };
    
    return {
        salesActualDetails,
        perMonthEntries,
        getSalesActualDetailsInMonthAndLocation,
        getPerMonthEntries,
        putSalesActualDetailsInMonthAndLocation,
        postSalesActualDetailFile,
    };
}
