<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-plus me-2"></i>Create/Update Purchase Order
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <FormInput
                        type="number"
                        v-model="form.ORDER_PO_ID"
                        disabled
                        label="Order ID"
                        id-prefix="orderPoId"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormInput
                        type="text"
                        v-model="form.PURCHASE_ORDER_NUMBER"
                        label="PO Number"
                        id-prefix="purchaseOrderNumber"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.PURCHASE_ORDER_NUMBER"
                    />
                    <FormInput
                        type="date"
                        v-model="form.DATE_ARRIVED"
                        label="Date Arrived"
                        id-prefix="dateArrived"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        :errors="errors.DATE_ARRIVED"
                    />
                    <div class="row gx-1">
                        <div class="col">
                            <FormInput
                                type="text"
                                v-model="form.SUPPLIER_ID"
                                disabled
                                label="Supplier ID"
                                id-prefix="supplierId"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-5 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-7 col-md-7 col-12"
                                :form-text="selectedRelationshipsDisplay.supplier ?.SUPPLIER_NAME"
                                :errors="errors.SUPPLIER_ID"
                            />
                        </div>
                        <div class="col-auto">
                            <div class="text-end mb-md-1 mb-2">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary px-1"
                                    @click="
                                        (_) => {
                                            isSelectOrderSupplierPopupOpen = true;
                                        }
                                    "
                                >
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                                </button>
                            </div>
                        </div>
                    </div>
                    <FormSelect
                        v-model="form.STATUS"
                        :options="STATUS_OPTIONS"
                        :option-value="(option) => option.value"
                        :option-to-string="(option) => option.label"
                        label="Status"
                        id-prefix="status"
                        :errors="errors.STATUS"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        select-class="form-select-sm"
                        select-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <div class="text-end mt-3">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm me-2"
                            :disabled="loadingFlags.has('insertPurchaseOrder') || loadingFlags.has('updatePurchaseOrder')"
                            @click="
                                (_) => {
                                    if (!purchaseOrder) {
                                        insertPurchaseOrder();
                                    } else {
                                        updatePurchaseOrder();
                                    }
                                }
                            "
                        >
                            <span
                                v-if="loadingFlags.has('insertPurchaseOrder') || loadingFlags.has('updatePurchaseOrder')"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            >
                            </span>
                            <i class="icon ph-bold ph-floppy-disk me-2"></i>Save
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm"
                            @click="reset"
                        >
                            <i class="icon ph-bold ph-x me-2"></i> Clear
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <SelectOrderSupplierPopup
        :is-open="isSelectOrderSupplierPopupOpen"
        @close="
            (_) => {
                isSelectOrderSupplierPopupOpen = false;
            }
        "
        @select="
            (selectedOrderSupplier) => {
                form.SUPPLIER_ID = selectedOrderSupplier.SUPPLIER_ID;
                selectedRelationshipsDisplay.supplier = selectedOrderSupplier;
                isSelectOrderSupplierPopupOpen = false;
            }
        "
        @error="
            (message) => {
                isSelectOrderSupplierPopupOpen = false;
                addFlashMessage('ERROR', message);
                scrollToTop();
            }
        "
    />
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";

import { usePurchaseOrders } from "@/composables/data/purchaseOrders";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import SelectOrderSupplierPopup from "@/components/utils/popups/SelectOrderSupplierPopup.vue";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["success", "close"]);

const toasts = useToastsStore();

const STATUS_OPTIONS = [
    {
        value: "I",
        label: "Initial",
    },
    {
        value: "R",
        label: "Received",
    },
    {
        value: "A",
        label: "Approved",
    },
    {
        value: "F",
        label: "Final",
    },
    {
        value: "C",
        label: "Cancelled",
    },
];

const { postPurchaseOrder, putPurchaseOrder } = usePurchaseOrders();

const purchaseOrder = defineModel();

const form = ref({
    ORDER_PO_ID: null,
    PURCHASE_ORDER_NUMBER: null,
    DATE_ARRIVED: null,
    SUPPLIER_ID: null,
    STATUS: null,
});

const selectedRelationshipsDisplay = ref({});

const errors = ref({});

const loadingFlags = useLoadingFlagsStore();

const isSelectOrderSupplierPopupOpen = ref(false);

watch(purchaseOrder, () => {
    errors.value = {};
    selectedRelationshipsDisplay.value.supplier = purchaseOrder.value?.supplier;
    form.value = Object.assign(
        {
            ORDER_PO_ID: null,
            PURCHASE_ORDER_NUMBER: null,
            DATE_ARRIVED: null,
            SUPPLIER_ID: null,
            STATUS: null,
        },
        purchaseOrder.value
    );
});

function reset() {
    purchaseOrder.value = null;
    if (!purchaseOrder.value) {
        form.value = {
            ORDER_PO_ID: null,
            PURCHASE_ORDER_NUMBER: null,
            DATE_ARRIVED: null,
            SUPPLIER_ID: null,
            STATUS: null,
        };
    }
    errors.value = {};
}

async function insertPurchaseOrder() {
    errors.value = {};

    loadingFlags.add("insertPurchaseOrder");
    try {
        const newPurchaseOrder = await postPurchaseOrder(form.value);
        toasts.add(
            "SUCCESS",
            "success",
            "Successfully created purchase order."
        );
        purchaseOrder.value = newPurchaseOrder;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "error", e.message);
    }
    loadingFlags.delete("insertPurchaseOrder");
}

async function updatePurchaseOrder() {
    errors.value = {};

    loadingFlags.add("updatePurchaseOrder");
    try {
        const newPurchaseOrder = await putPurchaseOrder(
            purchaseOrder.value.ORDER_PO_ID,
            form.value
        );
        toasts.add(
            "SUCCESS",
            "success",
            "Successfully updated purchase order."
        );
        purchaseOrder.value = newPurchaseOrder;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "error", e.message);
    }
    loadingFlags.delete("updatePurchaseOrder");
}
</script>

<style scoped>
.container {
    max-width: 500px;
}
</style>
