<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-palette me-2"></i> Colors Table
        </legend>
        <DataTable
            :loading="isLoading"
            :value="colors"
            paginator
            :rows="10"
            selection-mode="single"
            v-model:selection="selectedColor"
            data-key="COLOR"
            filter-display="row"
            v-model:filters="filters"
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
        >
            <Column
                field="COLOR"
                header="Color Name"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="IS_ACTIVE"
                header="Is Active"
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <FormSelect
                        v-model="filterModel.value"
                        :options="[
                            { value: 1, verbose: 'Active' },
                            { value: 0, verbose: 'Inactive' },
                        ]"
                        :option-value="(option) => option.value"
                        :option-to-string="(option) => option.verbose"
                        @change="filterCallback()"
                        placeholder="Search"
                        hide-label
                        select-class="form-select-sm form-select-class"
                    />
                </template>
                <template #body="{ data }">
                    <span v-if="data.IS_ACTIVE" class="badge bg-success">Active</span>
                    <span v-else class="badge bg-secondary">Inactive</span>
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data yet.
                </div>
            </template>
            <template #footer>
                {{ colors?.length ?? 0 }} total colors
            </template>
        </DataTable>
        <div class="text-end">
            <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="exportColorsSpreadsheet"
            >
                <i class="icon ph-bold ph-export me-2"></i>Export Colors to Spreadsheet
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useColors } from "@/composables/data/colors";

import FormSelect from "@/components/utils/FormSelect.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    refreshFlag: null,
});

const emit = defineEmits(["error"]);

const { filters } = useDataTableParams(["COLOR", "IS_ACTIVE"]);

const { colors, getColors } = useColors();

const isLoading = ref(false);

const selectedColor = defineModel();

async function fetchColors() {
    colors.value = null;

    isLoading.value = true;
    try {
        await getColors();
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

onMounted(fetchColors);

watch(() => props.refreshFlag, fetchColors);

function exportColorsSpreadsheet() {
    window.open(
        route("api.colors.spreadsheet.show", {
            IS_ACTIVE: filters.value.IS_ACTIVE.value,
        })
    );
}
</script>
