import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useRolesAndPermissions() {
    const roles = ref(null);
    const permissions = ref(null);

    const getRoles = async () => {
        try {
            const getRolesResponse = await axios.get(route('api.roles.index'));
            roles.value = getRolesResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching roles.');
        }
    };

    const getPermissions = async () => {
        try {
            const getPermissionsResponse = await axios.get(route('api.permissions.index'));
            permissions.value = getPermissionsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching permissions.');
        }
    };

    return {
        roles,
        permissions,
        getRoles,
        getPermissions,
    };
}
