<style scoped>
.wrapper {
    width: 100%;
    max-height: 15rem;
    overflow-y: auto;
}

.wrapper table thead {
    position: sticky;
    top: 0;
    margin: 0;
    background-color: lightslategrey;
    color: #fff;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
<template>
    <fieldset :class="class">
        <legend><i class="icon ph-bold ph-money me-2"></i>PRICE</legend>
        <div class="row">
            <div class="col-md-7">
                <div class="wrapper">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>DATE_EFF</th>
                                <th>PRICE</th>
                                <th>CREATED BY</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="accountItemPriceList.length == 0">
                                <td colspan="6" class="text-center">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-database me-2"></i>No price.
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-else
                                v-for="price in accountItemPriceList"
                                :class="{
                                    'bg-primary text-white':
                                        price.id === accountItemPrice.id,
                                }"
                                @click="selectAccountItemPrice(price)"
                                class="cursor-pointer"
                            >
                                <td>{{ price.DATE_EFF }}</td>
                                <td>{{ price.PRICE }}</td>
                                <td>{{ price.CREATED_BY }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-5">
                <FormInput
                    type="date"
                    label="DATE EFF:"
                    id-prefix="dateEFF"
                    class="mb-md-1 mb-2 align-items-center"
                    is-horizontal
                    label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                    input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                    input-class="form-control-sm"
                    v-model="accountItemPrice.DATE_EFF"
                    :errors="errors.DATE_EFF"
                    :disabled="disableControl"
                />
                <FormInput
                    type="text"
                    label="PRICE:"
                    id-prefix="price"
                    class="mb-md-1 mb-2 align-items-center"
                    is-horizontal
                    label-class="col-xl-4 col-lg-3 col-md-4 label-sm"
                    input-container-class="col col-xl-8 col-lg-9 col-md-8 col-12"
                    input-class="form-control-sm"
                    v-model="accountItemPrice.PRICE"
                    :errors="errors.PRICE"
                    :disabled="disableControl"
                />
            </div>
        </div>
        <div class="text-end mt-3">
            <button
                class="btn btn-primary me-2 mb-1 btn-sm"
                :disabled="disableControl"
                :hidden="accountItemPrice.id !== null"
                @click="handleInsertAccountItemPrice"
            >
                <i class="icon ph-bold ph-plus me-2"></i>INSERT
            </button>
            <button
                class="btn btn-primary me-2 mb-1 btn-sm"
                :disabled="disableControl"
                :hidden="accountItemPrice.id === null"
                @click="handleUpdateAccountItemPrice"
            >
                <i class="icon ph-bold ph-pencil-simple-line me-2"></i>UPDATE
            </button>
            <button
                class="btn btn-primary me-2 mb-1 btn-sm"
                :disabled="disableControl"
                @click="handleResetAccountItemPrice"
            >
                <i class="icon ph-bold ph-arrow-clockwise me-2"></i>RESET
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";

import FormInput from "../../../utils/FormInput.vue";

import scrollToTop from "../../../../utils/scrollToTop";

const props = defineProps({
    item: Object,
    account: Object,
});

const emit = defineEmits(["success", "error"]);

const errors = ref({});

const accountItemPriceList = ref([]);
const accountItemPrice = ref({
    id: null,
    DATE_EFF: null,
    PRICE: null,
});

const disableControl = ref(true);

watch(
    () => props.item,
    async () => {
        disableControls(props.item.id);
        handleResetAccountItemPrice();
        getAccountItemPriceList();
    }
);

function selectAccountItemPrice(price) {
    accountItemPrice.value = {
        id: price.id,
        DATE_EFF: price.DATE_EFF,
        PRICE: price.PRICE,
    };
}

async function getAccountItemPriceList() {
    if (props.account.ACCOUNT_ID === "New Emp") {
        accountItemPriceList.value = [];
        handleResetAccountItemPrice();
        return;
    }
    if (props.item.id === null) {
        accountItemPriceList.value = [];
        handleResetAccountItemPrice();
        return;
    }

    try {
        const response = await axios.get(
            route("api.account-item-prices.index"),
            {
                params: {
                    ITEM_NAME: props.item.ITEM_NAME,
                    ACCOUNT_ID: props.account.ACCOUNT_ID,
                },
            }
        );
        accountItemPriceList.value = response.data.data;
    } catch (e) {
        console.log(e);
        scrollToTop();
        emit(
            "error",
            "An error occurred while fetching account item prices. Please contact your administrator."
        );
    }
}

async function handleInsertAccountItemPrice() {
    try {
        const response = await axios.post(
            route("api.account-item-prices.store"),
            {
                ACCOUNT_ID: props.account.ACCOUNT_ID,
                ITEM_NAME: props.item.ITEM_NAME,
                DATE_EFF: accountItemPrice.value.DATE_EFF,
                PRICE: accountItemPrice.value.PRICE,
            }
        );
        errors.value = {};
        scrollToTop();
        emit("success", response.data);
        getAccountItemPriceList();
        handleResetAccountItemPrice();
    } catch (e) {
        console.log(e);
        if (e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            emit(
                "error",
                "An error occurred while saving account item price. Please contact your administrator."
            );
        }
    }
}

async function handleUpdateAccountItemPrice() {
    try {
        const response = await axios.patch(
            route("api.account-item-prices.update", {
                id: accountItemPrice.value.id,
            }),
            {
                DATE_EFF: accountItemPrice.value.DATE_EFF,
                PRICE: accountItemPrice.value.PRICE,
            }
        );
        errors.value = {};
        scrollToTop();
        emit("success", response.data);
        getAccountItemPriceList();
    } catch (e) {
        console.log(e);
        if (e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            emit(
                "error",
                "An error occurred while saving account item price. Please contact your administrator."
            );
        }
    }
}

function disableControls(id) {
    if (id === null) {
        disableControl.value = true;
    } else {
        disableControl.value = false;
    }
}

function handleResetAccountItemPrice() {
    accountItemPrice.value = {
        id: null,
        DATE_EFF: null,
        PRICE: null,
    };
}
</script>
