<template>
    <div class="row mb-3">
        <div class="col-lg-4">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-sliders me-2"></i>Filters
                </legend>
                <form @submit.prevent="applyFilters">
                    <FormInput
                        type="text"
                        v-model="fromTimePeriodId"
                        label="Start Time Period"
                        id-prefix="startTimePeriod"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        disabled
                    />
                    <div class="text-end mb-3">
                        <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            @click="(_) => {
                                timePeriodFieldToSet = 'fromTimePeriod';
                                isSelectTimePeriodPopupOpen = true;
                            }"
                        >
                            Select
                        </button>
                    </div>
                    <FormInput
                        type="text"
                        v-model="toTimePeriodId"
                        label="End Time Period"
                        id-prefix="endTimePeriod"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        disabled
                    />
                    <div class="text-end mb-3">
                        <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            @click="(_) => {
                                timePeriodFieldToSet = 'toTimePeriod';
                                isSelectTimePeriodPopupOpen = true;
                            }"
                        >
                            Select
                        </button>
                    </div>
                    <FormInput
                        type="text"
                        v-model="filters.jono"
                        label="JONO"
                        id-prefix="jono"
                        class="mb-3"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <div class="text-end">
                        <button
                            type="submit"
                            class="btn btn-sm btn-primary"
                        >
                            <i class="fa-solid fa-table"></i>
                            Apply Filters
                        </button>
                    </div>
                </form>
            </fieldset>
        </div>
    </div>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-table me-2"></i>Joborder Report
        </legend>
        <JoborderReportTable
            :from-time-period-id="appliedFilters?.fromTimePeriod?.TIME_PERIOD"
            :to-time-period-id="appliedFilters?.toTimePeriod?.TIME_PERIOD"
            :jono-contains="appliedFilters?.jono"
        />
    </fieldset>

    <SelectTimePeriodPopup
        can-select-paytype
        :is-open="isSelectTimePeriodPopupOpen"
        :paytype-options="['WEEKLY', 'BIMONTHLY']"
        @close="(_) => {
            isSelectTimePeriodPopupOpen = false;
        }"
        @select="(selectedTimePeriod) => {
            filters[timePeriodFieldToSet] = selectedTimePeriod;
            isSelectTimePeriodPopupOpen = false;
        }"
    />
</template>

<script setup>
import FormInput from '@/components/utils/FormInput.vue';
import SelectTimePeriodPopup from '@/components/utils/popups/SelectTimePeriodPopup.vue';
import { useToastsStore } from '@/stores/toasts';
import { computed, ref } from 'vue';
import JoborderReportTable from './JoborderReportTable.vue';

const toasts = useToastsStore();

const filters = ref({
    fromTimePeriod: null,
    toTimePeriod: null,
    jono: '',
});

const fromTimePeriodId = computed(() => filters.value.fromTimePeriod?.TIME_PERIOD ?? null);
const toTimePeriodId = computed(() => filters.value.toTimePeriod?.TIME_PERIOD ?? null);

const timePeriodFieldToSet = ref(null);

const isSelectTimePeriodPopupOpen = ref(false);

const appliedFilters = ref(null);

async function applyFilters() {
    let hasErrors = false;

    if (!filters.value.fromTimePeriod) {
        toasts.add(
            "ERROR",
            "Error",
            "Please select a starting time period."
        );
        hasErrors = true;
    }

    if (!filters.value.toTimePeriod) {
        toasts.add(
            "ERROR",
            "Error",
            "Please select an ending time period."
        );
        hasErrors = true;
    }

    if (
        filters.value.fromTimePeriod &&
        filters.value.toTimePeriod &&
        filters.value.fromTimePeriod.PAYTYPE !=
            filters.value.toTimePeriod.PAYTYPE
    ) {
        toasts.add(
            "ERROR",
            "Error",
            "The paytypes of both time periods must be the same."
        );
        hasErrors = true;
    }

    if (!filters.value.jono) {
        toasts.add(
            "ERROR",
            "Error",
            "Please specify a JONO."
        );
        hasErrors = true;
    }

    if (hasErrors) {
        return;
    }

    appliedFilters.value = Object.assign({}, filters.value);
}
</script>
