<style scoped>
.wrapper {
    width: 100%;
    max-height: 15rem;
    overflow-y: auto;
    background-color: #fff;
}

.wrapper table thead {
    position: sticky;
    top: 0;
    margin: 0;
    background-color: lightslategrey;
    color: #fff;
}
</style>

<template>
    <div class="row">
        <div class="col-xl-6 col-md-10">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-identification-badge me-2"></i>Insert New Account Type
                </legend>
                <div class="row g-3 mb-3">
                    <div class="col-md-6">
                        <div class="wrapper">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>ACCOUNT TYPES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="accountType in accountTypeList"
                                        class="cursor-pointer"
                                    >
                                        <td>{{ accountType.ACCOUNT_TYPE }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <FormInput
                            label="Account Type:"
                            v-model="accountType"
                            class="w-100"
                            input-class="form-control-sm w-100"
                            :errors="errors.ACCOUNT_TYPE"
                        />
                        <div class="text-end mt-2">
                            <button
                                class="btn btn-primary btn-sm"
                                @click="handleInsertAccountType"
                            >
                                <i class="icon ph-bold ph-plus me-2"></i>INSERT
                            </button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import scrollToTop from "../../../../../js/utils/scrollToTop.js";
import { useFlashMessages } from "../../../../composables/flashmessages";

import FormInput from "../../../utils/FormInput.vue";

const { addFlashMessage } = useFlashMessages();
const errors = ref({});

const accountTypeList = ref([]);
const accountType = ref(null);

onMounted(() => {
    getAccountTypes();
});

async function getAccountTypes() {
    try {
        const response = await axios.get(route("api.account-types.index"));
        accountTypeList.value = response.data.data;
    } catch (e) {
        console.log(e);
        scrollToTop();
        addFlashMessage(
            "ERROR",
            "An error occurred while fetching account types. Please contact your administrator."
        );
    }
}

async function handleInsertAccountType() {
    try {
        const response = await axios.post(route("api.account-types.store"), {
            ACCOUNT_TYPE: accountType.value,
        });
        errors.value = {};
        scrollToTop();
        addFlashMessage("SUCCESS", response.data);
        getAccountTypes();
        handleResetAccountType();
    } catch (e) {
        console.log(e);
        if (e.response && e.response.status == 422) {
            errors.value = e.response.data.errors;
        } else {
            scrollToTop();
            addFlashMessage(
                "ERROR",
                "An error occurred while saving account type. Please contact your administrator."
            );
        }
    }
}

function handleResetAccountType() {
    errors.value = {};
    accountType.value = null;
}
</script>
