<template>
    <div class="row my-3">
        <div class="col-lg-3 mb-lg-0 mb-3">
            <TimePeriodTableCard v-model="selectedTimePeriod" />
        </div>
        <div class="col-lg-9">
            <div class="row h-100">
                <div class="col-md-6 mb-md-0 mb-3">
                    <ReconLocationsTableCard
                        :time-period="selectedTimePeriod"
                        v-model="selectedLocationReconParm"
                        class="h-100">
                    </ReconLocationsTableCard>
                </div>
                <div class="col-md-6">
                    <InventoryCheckCountsTableCard
                        :location="selectedLocationReconParm?.LOCATION"
                        v-model="selectedInventoryCheckCount"
                        class="h-100"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3">
            <ReconInfoGenerationsTableCard
                :refresh-flag="reconInfoGenerationsTableRefreshFlag"
                class="mb-3"
            />
            <form @submit.prevent="generateReconInfo" class="mb-3">
                <fieldset>
                    <legend>
                        <i class="icon ph-bold ph-info me-2"></i>Details
                    </legend>
                    <FormInput
                        v-model="timePeriodStartDate"
                        disabled
                        label="Start Date"
                        id-prefix="startDateDisplay"
                        class="mb-xl-1 mb-2"
                        is-horizontal
                        :errors="errors.TIME_PERIOD"
                        label-class="col-xl-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-7 col-12"
                    />
                    <FormInput
                        v-model="timePeriodEndDate"
                        disabled
                        label="End Date"
                        id-prefix="endDateDisplay"
                        class="mb-xl-1 mb-2"
                        is-horizontal
                        :errors="errors.TIME_PERIOD"
                        label-class="col-xl-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-7 col-12"
                    />
                    <FormInput
                        v-model="startAdjDate"
                        label="Start Adj Date"
                        id-prefix="startAdjDate"
                        class="mb-xl-1 mb-2"
                        is-horizontal
                        :errors="errors.START_ADJ_DATE"
                        label-class="col-xl-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-7 col-12"
                    />
                    <FormInput
                        v-model="endAdjDate"
                        label="End Adj Date"
                        id-prefix="endAdjDate"
                        class="mb-xl-1 mb-2"
                        is-horizontal
                        :errors="errors.END_ADJ_DATE"
                        label-class="col-xl-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-7 col-12"
                    />
                    <FormSelect
                        v-model="reconType"
                        :options="[
                            { value: 'R', label: 'REGULAR' },
                            { value: 'A', label: 'ALTERNATE' },
                        ]"
                        :option-value="(option) => option.value"
                        :option-to-string="(option) => option.label"
                        label="Recon Type"
                        id-prefix="reconType"
                        class="mb-xl-1 mb-2"
                        is-horizontal
                        :errors="errors.RECON_TYPE"
                        label-class="col-xl-5 label-sm"
                        select-class="form-select-sm"
                        select-container-class="col-xl-7 col-12"
                    />
                    <div class="text-end">
                        <hr class="my-3" />
                        <button type="submit"
                            class="btn btn-primary btn-sm"
                            :disabled="isGeneratingReconInfo">
                            <span v-if="isGeneratingReconInfo"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true">
                            </span>
                            <i class="icon ph-bold ph-gear me-2"></i>Generate Recon Info<br><small>(only do this once per store)</small>
                        </button>
                    </div>
                </fieldset>
            </form>
        </div>
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-4 mb-lg-0 mb-3">
                    <fieldset>
                        <legend>
                            <i class="icon ph-bold ph-dots-three-outline-vertical me-2"></i>Reports
                        </legend>
                        <div class="d-grid gap-1">
                            <button type="button"
                                class="btn btn-primary btn-sm"
                                :disabled="!selectedLocationReconParm?.id"
                                @click="investigate">
                                <i class="icon ph-bold ph-magnifying-glass me-2"></i>Investigate
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm"
                                :disabled="!selectedLocationReconParm?.id"
                                @click="viewDetailReconReport">
                                <i class="icon ph-bold ph-file-text me-2"></i>1. Detail Recon Report
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm"
                                disabled>
                                <i class="icon ph-bold ph-note me-2"></i>2. Summary Recon Report
                            </button>
                            <button type="button"
                                class="btn btn-primary btn-sm"
                                :disabled="!selectedTimePeriod"
                                @click="viewDetailReconReportAllStores">
                                <i class="icon ph-bold ph-storefront me-2"></i>3. Detail Recon Report (all stores)
                            </button>
                        </div>
                    </fieldset>
                </div>
                <div class="col-lg-8">
                    <DuplicateInventoryCheckBarcodesTableCard
                        @delete-duplicate-success="(message) => { addFlashMessage('SUCCESS', message); scrollToTop(); }"
                        @error="(message) => { addFlashMessage('ERROR', message); scrollToTop(); }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useFlashMessages } from "@/composables/flashmessages";
import { useReconParms } from "@/composables/data/reconParms";
import { useReconInitializations } from "@/composables/data/reconInitializations";
import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import ReconInfoGenerationsTableCard from "./ReconInfoGenerationsTableCard.vue";
import TimePeriodTableCard from "./TimePeriodTableCard.vue";
import ReconLocationsTableCard from "./ReconLocationsTableCard.vue";
import InventoryCheckCountsTableCard from "../../utils/InventoryCheckCountsTableCard.vue";
import DuplicateInventoryCheckBarcodesTableCard from "./DuplicateInventoryCheckBarcodesTableCard.vue";
import moment from "moment";
import scrollToTop from "@/utils/scrollToTop";

const { addFlashMessage } = useFlashMessages();

const { reconParms, getReconParms } = useReconParms();
const { postReconInitialization } = useReconInitializations();

const selectedTimePeriod = ref(null);
const selectedLocationReconParm = ref(null);
const selectedInventoryCheckCount = ref(null);

const timePeriodStartDate = computed(
    () => selectedTimePeriod.value?.START_DATE
);
const timePeriodEndDate = computed(() => selectedTimePeriod.value?.END_DATE);

const startAdjDate = ref(null);
const endAdjDate = ref(null);
const reconType = ref('R');

const errors = ref({});

const isGeneratingReconInfo = ref(false);

const reconInfoGenerationsTableRefreshFlag = ref(false);

watch(selectedLocationReconParm, () => {
    selectedInventoryCheckCount.value = null;

    startAdjDate.value = null;
    endAdjDate.value = null;
    reconType.value = "R";

    if (!selectedLocationReconParm.value?.id) {
        return;
    }

    startAdjDate.value = selectedLocationReconParm
        .value
        .RECON_RAW_ADJ_START_DATE;
    endAdjDate.value = selectedLocationReconParm
        .value
        .RECON_RAW_ADJ_END_DATE;
    reconType.value = selectedLocationReconParm
        .value
        .RECON_FLAG;
});

watch(selectedInventoryCheckCount, async () => {
    if (!selectedInventoryCheckCount.value) {
        startAdjDate.value = null;
        endAdjDate.value = null;
        reconType.value = 'R';
        return;
    }

    try {
        await getReconParms({
            filters: {
                TIME_PERIOD: {
                    value: selectedTimePeriod.value.TIME_PERIOD,
                },
                LOCATION: {
                    value: selectedLocationReconParm.value.LOCATION,
                },
            },
        });

        const reconParm = reconParms.value[0];

        const fromDate = moment(timePeriodStartDate.value);
        const toDate = moment(timePeriodEndDate.value);
        const inventoryDate = moment(
            selectedInventoryCheckCount.value.INVENTORY_DONE
        );

        if (reconParm) {
            startAdjDate.value = reconParm.RECON_RAW_ADJ_START_DATE;
            endAdjDate.value = reconParm.RECON_RAW_ADJ_END_DATE;
            reconType.value = reconParm.RECON_FLAG;
        } else if (
            inventoryDate.isSameOrAfter(fromDate) &&
            inventoryDate.isSameOrBefore(toDate)
        ) {
            reconType.value = "A";
            if (!reconParm) {
                startAdjDate.value = inventoryDate.format("YYYY-MM-DD");
                endAdjDate.value = toDate.format("YYYY-MM-DD");
            }
        } else {
            reconType.value = "R";
            if (!reconParm) {
                startAdjDate.value = toDate.add(1, "days").format("YYYY-MM-DD");
                endAdjDate.value = inventoryDate
                    .subtract(1, "days")
                    .format("YYYY-MM-DD");
            }
        }
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        scrollToTop();
    }
});

async function generateReconInfo() {
    isGeneratingReconInfo.value = true;
    errors.value = {};
    try {
        const response = await postReconInitialization({
            TIME_PERIOD: selectedTimePeriod.value.TIME_PERIOD,
            INVENTORY_DATE: selectedInventoryCheckCount.value?.INVENTORY_DONE ?? null,
            LOCATION: selectedLocationReconParm.value.LOCATION,
            START_ADJ_DATE: startAdjDate.value,
            END_ADJ_DATE: endAdjDate.value,
            RECON_TYPE: reconType.value,
        });
        addFlashMessage("SUCCESS", response.data.message);
        // Trigger refresh of table
        reconInfoGenerationsTableRefreshFlag.value =
            !reconInfoGenerationsTableRefreshFlag.value;
    } catch (e) {
        addFlashMessage("ERROR", e.message);
        errors.value = e.errors ?? {};
    }
    scrollToTop();
    isGeneratingReconInfo.value = false;
}

function investigate() {
    window.open(
        route("inventory.report-recon-full.investigation-form", {
            recon_parm: selectedLocationReconParm.value.id,
        })
    );
}

function viewDetailReconReport() {
    window.open(
        route("api.recon-parms.detail-report.show", {
            recon_parm: selectedLocationReconParm.value.id,
        })
    );
}

function viewDetailReconReportAllStores() {
    window.open(
        route("api.recon.detail-report-all-stores.show", {
            time_period: selectedTimePeriod.value.TIME_PERIOD,
            LOCATION: selectedLocationReconParm.value?.LOCATION,
        })
    );
}
</script>
