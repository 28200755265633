<template>
    <DataTable
        :loading="loadingFlags.has('fetchRawOrderBreakdowns')"
        lazy
        :value="rawOrderBreakdowns"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="
            (e) => {
                onPage(e);
                fetchRawOrderBreakdowns();
            }
        "
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        selection-mode="single"
        v-model:selection="selectedRawOrderBreakdown"
        data-key="BREAKDOWN_ID"
        :pt="{ table: { class: 'table table-bordered table-hover' } }"
    >
        <Column
            field="BREAKDOWN_ID"
            header="Breakdown ID"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="ORDER_ID"
            header="Order ID"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="SWATCH_NO"
            header="Swatch No."
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="COLOR"
            header="Color"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="QUANTITY"
            header="Quantity"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="STATUS"
            header="Status"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="ARRIVAL_DATE"
            header="Arrival Date"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <template #empty>
            <div v-if="orderId" class="text-center py-2">
                <i class="icon ph-bold ph-database me-2"></i>No data yet.
            </div>
            <div v-else class="text-center py-2">
                <i class="icon ph-bold ph-database me-2"></i>Please select a Raw
                Order.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";

import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useRawOrderBreakdowns } from "@/composables/data/rawOrderBreakdowns";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    orderId: Number,
    refreshFlag: null,
    class: String,
});

const emit = defineEmits(["error"]);

const toasts = useToastsStore();

const { params, filters, onPage, onFilter } = useDataTableParams([
    "BREAKDOWN_ID",
    "ORDER_ID",
    "SWATCH_NO",
    "COLOR",
    "QUANTITY",
    "STATUS",
    "ARRIVAL_DATE",
]);

const { rawOrderBreakdowns, totalRecords, getRawOrderBreakdowns } = useRawOrderBreakdowns();

const selectedRawOrderBreakdown = defineModel();

const loadingFlags = useLoadingFlagsStore();

const fetchTimeout = ref(null);

async function fetchRawOrderBreakdowns() {
    rawOrderBreakdowns.value = null;

    if (!props.orderId) {
        return;
    }

    loadingFlags.add("fetchRawOrderBreakdowns");
    try {
        await getRawOrderBreakdowns(props.orderId, params.value);
    } catch (e) {
        toasts.add("ERROR", "error", e.message);
    }
    loadingFlags.delete("fetchRawOrderBreakdowns");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchRawOrderBreakdowns, 300);
}

watch(() => [props.orderId, props.refreshFlag], fetchRawOrderBreakdowns);
</script>
