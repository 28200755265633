<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-image me-2"></i>Voucher Images
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4 mb-3">
                <div class="modal-padding-container">
                    <div v-if="noImageFound" class="text-center text-danger">
                        No Gov't. Voucher uploaded with this name. Please upload
                        first using the Update Voucher page.
                    </div>
                    <div v-else-if="isImageLoading"
                        class="spinner-border"
                        role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <img v-if="!noImageFound"
                        :src="imageURL"
                        :alt="govtVoucherId + ' voucher image'"
                        @load="(_) => { isImageLoading = false; }"
                        @error="noImageFound"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useImages } from '@/composables/data/images';
import { useToastsStore } from '@/stores/toasts';

const props = defineProps({
    govtVoucherId: String,
    isOpen: Boolean,
});

const emit = defineEmits(['close']);

const toasts = useToastsStore();

const { images, getImages } = useImages();

const imageURL = ref(null);
const isImageLoading = ref(false);

const noImageFound = ref(false);

async function loadImage() {
    imageURL.value = null;

    if (!props.govtVoucherId) {
        return;
    }

    noImageFound.value = false;
    try {
        isImageLoading.value = true;
        await getImages({
            rows: 0,
            filters: {
                imageable_type: {
                    value: 'GovernmentVoucher',
                    matchMode: 'equals',
                },
                imageable_id: {
                    value: props.govtVoucherId,
                    matchMode: 'equals',
                },
            }
        });
        imageURL.value = images.value[0]?.url;

        if (!imageURL.value) {
            noImageFound.value = true;
        }
    } catch (e) {
        isImageLoading.value = false;
        toasts.add("ERROR", "Error", e.message);
    }
}

onMounted(loadImage);

watch(() => [props.isOpen, props.govtVoucherId], () => {
    if (props.isOpen) {
        loadImage();
    }
});
</script>

<style scoped>
.container {
    max-width: 800px;
}
</style>
