<template>
    <form @submit.prevent="createLocationKey">
        <fieldset>
            <legend>
                <i class="icon ph-bold ph-map-pin-plus me-2"></i>Create Location Key
            </legend>
            <FormInput
                type="text"
                v-model="locationKey.LOCATION_KEY"
                label="Location Key"
                input-class="form-control-sm"
                id-prefix="locationKey"
                class="mb-3"
                :errors="errors.LOCATION_KEY"
                @keydown-enter="
                    (_) => {
                        focusnext('createLocationKeyButton');
                    }
                "
            />
            <div class="text-end">
                <button
                    type="submit"
                    class="btn btn-primary btn-sm"
                    id="createLocationKeyButton"
                    :disabled="!location || isCreating"
                >
                    <span
                        v-if="isCreating"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    >
                    </span>
                    <i class="icon ph-bold ph-plus me-2"></i>Create
                </button>
            </div>
        </fieldset>
    </form>
</template>

<script setup>
import { ref } from "vue";

import { useLocationKeys } from "@/composables/data/locationKeys";

import FormInput from "@/components/utils/FormInput.vue";

import focusnext from "@/utils/focusnext";

const props = defineProps({
    location: String,
});

const emit = defineEmits(["success", "error"]);

const locationKey = ref({
    LOCATION_KEY: null,
});

const errors = ref({});

const { postLocationKey } = useLocationKeys();

const isCreating = ref(false);

async function createLocationKey() {
    errors.value = {};

    isCreating.value = true;
    try {
        await postLocationKey(props.location, locationKey.value);
        emit("success", "Successfully saved location key.");
        locationKey.value = {
            LOCATION_KEY: null,
        };
    } catch (e) {
        errors.value = e.errors ?? {};
        emit("error", e.message);
    }
    isCreating.value = false;
}
</script>
