<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-bank me-2"></i>Bulk Create Loan
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <div class="row gx-4 mb-4">
                        <div class="col-md-5">
                            <h4 class="mb-3">Bulk Loan Information</h4>
                            <FormSelect
                                v-model="form.LOANTYPE"
                                :options="['ITEM', 'CASH ADVANCE', 'DONATION']"
                                label="Loan Type"
                                id-prefix="loanType"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                select-class="form-select-sm"
                                select-container-class="col-xl-8 col-md-7 col-12"
                                :errors="errors.LOANTYPE"
                            />
                            <FormInput
                                type="date"
                                v-model="form.DATE_GRANTED"
                                label="Date Granted"
                                id-prefix="dateGranted"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                                :errors="errors.DATE_GRANTED"
                            />
                            <FormInput
                                type="date"
                                v-model="form.DATE"
                                label="Effectivity Date"
                                id-prefix="date"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                                :errors="errors.DATE"
                            />
                            <FormInput
                                type="text"
                                v-model="form.DESCRIPTION"
                                label="Description"
                                id-prefix="description"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                                :errors="errors.DESCRIPTION"
                            />
                            <FormInput
                                type="number"
                                v-model="form.NUM_WEEKS"
                                label="# of Weeks"
                                id-prefix="numWeeks"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                                :errors="errors.NUM_WEEKS"
                            />
                            <div class="row gx-2 mb-md-1 mb-2">
                                <div class="col">
                                    <FormInput
                                        type="text"
                                        v-model="individualDetailForm.employee.EMPID"
                                        disabled
                                        label="Employee"
                                        id-prefix="empid"
                                        :form-text="`${
                                            individualDetailForm.employee
                                                .FNAME ?? ''
                                        } ${
                                            individualDetailForm.employee
                                                .LNAME ?? ''
                                        }`"
                                        is-horizontal
                                        label-class="col-xl-5 col-md-5 label-sm"
                                        input-class="form-control-sm"
                                        input-container-class="col-xl-7 col-md-7 col-12 ps-md-2"
                                    />
                                </div>
                                <div class="col-auto">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-primary px-2"
                                        @click="
                                            (_) => {
                                                isSelectEmployeePopupOpen = true;
                                            }
                                        "
                                    >
                                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                                    </button>
                                </div>
                            </div>
                            <FormInput
                                type="number"
                                step=".01"
                                v-model="individualDetailForm.INIT_AMT"
                                label="Initial Amount"
                                id-prefix="initAmt"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                            />
                            <FormInput
                                type="number"
                                step=".01"
                                v-model="individualDetailForm.PRINCIPAL"
                                label="Principal"
                                id-prefix="principal"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                            />
                            <FormInput
                                type="number"
                                step=".01"
                                v-model="individualDetailForm.AMT_WEEK"
                                label="Amount per Week"
                                id-prefix="amtWeek"
                                disabled
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-4 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-8 col-md-7 col-12"
                            />
                            <div class="text-end my-3">
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    :disabled="
                                        !individualDetailForm.employee.EMPID ||
                                        !individualDetailForm.INIT_AMT ||
                                        !individualDetailForm.PRINCIPAL ||
                                        !individualDetailForm.AMT_WEEK
                                    "
                                    @click="addIndividual"
                                >
                                    <i class="icon ph-bold ph-plus me-2"></i>Add Individual
                                </button>
                            </div>
                            <div class="separator my-3">
                                <div class="content">OR</div>
                            </div>
                            <div class="mb-3">
                                <label for="loansFile" class="form-label">Import File (.xlsx)</label>
                                <input
                                    type="file"
                                    class="form-control form-control-sm"
                                    name="loansFile"
                                    id="loansFile"
                                    ref="loansFileInput"
                                    @change="
                                        (e) => {
                                            loansFile = e.target.files[0];
                                        }
                                    "
                                />
                            </div>
                            <div class="text-end mb-3">
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    @click="extractLoansFromSpreadsheet"
                                    :disabled="!loansFile"
                                >
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Import .xlsx
                                </button>
                            </div>
                            <hr />
                            <div class="text-end mt-3">
                                <button
                                    type="button"
                                    class="btn btn-primary me-2 btn-sm"
                                    @click="exportSampleBulkSpreadsheet"
                                >
                                    <i class="icon ph-bold ph-file-xls me-2"></i>Get Sample BULK Spreadsheet
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    :disabled="!form.DETAILS.length"
                                    @click="createLoans"
                                >
                                    <i class="icon ph-bold ph-plus me-2"></i>Create Bulk
                                </button>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <h4 class="mb-3">Individual Details</h4>
                            <DataTable
                                :value="form.DETAILS"
                                paginator
                                :rows="10"
                                :pt="{ table: { class: 'table table-bordered table-hover' } }"
                                class="mb-4"
                            >
                                <Column field="employee.EMPID" header="Employee ID" />
                                <Column field="employee.FNAME" header="First Name" />
                                <Column field="employee.LNAME" header="Last Name" />
                                <Column field="INIT_AMT" header="Initial Amt." />
                                <Column field="PRINCIPAL" header="Principal" />
                                <Column field="AMT_WEEK" header="Amt. per Week" />
                                <Column header="Delete">
                                    <template #body="{ index }">
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-danger"
                                            @click="deleteDetail(index)"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </template>
                                </Column>
                                <template #footer>
                                    {{ form.DETAILS.length }} total employees
                                </template>
                                <template #empty>
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>
                                        Please add individuals using the form
                                        below, or import an Excel file.
                                    </div>
                                </template>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <SelectEmployee
        :is-open="isSelectEmployeePopupOpen"
        @close="
            (_) => {
                isSelectEmployeePopupOpen = false;
            }
        "
        @select="
            (selectedEmployee) => {
                individualDetailForm.employee = selectedEmployee;
                isSelectEmployeePopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useEmployeeLoans } from "@/composables/data/employeeLoans";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import SelectEmployee from "@/components/utils/SelectEmployee.vue";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { postBulkLoanExtractionFromSpreadsheet, postLoansFlock } = useEmployeeLoans();

const form = ref({
    LOANTYPE: null,
    DATE_GRANTED: null,
    DATE: null,
    DESCRIPTION: null,
    NUM_WEEKS: null,
    DETAILS: [],
});

const individualDetailForm = ref({
    employee: {
        EMPID: null,
    },
    INIT_AMT: null,
    PRINCIPAL: null,
    AMT_WEEK: null,
});

const errors = ref({});

const loansFileInput = ref(null);
const loansFile = ref(null);

const isSelectEmployeePopupOpen = ref(false);

function addIndividual() {
    if (
        form.value.DETAILS.filter(
            (detail) =>
                detail.employee.EMPID ==
                individualDetailForm.value.employee.EMPID
        ).length > 0
    ) {
        toasts.add(
            "ERROR",
            "Error",
            `Employee with ID ${individualDetailForm.value.employee.EMPID} already exists.`
        );
        return;
    }

    form.value.DETAILS.push(individualDetailForm.value);
    individualDetailForm.value = {
        employee: {
            EMPID: null,
        },
        INIT_AMT: null,
        PRINCIPAL: null,
        AMT_WEEK: null,
    };
}

function deleteDetail(index) {
    form.value.DETAILS = [
        ...form.value.DETAILS.slice(0, index),
        ...form.value.DETAILS.slice(index + 1),
    ];
}

function exportSampleBulkSpreadsheet() {
    window.open(route("api.loans.flock.spreadsheet-format.show"));
}

async function extractLoansFromSpreadsheet() {
    loadingFlags.add("extractLoansFromSpreadsheet");
    try {
        const formData = new FormData();
        formData.append("spreadsheet", loansFile.value);
        const extractedData = await postBulkLoanExtractionFromSpreadsheet(
            formData
        );
        extractedData
            .map((loan) => {
                const newLoan = { ...loan };
                newLoan.AMT_WEEK = loan.INIT_AMT / form.value.NUM_WEEKS;
                return newLoan;
            })
            .forEach((loan) => {
                if (
                    form.value.DETAILS.filter(
                        (detail) => detail.employee.EMPID == loan.employee.EMPID
                    ).length > 0
                ) {
                    toasts.add(
                        "ERROR",
                        "Error",
                        `Employee with ID ${loan.employee.EMPID} already exists.`
                    );
                    return;
                }

                form.value.DETAILS.push(loan);
            });
        toasts.add("SUCCESS", "Success", "Done importing Excel file to table.");
        loansFile.value = null;
        loansFileInput.value.value = null;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("extractLoansFromSpreadsheet");
}

async function createLoans() {
    errors.value = {};

    loadingFlags.add("createLoans");
    try {
        const loanInformation = JSON.parse(JSON.stringify(form.value));
        loanInformation.DETAILS = loanInformation.DETAILS.map((detail) => {
            const newDetail = Object.assign({}, detail);
            newDetail.EMPID = newDetail.employee.EMPID;
            return newDetail;
        });
        const createdLoans = await postLoansFlock(loanInformation);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully created ${createdLoans.length} loans.<br/>` +
                `Search them in the table using the ff. filters:<br/>` +
                `-> Type: ${loanInformation.LOANTYPE}<br/>` +
                `-> Description: ${loanInformation.DESCRIPTION}<br/>` +
                `-> Date Granted: ${loanInformation.DATE_GRANTED}<br/>` +
                `-> Date: ${loanInformation.DATE}`
        );
        form.value = {
            LOANTYPE: null,
            DATE_GRANTED: null,
            DATE: null,
            DESCRIPTION: null,
            NUM_WEEKS: null,
            DETAILS: [],
        };
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("createLoans");
}

watch(
    () => form.value.NUM_WEEKS,
    () => {
        for (const detail of form.value.DETAILS) {
            if (form.value.NUM_WEEKS) {
                detail.AMT_WEEK = detail.INIT_AMT / form.value.NUM_WEEKS;
            } else {
                detail.AMT_WEEK = 0;
            }
        }
    }
);

watch(
    () => [form.value.NUM_WEEKS, individualDetailForm.value.INIT_AMT],
    () => {
        if (form.value.NUM_WEEKS && individualDetailForm.value.INIT_AMT) {
            individualDetailForm.value.AMT_WEEK = individualDetailForm.value.INIT_AMT / form.value.NUM_WEEKS;
        } else {
            individualDetailForm.value.AMT_WEEK = 0;
        }
    }
);
</script>

<style scoped>
.separator {
    text-align: center;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: center;
}

.separator::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #b7b7b7;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.separator .content {
    background: #f8f9fa;
    width: fit-content;
    text-align: center;
    z-index: 2;
    padding: 0 1.25rem;
}
</style>
