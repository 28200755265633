<template>
    <div class="row mb-3">
        <div class="col-xl-8">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-buildings me-2"></i>Quantity per Store
                </legend>
                <div class="row pb-3 px-3">
                    <div class="col-lg-8 mb-lg-0 mb-2">
                        <FormInput
                            label="Location:"
                            is-horizontal
                            label-class="col-xl-3 col-md-5"
                            input-class="form-control-sm"
                            input-container-class="col-xl-9 col-md-7 col-12"
                            class="mb-2"
                            disabled
                            v-model="locationDisplay"
                        />
                        <div class="row">
                            <div class="col-lg-6 mb-lg-0 mb-2">
                                <button
                                    class="btn btn-primary btn-sm w-100"
                                    @click="(_) => { isSelectLocationPopupOpen = true; }"
                                >
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select Location
                                </button>
                            </div>
                            <div class="col-lg-6">
                                <button
                                    class="btn btn-primary btn-sm w-100"
                                    @click="reset"
                                >
                                    <i class="icon ph-bold ph-arrow-counter-clockwise me-2"></i>Reset Location
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-grid">
                        <button
                            class="btn btn-primary btn-sm"
                            @click="fetchQuantitiesPerStore"
                        >
                            <i class="icon ph-bold ph-storefront me-2"></i>Store Quantity
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>

    <fieldset>
        <legend>
            <i class="icon ph-bold ph-buildings me-2"></i>View
        </legend>
        <DataTable
            :loading="loadingFlags.has('fetchQuantitiesPerStore')"
            lazy
            :value="quantitiesPerStore"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="e => { onPage(e); fetchQuantitiesPerStore(); }"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column field="LOCATION" header="Location" />
            <Column field="DESCRIPTION" header="Description" />
            <Column field="SUM_COUNT" header="Quantity" />
            <Column field="TOTAL_SM_PRICE" header="Total" />
            <template #empty>
                <div class="text-center">
                    No data yet.
                </div>
            </template>
        </DataTable>
        <div class="text-end">
            <button type="button" class="btn btn-primary"
                @click="exportSpreadsheet">
                <i class="icon ph-bold ph-table me-2"></i>Export Spreadsheet
            </button>
        </div>
    </fieldset>

    <SelectLocationPopup
        :is-open="isSelectLocationPopupOpen"
        @close="(_) => { isSelectLocationPopupOpen = false; }"
        @select="
            (selectedLocation) => {
                location = selectedLocation;
                isSelectLocationPopupOpen = false;
            }
        "
    />
</template>
<script setup>
import { computed, ref } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useQuantitiesPerStore } from "@/composables/data/quantitiesPerStore";

import FormInput from "@/components/utils/FormInput.vue";
import SelectLocationPopup from "@/components/utils/popups/SelectLocationPopup.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, onPage } = useDataTableParams([
    'LOCATION',
    'DESCRIPTION',
    'SUM_COUNT',
    'TOTAL_SM_PRICE',
]);

const { quantitiesPerStore, totalRecords, getQuantitiesPerStore } = useQuantitiesPerStore();

const isSelectLocationPopupOpen = ref(false);

const location = ref(null);
const locationDisplay = computed(() => location.value?.LOCATION);

async function fetchQuantitiesPerStore() {
    quantitiesPerStore.value = null;

    loadingFlags.add("fetchQuantitiesPerStore");
    try {
        await getQuantitiesPerStore({
            ...params.value,
            filters: {
                LOCATION: location.value ? {
                    value: location.value.LOCATION,
                    matchMode: 'equals',
                } : {},
            },
        });
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchQuantitiesPerStore");
}

function reset() {
    location.value = null;
}

function exportSpreadsheet() {
    window.open(route(`api.quantities-per-store.spreadsheet.show`, {
        filters: {
            LOCATION: location.value ? {
                value: location.value.LOCATION,
                matchMode: 'equals',
            } : {},
        },
    }));
}
</script>
