<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-barcode me-2"></i>Style Metro Gaisano
        </legend>
        <form>
            <FormInput
                label="Metro Gaisano SKU"
                type="text"
                v-model="form.mg_sku"
                is-horizontal
                id-prefix="mgSku"
                class="mb-2"
                input-class="form-control-sm"
                :errors="errors.mg_sku"
            />
            <div class="text-end">
                <hr class="my-3" />
                <button type="button" class="btn btn-sm btn-primary me-1"
                    :disabled="!style"
                    @click="updateMgSku">
                    <i class="icon ph-bold ph-floppy-disk me-2"></i>Save
                </button>
            </div>
        </form>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useStyleMgSkus } from "@/composables/data/styleMgSkus";

import FormInput from "@/components/utils/FormInput.vue";

const props = defineProps({
    style: String,
    class: String,
});

const errors = ref({});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { styleMgSku, getStyleMgSku, putStyleMgSku } = useStyleMgSkus();

const form = ref({
    mg_sku: null,
});

watch(
    () => props.style,
    async () => {
        form.value = {
            mg_sku: null,
        };

        errors.value = {};

        if (!props.style) {
            return;
        }

        loadingFlags.add('fetchStyleMgSku');
        try {
            await getStyleMgSku(props.style);
            form.value = styleMgSku.value;
        } catch (e) {
            errors.value = e.errors ?? {};
            if (e.status != 404) {
                toasts.add('ERROR', 'Error', e.message);
            }
        }
        loadingFlags.delete('fetchStyleMgSku');
    }
);

async function updateMgSku() {
    loadingFlags.add('updateMgSku');
    try {
        const newMgSku = await putStyleMgSku(props.style, form.value);
        toasts.add(
            "SUCCESS",
            "Success",
            `Successfully updated Metro Gaisano SKU to ${newMgSku.mg_sku}.`
        );
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('updateMgSku');
}
</script>
