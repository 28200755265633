<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-credit-card me-2"></i> View Loan Payments for LOAN: {{ loan?.LOANID }}
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable
                        :value="loan?.payments"
                        paginator
                        :rows="10"
                        :pt="{
                            table: {
                                class: 'table table-bordered table-hover',
                            },
                        }"
                    >
                        <Column field="LOANID" header="Loan ID" />
                        <Column field="DATE" header="Payment Date" />
                        <Column field="PAYMENT" header="Amount" />
                        <Column field="STATUS" header="Status" />
                        <Column field="CREATED_BY" header="Created By" />
                        <template #empty>
                            <div class="text-center">No payments.</div>
                        </template>
                        <template #footer>
                            {{ loan?.payments?.length ?? 0 }} total payments
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Column from "primevue/column";
import DataTable from "primevue/datatable";

const props = defineProps({
    loan: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["close"]);
</script>

<style scoped>
.container {
    max-width: 600px;
}
</style>
