<style scoped>
.sticky-header-footer {
    position: sticky;
    top: 0;
    z-index: 1;
}

#comment-container {
    width: 100%;
}

#month-container {
    width: 100%;
    height: 200px;
    overflow-y: auto;
}

#smsku-container,
#comment-split-container {
    width: 100%;
}
</style>

<template>
    <div class="row g-3 mb-3">
        <div class="col-xl-3">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-chat-teardrop-dots me-2"></i>COMMENT
                </legend>
                <div id="comment-container" class="row g-1">
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button class="btn btn-primary w-100 btn-sm"
                            @click="ShowPDFReport('comment-detail-stocklot')"
                            :disabled="!selectedComment">
                            <i class="icon ph-bold ph-stack me-2"></i>Comment Detail (by STOCKLOT)
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button class="btn btn-primary w-100 btn-sm"
                            @click="ShowPDFReport('comment-detail-style')"
                            :disabled="!selectedComment">
                            <i class="icon ph-bold ph-file-text me-2"></i>Comment Detail (by STYLE)
                        </button>
                    </div>
                    <div class="col-xl-12 col-lg-6 col-12">
                        <button
                            class="btn btn-primary w-100 btn-sm"
                            @click="(_) => { isGeneratingCommentSummary = true; }"
                            :disabled="!selectedComment">
                            <i class="icon ph-bold ph-note me-2"></i>Comment Summary (by STYLE)
                        </button>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-chat-teardrop-dots me-2"></i>COMMENT - SPLIT BY BRAND
                </legend>
                <div id="comment-split-container"
                    class="d-flex flex-column align-items-center">
                    <FormSelect
                        class="w-100 mb-3"
                        :options="splitTypes"
                        :option-to-string="(splitType) => splitType.label"
                        :option-value="(splitType) => splitType.value"
                        v-model="splitType"
                        select-class="form-select-sm w-100"
                    />
                    <div class="row g-1 w-100">
                        <div class="col-xl-12 col-md-6 col-12">
                            <button class="btn btn-primary w-100 btn-sm"
                                @click="ShowPDFReport('comment-detail-split')"
                                :disabled="!selectedComment">
                                <i class="icon ph-bold ph-file-text me-2"></i>Comment Detail (by STYLE)
                            </button>
                        </div>
                        <div class="col-xl-12 col-md-6 col-12">
                            <button class="btn btn-primary w-100 btn-sm"
                                @click="ShowPDFReport('comment-summary-split')"
                                :disabled="!selectedComment">
                                <i class="icon ph-bold ph-note me-2"></i>Comment Summary (by STYLE)
                            </button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-xl-9">
            <div class="row">
                <div class="col-12">
                    <fieldset>
                        <legend>
                            <i class="icon ph-bold ph-chat-teardrop-dots me-2"></i>COMMENT REPORTS
                        </legend>
                        <DataTable
                            lazy
                            paginator
                            class="sticky-header-footer"
                            :rows="10"
                            :value="commentList"
                            :total-records="totalRecords"
                            filter-display="row"
                            v-model:filters="filters"
                            v-model:selection="selectedComment"
                            selection-mode="single"
                            data-key="COMMENT_ID"
                            @page="(e) => onPage(e)"
                            @filter="(e) => onFilter(e)"
                            :row-class="commentRowClass"
                            :pt="{ table: { class: ' table table-bordered table-hover' } }">
                            <Column
                                field="COMMENT_ID"
                                header="ID"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' },
                                }">
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @input="filterCallback()"
                                        class="form-control"
                                        placeholder="Search by ID"
                                    />
                                </template>
                            </Column>
                            <Column
                                field="COMMENT_TEXT"
                                header="Text"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' },
                                }">
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @input="filterCallback()"
                                        class="form-control"
                                        placeholder="Search by Text"
                                    />
                                </template>
                            </Column>
                            <Column
                                field="COMMENT_RPT_DATE"
                                header="Report Date"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm'},
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' },
                                }">
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @input="filterCallback()"
                                        class="form-control"
                                        placeholder="Search by Report Date"
                                    />
                                </template>
                            </Column>
                            <Column
                                field="COMMENT_TIMESTAMP"
                                header="Timestamp"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' },
                                }">
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @input="filterCallback()"
                                        class="form-control"
                                        placeholder="Search by Timestamp"
                                    />
                                </template>
                            </Column>
                            <Column
                                field="LOCATION"
                                header="Location"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' },
                                }">
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @input="filterCallback()"
                                        class="form-control"
                                        placeholder="Search by Location"
                                    />
                                </template>
                            </Column>
                            <Column
                                field="CREATED_BY"
                                header="Created By"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' },
                                }">
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @input="filterCallback()"
                                        class="form-control"
                                        placeholder="Search by Created By"
                                    />
                                </template>
                            </Column>
                            <template #empty>
                                <div class="text-center py-2">
                                    <i class="icon ph-bold ph-database me-2"></i>No data.
                                </div>
                            </template>
                        </DataTable>
                    </fieldset>
                </div>
                <div class="col-xl-8 mt-3">
                    <fieldset>
                        <legend>
                            <i class="icon ph-bold ph-file-xls me-2"></i>Spreadsheet Reports
                        </legend>
                        <div id="smsku-container" class="py-1">
                            <div class="row mb-1">
                                <label class="form-label col-lg-4 col-md-3 label-sm align-items-center">COMMENT ID:</label>
                                <div class="col-lg-8 col-md-9">
                                    <input class="form-control form-control-sm"
                                        disabled
                                        :value="selectedComment ? selectedComment.COMMENT_ID : ''"
                                    />
                                </div>
                            </div>
                            <div class="row mb-1">
                                <label class="form-label col-lg-4 col-md-3 label-sm align-items-center">EXP DEL DATE:</label>
                                <div class="col-lg-8 col-md-9">
                                    <input class="form-control form-control-sm"
                                        type="date"
                                        v-model="expDelDate"
                                    />
                                </div>
                            </div>

                            <div class="row mt-3 mb-1 g-1">
                                <div class="col-md-6">
                                    <button class="btn btn-primary btn-sm w-100 px-1"
                                        :disabled="!selectedComment"
                                        @click="ShowPDFReport('sm-sku-dr-spreadsheet')"
                                    >
                                        <i class="icon ph-bold ph-barcode me-2"></i>SM SKU DR
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-primary btn-sm w-100 px-1"
                                        :disabled="!selectedComment"
                                        @click="ShowPDFReport('landmark-sku-dr-spreadsheet')"
                                    >
                                        <i class="icon ph-bold ph-barcode me-2"></i>Landmark SKU DR
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-primary btn-sm w-100 px-1"
                                        :disabled="!selectedComment"
                                        @click="ShowPDFReport('rds-sku-dr-spreadsheet')"
                                    >
                                        <i class="icon ph-bold ph-file-xls me-2"></i>Summary Excel
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-primary btn-sm w-100 px-1"
                                        :disabled="!selectedComment"
                                        @click="ShowPDFReport('gmall-dr-spreadsheet')"
                                    >
                                        <i class="icon ph-bold ph-file-xls me-2"></i>GMall DR
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-xl-4 mt-3">
                    <fieldset>
                        <legend>
                            <i class="icon ph-bold ph-calendar me-2"></i>Month
                        </legend>
                        <div id="month-container">
                            <table class="table table-bordered table-hover">
                                <tbody>
                                    <tr v-for="month in months"
                                        :key="month"
                                        :class="{ 'bg-primary text-white': month === selectedMonth, }"
                                        @click="selectMonth(month)">
                                        <td class="cursor-pointer">
                                            {{ month }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <FormSelect
                            class="w-100 my-3"
                            label="COMMENT TYPE:"
                            v-model="commentType"
                            :options="commentTypes"
                            id-prefix="CommentType"
                            select-class="form-select-sm w-100"
                        />
                    </fieldset>
                </div>
            </div>
        </div>
    </div>

    <CommentSummaryMarginPopup
        :is-open="isGeneratingCommentSummary"
        :comment-id="selectedComment?.COMMENT_ID"
        @close="(_) => { isGeneratingCommentSummary = false; }"
    />
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import { useFlashMessages } from "@/composables/flashmessages";
import scrollToTop from "@/utils/scrollToTop";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";
import CommentSummaryMarginPopup from "./CommentSummaryMarginPopup.vue";

import axios from "axios";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;

const splitTypes = [
    {
        label: "KIMBEL+KIMCHI",
        value: [],
    },
    {
        label: "KIMBEL ONLY",
        value: ['KIMBEL', 'OTHER'],
    },
    {
        label: "KIMCHI ONLY",
        value: ['KIMCHI'],
    },
];

const years = ref([]);
const months = ref([]);
const selectedComment = ref(null);
const selectedMonth = ref("");
const commentType = ref("Delivery");
const commentList = ref([]);
const splitType = ref(splitTypes[1].value);
const drNumber = ref(null);
const expDelDate = ref(null);
const totalRecords = ref();
const params = ref({});
const filters = ref({
    COMMENT_ID: { value: null, matchMode: FilterMatchMode.CONTAINS },
    COMMENT_TEXT: { value: null, matchMode: FilterMatchMode.CONTAINS },
    COMMENT_RPT_DATE: { value: null, matchMode: FilterMatchMode.CONTAINS },
    COMMENT_TIMESTAMP: { value: null, matchMode: FilterMatchMode.CONTAINS },
    LOCATION: { value: null, matchMode: FilterMatchMode.CONTAINS },
    CREATED_BY: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const { addFlashMessage } = useFlashMessages();

const isGeneratingCommentSummary = ref(false);

const commentTypes = [
    "Initial",
    "Delivery",
    "Pullout",
    "Sales",
    "Reconciliation",
    "New Stock",
    "Return",
    "Replacement",
];

const smLocation = "SM";

watch(params, getCommentList, { deep: true });

watch(selectedMonth, () => {
    getCommentList();
});

watch(commentType, () => {
    getCommentList();
});

onMounted(() => {
    generateYearsAndMonths();

    const formattedCurrentMonth =
        currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
    selectedMonth.value = `${currentYear}/${formattedCurrentMonth}`;
});

onMounted(async () => {
    params.value = {
        first: 0,
        page: 0,
        rows: 10,
        sortField: null,
        sortOrder: null,
        filters: filters.value,
    };
});

const commentRowClass = (data) => {
    return [
        {
            "bg-primary text-white":
                data.COMMENT_ID == selectedComment.value?.COMMENT_ID,
        },
    ];
};

function generateYearsAndMonths() {
    const startYear = 2000;

    for (let year = currentYear; year >= startYear; year--) {
        years.value.push(year);
    }
    for (let year = currentYear; year >= startYear; year--) {
        const endMonth = year === currentYear ? currentMonth : 12;
        for (let month = 12; month >= 1; month--) {
            if (year === currentYear && month > endMonth) {
                continue;
            }
            const formattedMonth = month < 10 ? `0${month}` : `${month}`;
            const yearMonth = `${year}/${formattedMonth}`;
            months.value.push(yearMonth);
        }
    }
}

async function getCommentList() {
    try {
        const response = await axios.get(
            route("api.comments.index", {
                ...params.value,
                MONTH: selectedMonth.value,
                COMMENT_TYPE: commentType.value,
                COMMENT_STATUS: "O",
            })
        );
        commentList.value = response.data.data;
        totalRecords.value = response.data.meta.total;
    } catch (e) {
        console.log(e);
        scrollToTop();
        addFlashMessage(
            "ERROR",
            `An error occurred while fetching the comment list.
            Please contact your administrator.`
        );
    }
}

const onPage = (event) => {
    params.value = event;
};

const onFilter = (_) => {
    params.value.filters = filters.value;
    params.value.page = 0;
    params.value.first = 0;
};

function selectMonth(month) {
    selectedMonth.value = month;
    selectedComment.value = null;
}

function ShowPDFReport(reportType) {
    if (
        selectedComment.value?.LOCATION.substr(0, 2) !== smLocation &&
        reportType == "sm-sku"
    ) {
        scrollToTop();
        addFlashMessage("ERROR", "The selected comment is not located in SM.");
        return;
    }

    window.open(
        route(`api.reports.inventory.comment-report-generator`, {
            REPORT_TYPE: reportType,
            COMMENT_ID: selectedComment.value?.COMMENT_ID,
            SPLIT_TYPE: splitType.value,
        }),
        "_blank"
    );
}
</script>
