<template>
    <DataTable
        lazy
        :total-records="totalRecords"
        :value="users"
        paginator
        :rows="10"
        @page="(e) => { onPage(e); fetchUsers(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="(e) => { onFilter(e); fetchUsers(); }"
        sort-mode="multiple"
        v-model:multi-sort-meta="multiSortMeta"
        @sort="(e) => { onSort(e); fetchUsers(); }"
        selection-mode="single"
        v-model:selection="selectedUser"
        data-key="id"
        :pt="{ table: { class: 'table table-bordered table-hover' } }"
    >
        <Column
            field="name"
            header="Name"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keyup.enter="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="email"
            header="Email"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keyup.enter="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
    </DataTable>
</template>

<script setup>
import { ref, onMounted } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useUsers } from "@/composables/data/users";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    class: String,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    'name',
    'email',
]);

const { users, totalRecords, getUsers } = useUsers();

const selectedUser = defineModel();

async function fetchUsers() {
    users.value = null;

    loadingFlags.add("fetchUsers");
    try {
        await getUsers(params.value);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchUsers");
}

onMounted(fetchUsers);
</script>
