import { ref } from "vue";

import axios from "axios";

import constructFetchError from "./constructFetchError";

export function useQuantitiesPerStore() {
    const quantitiesPerStore = ref(null);
    const totalRecords = ref(null);

    const getQuantitiesPerStore = async (params) => {
        try {
            const getQuantitiesPerStoreResponse = await axios.get(route('api.quantities-per-store.index', params));
            quantitiesPerStore.value = getQuantitiesPerStoreResponse.data.data;
            totalRecords.value = getQuantitiesPerStoreResponse.data.meta?.total ?? quantitiesPerStore.value.length;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching quantities per store.');
        }
    };
    
    return {
        quantitiesPerStore,
        totalRecords,
        getQuantitiesPerStore,
    };
}
