<template>
    <div class="row mb-3">
        <div class="col-lg-5">
            <fieldset class="h-100">
                <legend>
                    Select User
                </legend>
                <UsersTable
                    v-model="user"
                    class="mb-3 h-100"
                />
                <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    :disabled="!user"
                    @click="saveChanges"
                >
                    
                <i class="icon ph-bold ph-floppy-disk me-2"></i>Save Roles and Permissions
                </button>
            </fieldset>
        </div>
        <div class="col-lg-7">
            <fieldset class="h-100">
                <legend>
                    <i class="icon ph-bold ph-lock-laminated me-2"></i>Current User Roles & Permission
                </legend>
                <div class="row gx-5">
                    <div class="col-sm-6">
                        <h6>Selected User Roles</h6>
                        <DataTable
                            :value="userRoles"
                            paginator
                            :rows="10"
                            filter-display="row"
                            v-model:filters="userRolesFilters"
                            v-model:selection="selectedUserRole"
                            selection-mode="single"
                            data-key="id"
                            :pt="{ table: { class: 'table table-bordered table-hover' } }"
                        >
                            <Column
                                field="name"
                                header="Role Name"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: {  class: 'd-none' },
                                }"
                            >
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @keyup.enter="filterCallback()"
                                        class="form-control"
                                        placeholder="Search"
                                    />
                                </template>
                            </Column>
                            <template #empty>
                                <template v-if="!user">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>Please select a user.
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>No roles.
                                    </div>
                                </template>
                            </template>
                        </DataTable>
                        <div class="text-end">
                            <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                :disabled="!user || !selectedUserRole"
                                @click="removeUserRole"
                            >
                                <i class="icon ph-bold ph-trash me-2"></i>Remove Selected Role
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <h6>Selected User Permissions</h6>
                        <DataTable
                            :value="userPermissions"
                            paginator
                            :rows="10"
                            filter-display="row"
                            v-model:filters="userPermissionsFilters"
                            v-model:selection="selectedUserPermission"
                            selection-mode="single"
                            data-key="id"
                            :pt="{ table: { class: 'table table-bordered table-hover' } }"
                        >
                            <Column
                                field="name"
                                header="Permission Name"
                                :pt="{
                                    filterInput: { class: 'input-group input-group-sm' },
                                    filterMenuButton: { class: 'd-none' },
                                    headerFilterClearButton: { class: 'd-none' },
                                }"
                            >
                                <template #filter="{ filterModel, filterCallback }">
                                    <InputText
                                        type="text"
                                        v-model="filterModel.value"
                                        @keyup.enter="filterCallback()"
                                        class="form-control"
                                        placeholder="Search"
                                    />
                                </template>
                            </Column>
                            <template #empty>
                                <template v-if="!user">
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>Please select a user.
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center py-2">
                                        <i class="icon ph-bold ph-user me-2"></i>No permissions.
                                    </div>
                                </template>
                            </template>
                        </DataTable>
                        <div class="text-end">
                            <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                :disabled="!user || !selectedUserPermission"
                                @click="removeUserPermission"
                            >
                                <i class="icon ph-bold ph-trash me-2"></i>Remove Selected Permission
                            </button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-user-circle-gear me-2"></i>Available Roles
                </legend>
                <DataTable
                    :value="roles"
                    paginator
                    :rows="10"
                    filter-display="row"
                    v-model:filters="rolesFilters"
                    v-model:selection="selectedRole"
                    selection-mode="single"
                    data-key="id"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                >
                    <Column
                        field="name"
                        header="Role Name"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @keyup.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <template v-if="!user">
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-user me-2"></i>Please select a user.
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-user-circle-gear me-2"></i>No roles.
                            </div>
                        </template>
                    </template>
                </DataTable>
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="!user || !selectedRole"
                        @click="addRole"
                    >
                        <i class="icon ph-bold ph-plus me-2"></i>Add Selected Role
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-6">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-lock-key me-2"></i> Available Permissions
                </legend>
                <DataTable
                    :value="permissions"
                    paginator
                    :rows="10"
                    filter-display="row"
                    v-model:filters="permissionsFilters"
                    v-model:selection="selectedPermission"
                    selection-mode="single"
                    data-key="id"
                    :pt="{ table: { class: 'table table-bordered table-hover' } }"
                >
                    <Column
                        field="name"
                        header="Permission Name"
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                        }"
                    >
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @keyup.enter="filterCallback()"
                                class="form-control"
                                placeholder="Search"
                            />
                        </template>
                    </Column>
                    <template #empty>
                        <template v-if="user == null">
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-user me-2"></i>Please select a user.
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-lock-key me-2"></i>No permissions.
                            </div>
                        </template>
                    </template>
                </DataTable>
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="!user || !selectedPermission"
                        @click="addPermission"
                    >
                        <i class="icon ph-bold ph-plus me-2"></i>Add Selected Permission
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useRolesAndPermissions } from "@/composables/data/rolesAndPermissions";
import { useUserRolesAndPermissions } from "@/composables/data/userRolesAndPermissions";

import UsersTable from "./UsersTable.vue";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const user = ref(null);

const { roles, getRoles, permissions, getPermissions } = useRolesAndPermissions();
const {
    userRoles,
    getUserRoles,
    userPermissions,
    getUserPermissions,
    putUserRolesAndPermissions,
} = useUserRolesAndPermissions();

const { filters: rolesFilters } = useDataTableParams(['name']);
const { filters: permissionsFilters } = useDataTableParams(['name']);
const { filters: userRolesFilters } = useDataTableParams(['name']);
const { filters: userPermissionsFilters } = useDataTableParams(['name']);

const selectedRole = ref();
const selectedUserRole = ref();
const selectedPermission = ref();
const selectedUserPermission = ref();

async function fetchRoles() {
    loadingFlags.add("fetchRoles");
    try {
        await getRoles();
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchRoles");
}

async function fetchPermissions() {
    loadingFlags.add("fetchPermissions");
    try {
        await getPermissions();
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchPermissions");
}

async function fetchUserRoles() {
    loadingFlags.add("fetchUserRoles");
    try {
        await getUserRoles(user.value.id);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchUserRoles");
}

async function fetchUserPermissions() {
    loadingFlags.add("fetchUserPermissions");
    try {
        await getUserPermissions(user.value.id);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchUserPermissions");
}

watch(user, async () => {
    roles.value = null;
    permissions.value = null;
    userRoles.value = null;
    userPermissions.value = null;

    if (!user.value) {
        return;
    }

    await Promise.all([
        fetchRoles(),
        fetchPermissions(),
        fetchUserRoles(),
        fetchUserPermissions(),
    ]);

    for (const userRole of userRoles.value) {
        roles.value = roles.value.filter(
            (role) => role.id != userRole.id
        );
    }
    for (const userPermission of userPermissions.value) {
        permissions.value = permissions.value.filter(
            (permission) => permission.id != userPermission.id
        );
    }
});

function moveItemFromArrayToAnother(firstArrayRef, secondArrayRef, selectedItem) {
    firstArrayRef.value = firstArrayRef.value.filter(
        (firstArrayElement) => firstArrayElement.id != selectedItem.value.id
    );

    secondArrayRef.value = [
        ...secondArrayRef.value,
        selectedItem.value,
    ];

    selectedItem.value = null;
}

function addRole() {
    moveItemFromArrayToAnother(roles, userRoles, selectedRole);
}

function removeUserRole() {
    moveItemFromArrayToAnother(userRoles, roles, selectedUserRole);
}

function addPermission() {
    moveItemFromArrayToAnother(permissions, userPermissions, selectedPermission);
}

function removeUserPermission() {
    moveItemFromArrayToAnother(userPermissions, permissions, selectedUserPermission);
}

async function saveChanges() {
    loadingFlags.add("saveChanges");
    try {
        await putUserRolesAndPermissions(
            user.value.id,
            userRoles.value.map((userRole) => userRole.id),
            userPermissions.value.map((userPermission) => userPermission.id),
        );
        toasts.add("SUCCESS", "Success", "Successfully saved roles and permissions.");
    } catch (e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("saveChanges");
}
</script>
