import { createApp, ref } from "vue";
import { createPinia } from 'pinia';

import App from "./App.vue";

import PrimeVue from 'primevue/config';

import Sidebar from "./components/utils/global/sidebar/";

// utils
import Card from "./components/utils/Card.vue";
import ListBox from "./components/utils/ListBox.vue";
import Preloader from "./components/utils/Preloader.vue";
import Modal from "./components/utils/Modal.vue";
import GlobalFlashMessageAlerts from "./components/utils/GlobalFlashMessageAlerts.vue";

import Dashboard from "./components/dashboard";

// activity page components
import NewComment from "./components/activity/pages/new-comment/";
import UpdateComment from "./components/activity/pages/update-comment/";
import GenerateNewBarcodes from "./components/activity/pages/generate-new-barcodes/";
import WriteNewBarcodes from "./components/activity/pages/write-new-barcodes/";
import InventoryUnified from "./components/activity/pages/inventory-unified/";
import MaterialCatalog from "./components/activity/pages/material-catalog/";
import SamplingMaintenance from "./components/activity/pages/sampling-maintenance/";
import SampleDetail from "./components/activity/pages/sampling-maintenance/sample-detail/";
import SampleApprovalList from "./components/activity/pages/sampling-maintenance/approval-list/";
import CreateSMPulloutLetter from "./components/activity/pages/create-sm-pullout-letter/";
import UpdateSPStatus from "./components/activity/pages/update-sp-status/";
import UpdateOrderDetails from "./components/activity/pages/update-sp-status/update-order-details/";

// inventory page components
import StyleMaintenance from "./components/inventory/pages/style-maintenance/";
import StylePrice from "./components/inventory/pages/style-price/";
import ReportReconFull from "./components/inventory/pages/report-recon-full/";
import PhysicalInventoryChecking from "./components/inventory/pages/physical-inventory-checking/";
import ReconInvestigationForm from "./components/inventory/pages/report-recon-full/investigation-form";
import ReconDisplayExtraData from "./components/inventory/pages/report-recon-full/investigation-form/display-extra-data";
import PhysicalInventoryReports from "./components/inventory/pages/physical-inventory-reports/";
import UpdateInventoryCheck from "./components/inventory/pages/update-inventory-check/";
import ReconMainWarehouse from "./components/inventory/pages/recon-main-warehouse/";

// voucher page components
import UpdateVoucher from "./components/vouchers/pages/UpdateVoucher.vue";
import CreateVoucher from "./components/vouchers/pages/CreateVoucher.vue";
import glBalances from "./components/vouchers/pages/GLBalances.vue";
import CheckRegister from "./components/vouchers/pages/CheckRegister.vue";
import PayrollVouchers from "./components/vouchers/pages/PayrollVouchers.vue";
import UpdateVoucherAdmin from "./components/vouchers/pages/UpdateVoucherAdmin.vue";
import MessageBox from "./components/vouchers/popups/MessageBox.vue";

// payslip page components
import EnterHours from "./components/payslip/pages/enter-hours/";
import CreatePayslip from "./components/payslip/pages/create-payslip/";
import DeletePayslip from "./components/payslip/pages/delete-payslip/";
import ImportHoursSales from "./components/payslip/pages/ImportHoursSales.vue";
import ImportHoursNideka from "./components/payslip/pages/ImportHoursNideka.vue";
import EmployeeSmMapping from "./components/payslip/pages/EmployeeSmMapping.vue";
import SalesLeaves from "./components/payslip/pages/sales-leaves/";
import CreatePayroll from "./components/payslip/pages/create-payroll/";
import DeletePayroll from "./components/payslip/pages/DeletePayroll.vue";
import CreatePayrollReport from "./components/payslip/pages/create-payroll-report/";

// admin page components
import UploadRemittanceAdvice from "./components/admin/pages/upload-remittance-advice/";
import UploadSalesReport from "./components/admin/pages/upload-sales-report/";

// catalog page components
import EmployeeMaintenance from "./components/catalog/pages/employee-maintenance/";
import UserPermissions from "./components/catalog/pages/user-permissions/";
import EmployeeDependents from "./components/catalog/pages/EmployeeDependents.vue";
import ChangeRate from "./components/catalog/pages/ChangeRate";
import EmployeeLocation from "././components/catalog/pages/EmployeeLocation.vue";
import EmployeeViolations from "./components/catalog/pages/EmployeeViolations.vue";
import JoborderMaintenance from "./components/catalog/pages/joborder-maintenance/index.vue";
import TimePeriodMaintenance from "./components/catalog/pages/TimePeriodMaintenance.vue";
import LoanMaintenance from "./components/catalog/pages/loan-maintenance/";
import CloseLoans from "./components/catalog/pages/CloseLoans.vue";
import AccountMaintenance from "./components/catalog/pages/account-maintenance/";
import AccountTypeMaintenance from "./components/catalog/pages/account-type-maintenance/";
import LandedCosts from "./components/catalog/pages/landed-costs/";
import LocationCosts from "./components/catalog/pages/location-costs/";
import LocationMaintenance from "./components/catalog/pages/location-maintenance/";
import LocationKeys from "./components/catalog/pages/location-keys/";
import UpdateStoreSummary from "./components/catalog/pages/UpdateStoreSummary.vue";
import ColorMaintenance from "./components/catalog/pages/color-maintenance/";
import CompanyEventMaintenance from "./components/catalog/pages/company-event-maintenance/";
import TabulationYearEquivalenceMaintenance from "./components/catalog/pages/tabulation-year-equivalence-maintenance/";

// report page components
import AccountingMonthlyReports from "./components/reports/pages/accounting/MonthlyReports.vue";
import AccountingTimePeriodReports from "./components/reports/pages/accounting/time-period-reports/";
import AccountingLocationReports from "./components/reports/pages/accounting/location-reports/";
import AccountingMonthlyStoreSummary from "./components/reports/pages/accounting/store-summary-reports/";
import AccountingIntervalReports from "./components/reports/pages/accounting/IntervalReports.vue";
import AccountingDaysOfWorkAndYearsOfServiceReports from "./components/reports/pages/accounting/days-of-work-and-years-of-service-reports/";
import AccountingCommissionReport from "./components/reports/pages/accounting/commission-report/";
import AccountingYearlyReports from "./components/reports/pages/accounting/yearly-reports/";
import AccountingEmployeeReports from "./components/reports/pages/accounting/employee-reports/";
import AccountingLoanReports from "./components/reports/pages/accounting/loan-reports/";
import InventoryStyleReports from "./components/reports/pages/inventory/StyleReports.vue";
import InventoryCommentReports from "./components/reports/pages/inventory/comment-reports/index.vue";
import InventoryBarcodeReports from "./components/reports/pages/inventory/barcode-reports/";
import InventoryMonthlyReports from "./components/reports/pages/inventory/MonthlyReports.vue";
import UpdateDailySales from "./components/reports/pages/inventory/monthly-reports/UpdateDailySales.vue";
import InventoryQuantityPerStore from "./components/reports/pages/inventory/custom-reports/QuantityPerStore";
import InventoryCustomReports from "./components/reports/pages/inventory/custom-reports/index.vue";
import InventoryDeliveryAndPulloutReports from "./components/reports/pages/inventory/custom-reports/DeliveryAndPulloutReports.vue";
import InventoryTopsellerReports from "./components/reports/pages/inventory/TopsellerReports.vue";
import InventorySalesReports from "./components/reports/pages/inventory/custom-reports/SalesReports.vue";
import InventoryMonthlyDiscrepancyReport from "./components/reports/pages/inventory/custom-reports/MonthlyDiscrepancyReport.vue";
import InventoryDeliveryReceiptReport from "./components/reports/pages/inventory/custom-reports/DeliveryReceiptReport.vue";
import InventoryLocationDetailReport from "./components/reports/pages/inventory/custom-reports/LocationDetailReport.vue";
import InventoryReconLostReports from "./components/reports/pages/inventory/custom-reports/ReconLostReports.vue";
import InventoryStoreComparisonReports from "./components/reports/pages/inventory/custom-reports/StoreComparisonReports.vue";
import PayrollTimePeriodReports from "./components/reports/pages/payroll/time-period-reports/";
import PayrollMonthlyReports from "./components/reports/pages/payroll/MonthlyReports.vue";
import PayrollAttendanceReports from "./components/reports/pages/payroll/attendance-reports/";
import PayrollEmployeeHoursReports from "./components/reports/pages/payroll/employee-hours-reports/";
import PayrollRetroactiveHoursReports from "./components/reports/pages/payroll/retroactive-hours-reports/";
import PayrollJoborderReports from "./components/reports/pages/payroll/joborder-reports/";

// purchase order page components
import POSupplierMaintenance from "./components/po/pages/supplier-maintenance/";
import POPurchaseOrders from "./components/po/pages/purchase-orders/";

import LoadingOverlay from "./components/utils/global/LoadingOverlay.vue";
import Toasts from "./components/utils/global/Toasts.vue";

require("./bootstrap");

const pinia = createPinia();

window.app = createApp(App)
    .use(PrimeVue, {
        unstyled: true
    })
    .use(pinia)

    .component("dashboard", Dashboard)
    .component("sidebar", Sidebar)

    // register activity page components here
    .component("new-comment", NewComment)
    .component("update-comment", UpdateComment)
    .component("generate-new-barcodes", GenerateNewBarcodes)
    .component("write-new-barcodes", WriteNewBarcodes)
    .component("inventory-unified", InventoryUnified)
    .component("material-catalog", MaterialCatalog)
    .component("sampling-maintenance", SamplingMaintenance)
    .component("sample-detail", SampleDetail)
    .component("sample-approval-list", SampleApprovalList)
    .component("create-sm-pullout-letter", CreateSMPulloutLetter)
    .component("update-sp-status", UpdateSPStatus)
    .component("update-order-details", UpdateOrderDetails)

    // register inventory page components here
    .component("style-maintenance", StyleMaintenance)
    .component("style-price", StylePrice)
    .component("report-recon-full", ReportReconFull)
    .component("physical-inventory-checking", PhysicalInventoryChecking)
    .component("recon-investigation-form", ReconInvestigationForm)
    .component("recon-display-extra-data", ReconDisplayExtraData)
    .component("physical-inventory-reports", PhysicalInventoryReports)
    .component("update-inventory-check", UpdateInventoryCheck)
    .component("recon-main-warehouse", ReconMainWarehouse)

    // register voucher page components here
    .component("update-voucher", UpdateVoucher)
    .component("update-voucher-admin", UpdateVoucherAdmin)
    .component("create-voucher", CreateVoucher)
    .component("gl-balances", glBalances)
    .component("check-register", CheckRegister)
    .component("payroll-vouchers", PayrollVouchers)
    .component("message-box", MessageBox)

    // register payslip page components here
    .component("enter-hours", EnterHours)
    .component("create-payslip", CreatePayslip)
    .component("delete-payslip", DeletePayslip)
    .component("import-hours-sales", ImportHoursSales)
    .component("import-hours-nideka", ImportHoursNideka)
    .component("employee-sm-mapping", EmployeeSmMapping)
    .component("sales-leaves", SalesLeaves)
    .component("create-payroll", CreatePayroll)
    .component("delete-payroll", DeletePayroll)
    .component("create-payroll-report", CreatePayrollReport)

    // register admin page components here
    .component("upload-remittance-advice", UploadRemittanceAdvice)
    .component("upload-sales-report", UploadSalesReport)

    // register catalog page components here
    .component("employee-maintenance", EmployeeMaintenance)
    .component("user-permissions", UserPermissions)
    .component("change-rate", ChangeRate)
    .component("employee-location", EmployeeLocation)
    .component("employee-dependents", EmployeeDependents)
    .component("employee-violations", EmployeeViolations)
    .component("joborder-maintenance", JoborderMaintenance)
    .component("time-period-maintenance", TimePeriodMaintenance)
    .component("loan-maintenance", LoanMaintenance)
    .component("close-loans", CloseLoans)
    .component("account-maintenance", AccountMaintenance)
    .component("account-type-maintenance", AccountTypeMaintenance)
    .component("landed-costs", LandedCosts)
    .component("location-costs", LocationCosts)
    .component("location-keys", LocationKeys)
    .component("location-maintenance", LocationMaintenance)
    .component("update-store-summary", UpdateStoreSummary)
    .component("color-maintenance", ColorMaintenance)
    .component("company-event-maintenance", CompanyEventMaintenance)
    .component("tabulation-year-equivalence-maintenance", TabulationYearEquivalenceMaintenance)

    // register report page components here
    .component("accounting-monthly-reports", AccountingMonthlyReports)
    .component("accounting-time-period-reports", AccountingTimePeriodReports)
    .component("accounting-location-reports", AccountingLocationReports)
    .component("accounting-monthly-store-summary", AccountingMonthlyStoreSummary)
    .component("accounting-interval-reports", AccountingIntervalReports)
    .component("accounting-days-of-work-and-years-of-service-reports", AccountingDaysOfWorkAndYearsOfServiceReports)
    .component("accounting-commission-report", AccountingCommissionReport)
    .component("accounting-yearly-reports", AccountingYearlyReports)
    .component("accounting-employee-reports", AccountingEmployeeReports)
    .component("accounting-loan-reports", AccountingLoanReports)
    .component("inventory-style-reports", InventoryStyleReports)
    .component("inventory-comment-reports", InventoryCommentReports)
    .component("inventory-barcode-reports", InventoryBarcodeReports)
    .component("inventory-monthly-reports", InventoryMonthlyReports)
    .component("update-daily-sales", UpdateDailySales)
    .component("inventory-quantity-per-store", InventoryQuantityPerStore)
    .component("inventory-custom-reports", InventoryCustomReports)
    .component("inventory-delivery-pullout-reports", InventoryDeliveryAndPulloutReports)
    .component("inventory-topseller-reports", InventoryTopsellerReports)
    .component("inventory-sales-reports", InventorySalesReports)
    .component("inventory-monthly-discrepancy-report", InventoryMonthlyDiscrepancyReport)
    .component("inventory-delivery-receipt-report", InventoryDeliveryReceiptReport)
    .component("inventory-location-detail-report", InventoryLocationDetailReport)
    .component("inventory-recon-lost-reports", InventoryReconLostReports)
    .component("inventory-store-comparison-reports", InventoryStoreComparisonReports)
    .component("payroll-time-period-reports", PayrollTimePeriodReports)
    .component("payroll-monthly-reports", PayrollMonthlyReports)
    .component("payroll-attendance-reports", PayrollAttendanceReports)
    .component("payroll-employee-hours-reports", PayrollEmployeeHoursReports)
    .component("payroll-retroactive-hours-reports", PayrollRetroactiveHoursReports)
    .component("payroll-joborder-reports", PayrollJoborderReports)

    // register purchase order page components here
    .component("po-supplier-maintenance", POSupplierMaintenance)
    .component("po-purchase-orders", POPurchaseOrders)

    // utils
    .component("loading-overlay", LoadingOverlay)
    .component("toasts", Toasts)
    .component("card", Card)
    .component("listbox", ListBox)
    .component("preloader", Preloader)
    .component("modal", Modal)
    .component("global-flash-message-alerts", GlobalFlashMessageAlerts)

    .mount("#app");
