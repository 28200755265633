import { ref } from "vue";

import axios from 'axios';

import constructFetchError from "./constructFetchError";

export function useVouchers() {
    const vouchers = ref(null);
    const totalRecords = ref(null);
    const voucher = ref(null);

    const getVouchers = async (params) => {
        try {
            const getVouchersResponse = await axios.get(route('api.vouchers.index', params));
            vouchers.value = getVouchersResponse.data.data;
            totalRecords.value = getVouchersResponse.data.meta?.total;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching vouchers.');
        }
    };

    const getFinalVouchers = async (params) => {
        try {
            const getVouchersResponse = await axios.get(route('api.finvoucherall.index', params));
            vouchers.value = getVouchersResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching vouchers.');
        }
    };

    const getVoucher = async (voucherId, params) => {
        try {
            const getVoucherResponse = await axios.get(route('api.vouchers.show', {
                voucher: voucherId,
                ...params,
            }));
            voucher.value = getVoucherResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching voucher.');
        }
    };

    const putVoucher = async (voucherId, voucher) => {
        try {
            const putVoucherResponse = await axios.put(route('api.vouchers.update', {
                id: voucherId,
            }), voucher);
            return putVoucherResponse;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching voucher.');
        }
    };

    return {
        vouchers,
        totalRecords,
        voucher,
        getVouchers,
        getFinalVouchers,
        getVoucher,
        putVoucher,
    };
}
