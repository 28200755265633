<template>
    <fieldset class="h-100">
        <legend><i class="icon ph-bold ph-credit-card me-2"></i> Loans</legend>
        <LoansTable
            v-model="selectedLoan"
            @filter="(filters) => { tableFilters = filters; }"
        />
        <div class="text-end">
            <Button
                type="button"
                class="btn btn-primary me-2 btn-sm"
                @click="(_) => { isBulkCreateLoanFormPopupOpen = true; }"
            >
                <i class="fas fa-plus me-2"></i>Bulk Create
            </Button>
            <Button
                type="button"
                class="btn btn-primary me-2 btn-sm"
                :disabled="!selectedLoan"
                @click="(_) => { isViewLoanPaymentsTablePopupOpen = true; }"
            >
                <i class="fas fa-eye me-2"></i>View Payments
            </Button>
            <Button
                type="button"
                class="btn btn-primary btn-sm"
                @click="exportSpreadsheet"
            >
                <i class="icon ph-bold ph-file-xls me-2"></i>Export Spreadsheet
            </Button>
        </div>
    </fieldset>

    <BulkCreateLoanFormPopup
        :is-open="isBulkCreateLoanFormPopupOpen"
        @close="
            (_) => {
                isBulkCreateLoanFormPopupOpen = false;
            }
        "
    />

    <ViewLoanPaymentsTablePopup
        :loan="selectedLoan"
        :is-open="isViewLoanPaymentsTablePopupOpen"
        @close="
            (_) => {
                isViewLoanPaymentsTablePopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { ref } from "vue";

import Button from "primevue/button";

import LoansTable from "@/components/utils/tables/LoansTable.vue";

import ViewLoanPaymentsTablePopup from "./ViewLoanPaymentsTablePopup.vue";
import BulkCreateLoanFormPopup from "./BulkCreateLoanFormPopup.vue";

const selectedLoan = ref(null);

const isBulkCreateLoanFormPopupOpen = ref(false);
const isViewLoanPaymentsTablePopupOpen = ref(false);

const tableFilters = ref(null);

function exportSpreadsheet() {
    window.open(
        route("api.loans.spreadsheet.show", {
            filters: tableFilters.value,
        })
    );
}
</script>
