<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-image me-2"></i>Upload Images
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <div class="row g-3">
                        <div class="col-lg-6">
                            <ImagesTable
                                :refresh-flag="refreshFlag"
                                :filters="{
                                    imageable_type: {
                                        value: props.imageableType,
                                        matchMode: 'equals',
                                    },
                                }"
                                :multi-sort-meta="[
                                    {
                                        field: 'updated_at',
                                        order: -1
                                    }
                                ]"
                            />
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label for="imageFileInput" class="form-label">Images</label>
                                <input type="file"
                                    multiple
                                    required
                                    ref="imageFilesInput"
                                    accept="image/png, image/gif, image/jpeg"
                                    @change="onImageFileInputChange"
                                    form-text="Make sure filename is same as STYLE"
                                    class="form-control"
                                />
                            </div>
                            <DataTable
                                :value="imageFiles"
                                paginator
                                :rows="10"
                                sort-field="status"
                                :sort-order="1"
                                edit-mode="cell"
                                @cell-edit-complete="onImageFileTableCellEditComplete"
                                :pt="{ table: { class: 'table table-bordered table-hover' } }"
                            >
                                <Column
                                    field="name"
                                    header="File Name"
                                >
                                    <template #editor="{ data, field }">
                                        <FormInput
                                            type="text"
                                            v-model="data[field]"
                                            input-class="form-control-sm"
                                            hide-label
                                            is-horizontal
                                        />
                                    </template>
                                </Column>
                                <Column
                                    field="status"
                                    header="Status"
                                    sortable
                                >
                                    <template #body="{ data }">
                                        <span v-if="data.status == 'READY'" class="badge bg-success">
                                            Ready for Upload
                                        </span>
                                        <span v-else-if="data.status == 'INVALID'" class="badge bg-danger">
                                            Invalid ID
                                        </span>
                                    </template>
                                </Column>
                                <template #footer>
                                    There are still {{ imageFiles?.filter((imageFile) => imageFile.status == 'INVALID').length ?? 0 }} invalid names.
                                </template>
                            </DataTable>
                            <div class="text-end">
                                <button type="button"
                                    class="btn btn-primary btn-sm"
                                    @click="uploadImages">
                                    <i class="icon ph-bold ph-upload-simple me-2"></i>Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useImages } from "@/composables/data/images";
import ImagesTable from "@/components/utils/tables/ImagesTable.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import FormInput from "@/components/utils/FormInput.vue";

const MAX_NUMBER_OF_FILES = 30;

const props = defineProps({
    imageableType: String,
    extractIdCallback: Function,
    validateInputsCallback: Function,
    validateInputCallback: Function,
    isOpen: Boolean,
});

const emit = defineEmits(["close"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { postImagesFlock } = useImages();

const imageFilesInput = ref(null);
const imageFiles = ref(null);

const refreshFlag = ref(false);

async function onImageFileInputChange(event) {
    const inputFiles = [...event.target.files];

    if (inputFiles.length > MAX_NUMBER_OF_FILES) {
        toasts.add("ERROR", "Error", `Files to upload (${inputFiles.length}) exceeds maximum ${MAX_NUMBER_OF_FILES}.`);
        imageFiles.value = null;
        imageFilesInput.value.value = null;
    }

    const ids = inputFiles.map((inputFile) => props.extractIdCallback(inputFile.name));

    const isValid = await props.validateInputsCallback(ids);

    imageFiles.value = inputFiles.map((inputFile, index) => ({
        name: inputFile.name,
        actualFile: inputFile,
        status: isValid[index] ? 'READY' : 'INVALID',
    }));
}

async function onImageFileTableCellEditComplete(event) {
    const { data, newValue, field, index } = event;

    const hasSameName = imageFiles.value
        .slice(0, index)
        .concat(imageFiles.value.slice(index + 1))
        .filter((imageFile) => imageFile.name == newValue)
        .length > 0;

    if (hasSameName) {
        toasts.add("ERROR", "Error", "Cannot use this file name, already used.");
        return;
    }

    data[field] = newValue;

    data.actualFile = new File(
        [data.actualFile.slice(0, data.actualFile.size, data.actualFile.type)],
        newValue,
        { type: data.actualFile.type }
    );
    data.status = 'VALIDATING';

    const isValid = await props.validateInputCallback(props.extractIdCallback(newValue));
    data.status = isValid ? 'READY' : 'INVALID';
}

async function uploadImages() {
    if (!imageFiles.value?.length) {
        toasts.add("ERROR", "Error", "Please specify some files to upload.");
        return;
    }

    loadingFlags.add("uploadImages");
    try {
        const formData = new FormData();
        imageFiles.value.filter((imageFile) => imageFile.status == 'READY').forEach((imageFile, index) => {
            formData.append(`images[${index}][imageable_type]`, props.imageableType);
            formData.append(`images[${index}][imageable_id]`, props.extractIdCallback(imageFile.name));
            formData.append(`images[${index}][image]`, imageFile.actualFile);
        });
        await postImagesFlock(formData);
        toasts.add("SUCCESS", "Success", "Successfully placed ready files for uploading.");
        refreshFlag.value = !refreshFlag.value;
        imageFiles.value = imageFiles.value.filter((imageFile) => imageFile.status == 'INVALID');
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("uploadImages");
}
</script>
