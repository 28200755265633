<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-list me-2"></i>Select Purchase Order
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <PurchaseOrdersTable v-model="selectedPurchaseOrder" />
                </div>
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="!selectedPurchaseOrder"
                        @click="
                            (_) => {
                                emit('select', selectedPurchaseOrder);
                            }
                        "
                    >
                        <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select Purchase Order
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

import PurchaseOrdersTable from "../tables/PurchaseOrdersTable.vue";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["select", "close"]);

const selectedPurchaseOrder = ref(null);
</script>

<style scoped>
.container {
    max-width: 700px;
}
</style>
