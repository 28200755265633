<template>
    <div class="row mb-3">
        <div class="col-lg-4 mb-3">
            <fieldset>
                <legend>
                    <i class="fa-solid fa-table me-2"></i>Filters
                </legend>
                <form @submit.prevent="applyFilters">
                    <FormInput
                        type="number"
                        required
                        label="From Year:"
                        v-model="fromYear"
                        min="1900"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm align-items-center"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        id-prefix="fromYear"
                    />
                    <FormInput
                        type="number"
                        required
                        label="To Year:"
                        v-model="toYear"
                        min="1900"
                        class="mb-md-1 mb-2"
                        input-class="form-control-sm"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm align-items-center"
                        input-container-class="col-xl-8 col-md-7 col-12"
                        id-prefix="toYear"
                    />
                    <LocationTable
                        v-model="location"
                        class="mb-3"
                    />
                    <div class="d-flex justify-content-end">
                        <button
                            type="submit"
                            class="btn btn-sm btn-primary"
                        >
                            <i class="fa-solid fa-table me-2"></i>Apply Filters
                        </button>
                    </div>
                </form>
            </fieldset>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-lg-6">
            <fieldset>
                <legend>
                    <i class="fa-solid fa-table me-2"></i>Total Quantity
                    <span v-if="appliedFilters">(for {{ appliedFilters.location.LOCATION }})</span>
                </legend>
                <DataTable
                    v-if="appliedFilters"
                    :value="perMonthEntries"
                    :pt="{ table: { class: 'table table-sm table-bordered' } }"
                >
                    <Column
                        field="month"
                        header="Month"
                    />
                    <Column
                        v-for="year in Array(appliedFilters.toYear - appliedFilters.fromYear + 1).fill().map((_, idx) => appliedFilters.fromYear + idx)"
                        :field="`${year}.total_quantity`"
                        :header="year"
                    />
                </DataTable>
                <p v-else class="text-center">Please choose filters.</p>
                <div class="d-flex justify-content-end">
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        :disabled="!appliedFilters"
                        @click="exportTotalQuantitySpreadsheet"
                    >
                        <i class="fa-solid fa-table me-2"></i>Export Spreadsheet
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-6">
            <fieldset>
                <legend>
                    <i class="fa-solid fa-table me-2"></i>Total Amount
                    <span v-if="appliedFilters">(for {{ appliedFilters.location.LOCATION }})</span>
                </legend>
                <DataTable
                    v-if="appliedFilters"
                    :value="perMonthEntries"
                    :pt="{ table: { class: 'table table-sm table-bordered' } }"
                >
                    <Column
                        field="month"
                        header="Month"
                    />
                    <Column
                        v-for="year in Array(appliedFilters.toYear - appliedFilters.fromYear + 1).fill().map((_, idx) => appliedFilters.fromYear + idx)"
                        :field="`${year}.total_amount`"
                        :header="year"
                    >
                        <template #body="{ data }">
                            {{ data[year].total_amount?.toFixed(2) }}
                        </template>
                    </Column>
                </DataTable>
                <p v-else class="text-center">Please choose filters.</p>
                <div class="d-flex justify-content-end">
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        :disabled="!appliedFilters"
                        @click="exportTotalAmountSpreadsheet"
                    >
                        <i class="fa-solid fa-table me-2"></i>Export Spreadsheet
                    </button>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import FormInput from '@/components/utils/FormInput.vue';
import LocationTable from '@/components/utils/tables/LocationTable.vue';
import { useSalesActualDetails } from '@/composables/data/salesActualDetails';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { ref } from 'vue';

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const fromYear = ref(null);
const toYear = ref(null);
const location = ref(null);

const { perMonthEntries, getPerMonthEntries } = useSalesActualDetails();

const appliedFilters = ref(null);

async function fetchPerMonthEntries() {
    loadingFlags.add("fetchPerMonthEntries");
    try {
        await getPerMonthEntries(
            appliedFilters.value?.fromYear,
            appliedFilters.value?.toYear,
            appliedFilters.value?.location?.LOCATION
        );
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchPerMonthEntries");
}

function applyFilters() {
    if (!location.value) {
        toasts.add("ERROR", "Error", "Please select a location.");
        return;
    }

    appliedFilters.value = {
        fromYear: fromYear.value,
        toYear: toYear.value,
        location: location.value,
    };

    fetchPerMonthEntries();
}

function exportTotalQuantitySpreadsheet() {
    window.open(route('api.sales-actual-details.per-month-entries.total-quantity-spreadsheet.show', {
        from_year: appliedFilters.value.fromYear,
        to_year: appliedFilters.value.toYear,
        location: appliedFilters.value.location.LOCATION,
    }));
}

function exportTotalAmountSpreadsheet() {
    window.open(route('api.sales-actual-details.per-month-entries.total-amount-spreadsheet.show', {
        from_year: appliedFilters.value.fromYear,
        to_year: appliedFilters.value.toYear,
        location: appliedFilters.value.location.LOCATION,
    }));
}
</script>
