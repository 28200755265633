<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-upload-simple me-2"></i>Uploaded Files
        </legend>
        <DataTable
            :loading="isLoading"
            lazy
            :value="uploadedRemittanceAdviceFileCollections"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="
                (e) => {
                    onPage(e);
                    fetchUploadedRemittanceAdviceFileCollections();
                }
            "
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="
                (e) => {
                    onSort(e);
                    fetchUploadedRemittanceAdviceFileCollections();
                }
            "
            selection-mode="single"
            v-model:selection="selectedUploadedRemittanceAdviceFileCollection"
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
        >
            <Column
                field="time_period"
                header="Time Period"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="si_file_name"
                header="SI File"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="dm_file_name"
                header="DM File"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="main_file_name"
                header="Main File"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="status"
                header="Status"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
                <template #body="{ data }">
                    <span
                        v-if="data.status == 'SUCCESS'"
                        class="badge bg-success"
                    >
                        Success
                    </span>
                    <span
                        v-else-if="data.status == 'WARNING'"
                        class="badge bg-warning"
                    >
                        Warning
                    </span>
                    <span
                        v-else-if="data.status == 'ERROR'"
                        class="badge bg-danger"
                    >
                        Error
                    </span>
                </template>
            </Column>
            <template #empty>
                <div class="text-center">No data.</div>
            </template>
        </DataTable>
        <div class="text-end">
            <button
                type="button"
                class="btn btn-primary me-1 btn-sm"
                :disabled="!selectedUploadedRemittanceAdviceFileCollection"
                @click="(_) => { isViewDetailsPopupOpen = true; }"
            >
                <i class="icon ph-bold ph-eye me-2"></i>View Details
            </button>
            <button
                type="button"
                class="btn btn-primary btn-sm"
                :disabled="!selectedUploadedRemittanceAdviceFileCollection"
                @click="downloadFiles"
            >
                <i class="icon ph-bold ph-download me-2"></i>Download Files
            </button>
        </div>
    </fieldset>
    <ViewDetailsPopup
        :is-open="isViewDetailsPopupOpen"
        @close="
            (_) => {
                isViewDetailsPopupOpen = false;
            }
        "
        :details="
            selectedUploadedRemittanceAdviceFileCollection?.details != null
                ? JSON.parse(selectedUploadedRemittanceAdviceFileCollection.details)
                : null
        "
    />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useUploadedRemittanceAdviceFileCollections } from "@/composables/data/uploadedRemittanceAdviceFileCollections";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

import ViewDetailsPopup from "./ViewDetailsPopup.vue";

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const props = defineProps({
    refreshFlag: null,
});

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    'time_period',
    'si_file_name',
    'dm_file_name',
    'main_file_name',
    'status'
]);

const { uploadedRemittanceAdviceFileCollections, totalRecords, getUploadedRemittanceAdviceFileCollections } = useUploadedRemittanceAdviceFileCollections();

const selectedUploadedRemittanceAdviceFileCollection = ref(null);

const isLoading = ref(false);

const fetchTimeout = ref(null);

const isViewDetailsPopupOpen = ref(false);

async function fetchUploadedRemittanceAdviceFileCollections() {
    uploadedRemittanceAdviceFileCollections.value = null;

    loadingFlags.add("fetchUploadedRemittanceAdviceFileCollections");
    try {
        await getUploadedRemittanceAdviceFileCollections(params.value);
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchUploadedRemittanceAdviceFileCollections");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(
        fetchUploadedRemittanceAdviceFileCollections,
        300
    );
}

onMounted(fetchUploadedRemittanceAdviceFileCollections);
watch(() => props.refreshFlag, fetchUploadedRemittanceAdviceFileCollections);

function downloadFiles() {
    window.open(
        route(
            "api.uploaded-remittance-advice-file-collections.files-zip.show",
            {
                file_collection: selectedUploadedRemittanceAdviceFileCollection.value.id,
            }
        )
    );
}
</script>
