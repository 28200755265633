<template>
    <div
        class="employees-available-table-container"
        :class="class"
        @keydown.enter="_ => { focusNextInput('otherSelectUnifiedDoneButton'); }"
        tabindex="-1"
    >
        <DataTable
            :value="employees"
            selection-mode="single"
            v-model:selection="selectedEmployee"
            data-key="EMPID"
            :pt="{ table: { class: 'table table-sm table-bordered table-hover' }}"
        >
            <Column
                field="EMPID"
                header="ID"
            />
            <Column
                field="FNAME"
                header="First Name"
            />
            <Column
                field="LNAME"
                header="Last Name"
            />
            <template #empty>
                <div class="text-center">
                    No employees available.
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useToastsStore } from '@/stores/toasts';
import { useEmployeeLocations } from "@/composables/data/employeeLocations";

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

const props = defineProps({
    filters: Object,
    class: String,
});

const toasts = useToastsStore();

const { employeeLocations, getEmployeeLocations } = useEmployeeLocations();

const employees = ref(null);

const selectedEmployee = defineModel();

async function fetchAvailableEmployees() {
    employees.value = null;

    try {
        await getEmployeeLocations({
            filters: props.filters,
            rows: 0,
        });

        employees.value = employeeLocations.value.map(
            employeeLocation => employeeLocation.employee
        ).filter((employee, index, array) => array.findIndex(innerEmployee => innerEmployee.EMPID == employee.EMPID) == index);
    } catch(e) {
        toasts.add("ERROR", "Error", e.message);
    }
}

watch(() => props.filters, fetchAvailableEmployees);

onMounted(fetchAvailableEmployees);
</script>

<style scoped>
.employees-available-table-container {
    height: 150px;
    overflow-y: auto;
}
</style>
