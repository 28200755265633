<template>
    <div
        class="pop-up justify-content-center align-items-center"
        :class="{ 'd-none': !isOpen, 'd-flex': isOpen }"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-chat-circle-text me-2"></i>Select Lost Comment
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <CommentsTable
                        v-model="selectedComment"
                        :filters="{
                            COMMENT_STATUS: {
                                value: 'O',
                                matchMode: 'equals',
                            },
                            LOCATION: {
                                value: 'LOST',
                                matchMode: 'equals',
                            },
                        }"
                    />
                </div>
                <div class="text-end mt-4">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        :disabled="!selectedComment"
                        @click="emit('select', selectedComment)"
                    >
                        <i class="icon ph-bold ph-hand-pointing me-2"></i>Select
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

import CommentsTable from "@/components/utils/tables/CommentsTable.vue";

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(["select"]);

const selectedComment = ref(null);
</script>

<style scoped>
.container {
    max-width: 1200px;
}
</style>
