<template>
    <div class="row">
        <div class="col-lg-4 mb-3">
            <CreateOrUpdateEventForm
                v-model="selectedCompanyEvent"
                @success="
                    (_) => {
                        tableRefreshFlag = !tableRefreshFlag;
                    }
                "
            />
        </div>
        <div class="col-lg-8">
            <fieldset class="mb-3">
                <legend>
                    <i class="icon ph-bold ph-calendar-star me-2"></i>Events
                </legend>
                <CompanyEventsTable
                    v-model="selectedCompanyEvent"
                    :refresh-flag="tableRefreshFlag"
                />
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

import CompanyEventsTable from "@/components/utils/tables/CompanyEventsTable.vue";
import CreateOrUpdateEventForm from "./CreateOrUpdateEventForm.vue";

const selectedCompanyEvent = ref(null);

const tableRefreshFlag = ref(false);
</script>
