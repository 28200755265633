import { ref } from "vue";

const messages = ref([]);

export function useFlashMessages() {
    function addFlashMessage(type, content, dismissible = true, onYes = null, onNo = null) {
        const newMessageId = (new Date()).getTime();
        messages.value.push({
            id: newMessageId,
            type,
            content,
            dismissible,
            onYes,
            onNo
        });
        setTimeout(() => {
            messages.value = messages.value.filter((message) => message.id != newMessageId);
        }, 3000);
    }

    function removeFlashMessage(index) {
        messages.value.splice(index, 1);
    }

    function clearFlashMessages() {
        messages.value = [];
    }

    return { messages, addFlashMessage, removeFlashMessage, clearFlashMessages };
};
