<template>
    <fieldset :class="class">
        <legend>
            <i class="icon ph-bold ph-barcode me-2"></i>Scanned Barcodes
        </legend>
        <div class="row mb-3">
            <div class="col-xl-5">
                <form @submit.prevent="addBarcode">
                    <div class="row g-1">
                        <div class="col scanned-barcode">
                            <FormInput
                                type="text"
                                v-model="barcodeToUpload"
                                label="Enter barcode"
                                id-prefix="barcodeToUpload"
                                is-horizontal
                                class="mb-1"
                                :errors="barcodeToUploadErrors"
                                @keydown-enter="addBarcode"
                                label-class="col-auto label-sm"
                                input-class="form-control-sm"
                            />
                        </div>
                        <div class="col-auto mt-auto">
                            <button type="submit" class="btn btn-primary btn-sm mb-1">
                                <i class="icon ph-bold ph-file-plus me-2"></i>Add
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-xl-7">
                <div class="row g-1">
                    <div class="col scanned-barcode">
                        <div class="row mb-1">
                            <label for="barcodesFileInput"
                                class="col-auto col-form-label label-sm">
                                Import File (.txt, .csv)
                            </label>
                            <div class="col">
                                <input
                                    type="file"
                                    required
                                    ref="barcodesFileInput"
                                    accept=".txt,.xlsx"
                                    @change="barcodesFileOnChange"
                                    id="barcodesFileInput"
                                    class="form-control form-control-sm"
                                    @keydown.enter.prevent
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-auto mt-auto">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm mb-1"
                            :disabled=" !barcodesFile || !inventoryCheckDescription "
                            @click="processBarcodesFile">
                            <i class="icon ph-bold ph-download-simple me-2"></i>Import
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <DataTable
            :value="barcodesToUpload"
            paginator
            :rows="10"
            filter-display="row"
            v-model:filters="filters"
            sort-mode="multiple"
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
            class="mb-3">
            <Column
                field="index"
                header="#"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by #"
                    />
                </template>
            </Column>
            <Column
                field="RAW"
                header="Raw"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                    bodyCell: { class: 'barcode' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Raw"
                    />
                </template>
            </Column>
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Style"
                    />
                </template>
            </Column>
            <template #footer>
                There are {{ barcodesToUpload?.length ?? 0 }} records.
            </template>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No barcodes yet.
                </div>
            </template>
        </DataTable>
        <DataTable
            :value="noBarcodesToUpload"
            paginator
            :rows="10"
            filter-display="row"
            v-model:filters="noBarcodesFilters"
            sort-mode="multiple"
            :pt="{ table: { class: 'table table-bordered table-hover' } }">
            <Column
                field="index"
                header="#"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by #"
                    />
                </template>
            </Column>
            <Column
                field="STYLE"
                header="Style"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Style"
                    />
                </template>
            </Column>
            <Column
                field="COLOR"
                header="Color"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }">
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback()"
                        class="form-control"
                        placeholder="Search by Color"
                    />
                </template>
            </Column>
            <template #footer>
                There are {{ noBarcodesToUpload?.length ?? 0 }} records.
            </template>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data yet.
                </div>
            </template>
        </DataTable>
        <hr class="my-3" />
        <div class="text-end">
            <button type="button"
                class="btn btn-outline-danger btn-sm me-2"
                :disabled="barcodesToUpload.length < 1 && noBarcodesToUpload.length < 1"
                @click="clear">
                <i class="icon ph-bold ph-x me-2"></i>Clear
            </button>
            <button type="button"
                class="btn btn-primary btn-sm"
                :disabled=" !inventoryCheckDescription || (barcodesToUpload.length < 1 && noBarcodesToUpload.length < 1) "
                @click="addToInventoryCheck">
                <i class="icon ph-bold ph-file-plus me-2"></i>Add to Inventory Check
            </button>
        </div>
    </fieldset>
    <div class="row g-3">
        <div class="col-lg-7">
            <fieldset>
                <legend>
                    <i class="icon ph-bold ph-barcode me-2"></i>Malformed Barcodes
                </legend>
                <DataTable
                    :value="malformedBarcodes"
                    paginator
                    :rows="10"
                    filter-display="row"
                    v-model:filters="malformedBarcodesFilters"
                    sort-mode="multiple"
                    selection-mode="single"
                    v-model:selection="selectedMalformedBarcode"
                    @rowSelect="(e) => { selectedMalformedBarcodeIndex = e.index; }"
                    @rowUnselect="(e) => { selectedMalformedBarcodeIndex = null; }"
                    edit-mode="cell"
                    @cell-edit-complete="onMalformedBarcodesTableCellEditComplete"
                    :pt="{ table: { class: 'table table-bordered table-hover' }}">
                    <Column
                        field="barcode"
                        header="Barcode"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            bodyCell: { class: 'barcode' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Barcodes"
                            />
                        </template>
                        <template #editor="{ data }">
                            <InputText v-model="data.barcode"
                                class="form-control form-control-sm"
                            />
                        </template>
                    </Column>
                    <Column
                        field="status"
                        header="Status"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            bodyCell: { class: 'barcode' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Status"
                            />
                        </template>
                    </Column>
                    <Column
                        field="status_conversion_nobc"
                        header="Status (after clicking NOBC)"
                        sortable
                        :pt="{
                            filterInput: { class: 'input-group input-group-sm' },
                            filterMenuButton: { class: 'd-none' },
                            headerFilterClearButton: { class: 'd-none' },
                            bodyCell: { class: 'barcode' },
                        }">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                @input="filterCallback()"
                                class="form-control"
                                placeholder="Search by Status"
                            />
                        </template>
                    </Column>
                    <template #footer>
                        There are {{ malformedBarcodes?.length ?? 0 }} records.
                    </template>
                    <template #empty>
                        <div class="text-center py-2">
                            <i class="icon ph-bold ph-database me-2"></i>No data.
                        </div>
                    </template>
                </DataTable>
                <div class="text-end">
                    <hr class="my-3" />
                    <button type="button"
                        class="btn btn-outline-danger btn-sm me-2 mb-1"
                        :disabled="!selectedMalformedBarcode"
                        @click="deleteSelectedMalformedBarcode">
                        <i class="icon ph-bold ph-trash me-2"></i>Delete Selected from Table
                    </button>
                    <button type="button"
                        class="btn btn-primary btn-sm me-2 mb-1"
                        :disabled="malformedBarcodes.length < 1"
                        @click="addStyleColorNOBC">
                        <i class="icon ph-bold ph-file-plus me-2"></i>Add (STYLE COLOR) as NOBC
                    </button>
                </div>
            </fieldset>
        </div>
        <div class="col-lg-5">
            <AddNoBarcodeForm @add="addNoBarcode" />
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useInventoryChecks } from "@/composables/data/inventoryChecks";
import FormInput from "@/components/utils/FormInput";
import AddNoBarcodeForm from "./AddNoBarcodeForm.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import axios from "axios";
import { deconstructBarcode } from "@/utils/barcodes";

const props = defineProps({
    inventoryCheckDescription: Object,
    class: String,
});

const emit = defineEmits(["success"]);

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const barcodeToUpload = ref(null);
const barcodeToUploadErrors = ref(null);

const barcodesToUpload = ref([]);
const noBarcodesToUpload = ref([]);
const malformedBarcodes = ref([]);

const selectedMalformedBarcode = ref(null);
const selectedMalformedBarcodeIndex = ref(null);

const { filters } = useDataTableParams(["index", "STYLE", "RAW"]);
const { filters: noBarcodesFilters } = useDataTableParams([
    "index",
    "COLOR",
    "STYLE",
]);
const { filters: malformedBarcodesFilters } = useDataTableParams([
    "barcode",
    "status",
    "status_conversion_nobc",
]);

const {
    postMultipleInventoryChecks,
    postMalformedToStyleColorValidation,
} = useInventoryChecks();

// For text file importing
const barcodesFileInput = ref(null);
const barcodesFile = ref();

function barcodesFileOnChange(event) {
    const file = event.target.files[0];
    barcodesFile.value = file;
}

function addBarcode() {
    barcodeToUploadErrors.value = null;
    try {
        barcodesToUpload.value.push({
            index: barcodesToUpload.value.length + 1,
            STYLE: deconstructBarcode(barcodeToUpload.value).style,
            RAW: barcodeToUpload.value.toUpperCase(),
        });
        barcodeToUpload.value = null;
    } catch (e) {
        malformedBarcodes.value.push({
            barcode: barcodeToUpload.value,
            status: "Malformed",
        });
        barcodeToUpload.value = null;
    }
}

function addNoBarcode(noBarcode) {
    noBarcodesToUpload.value.push({
        index: noBarcodesToUpload.value.length + 1,
        STYLE: noBarcode.STYLE.toUpperCase(),
        COLOR: noBarcode.COLOR.toUpperCase(),
    });
}

function processBarcodesFile() {
    if (
        barcodesFile.value.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
        importFromExcelFile();
    }
    
    else {
        importFromTextFile();
    }
}

function importFromTextFile() {
    const reader = new FileReader();
    reader.onload = (e) => {
        const malformedBarcodesCount = malformedBarcodes.value.length;
        for (let barcode of e.target.result.split(/[\r\n]+/g)) {
            barcodeToUpload.value = barcode;
            addBarcode();
        }
        window.alert("Barcodes have been added.");
        if (malformedBarcodes.value.length > malformedBarcodesCount)
            window.alert(
                "There are malformed barcodes from the text file. Please check."
            );
        barcodesFileInput.value.value = null;
        barcodesFile.value = null;
    };
    reader.readAsText(barcodesFile.value);
}

async function importFromExcelFile() {
    // Hit API to extract contents
    loadingFlags.add("importFromExcelFile");
    try {
        const formData = new FormData();
        formData.append("file", barcodesFile.value);

        const response = await axios.post(
            route("api.spreadsheet-barcode-extractions.store"),
            formData
        );
        for (const barcode of response.data.data) {
            if (!barcode) {
                continue;
            }

            barcodeToUpload.value = barcode.toUpperCase();
            addBarcode();
        }

        barcodesFileInput.value.value = null;
        barcodesFile.value = null;
    } catch (e) {
        console.log(e);
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("importFromExcelFile");
}

function deleteSelectedMalformedBarcode() {
    malformedBarcodes.value.splice(selectedMalformedBarcodeIndex.value, 1);
    selectedMalformedBarcode.value = null;
}

function clear() {
    barcodesToUpload.value = [];
    noBarcodesToUpload.value = [];
    malformedBarcodes.value = [];
    barcodeToUpload.value = null;
}

function onMalformedBarcodesTableCellEditComplete(event) {
    const { data, newValue, field } = event;
    data[field] = newValue;
}

async function addStyleColorNOBC() {
    // Attempt to parse a "STYLE<space>COLOR". If valid, add to NOBC
    loadingFlags.add("addStyleColorNOBC");
    try {
        const data = await postMalformedToStyleColorValidation(
            malformedBarcodes.value.map(malformedBarcode => malformedBarcode.barcode)
        );

        const transformed = data.transformed;
        const stillMalformed = data.invalid;

        noBarcodesToUpload.value.push(...transformed.map((transformedBarcode, index) => ({
            index: noBarcodesToUpload.value.length + index,
            STYLE: transformedBarcode.style,
            COLOR: transformedBarcode.color,
        })));

        malformedBarcodes.value = stillMalformed.map(malformedBarcode => ({
            barcode: malformedBarcode.barcode,
            status: 'Malformed',
            status_conversion_nobc: malformedBarcode.status,
        }));

        if (transformed.length > 0) {
            toasts.add("SUCCESS", "Success", `Successfully transformed ${transformed.length} malformed as NOBC.`);
        }

        if (stillMalformed.length > 0) {
            toasts.add("WARNING", "Warning", `There are still ${stillMalformed.length} malformed barcodes.`);
        }
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("addStyleColorNOBC");
}

async function addToInventoryCheck() {
    loadingFlags.add("addToInventoryCheck");
    try {
        const response = await postMultipleInventoryChecks(
            props.inventoryCheckDescription.LOCATION,
            props.inventoryCheckDescription.INVENTORY_DONE,
            barcodesToUpload.value.map((barcodeEntry) => barcodeEntry.RAW),
            noBarcodesToUpload.value
        );

        barcodesToUpload.value = [];
        noBarcodesToUpload.value = [];

        const validBarcodes = response.data.valid_barcodes.map((barcode) => ({
            RAW: barcode
        }));
        const noBarcodes = response.data.no_barcodes;
        const duplicateBarcodes = response.data.duplicate_barcodes.map((barcode) => ({
            RAW: barcode,
        }));
        const legacyBarcodes = response.data.legacy_barcodes;

        toasts.add("SUCCESS", "Success", "Successfully saved barcodes.");
        if (duplicateBarcodes?.length > 0) {
            toasts.add("WARNING", "Warning", "There are DUPLICATEs, please check.");
        }
        if (legacyBarcodes?.length > 0) {
            toasts.add("WARNING", "Warning", "There are LEGACY BARCODEs, please check.");
        }

        emit("success", validBarcodes, noBarcodes, duplicateBarcodes, legacyBarcodes);

        barcodeToUploadErrors.value = null;
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("addToInventoryCheck");
}
</script>
