export function useCommentMovementTypes() {
    const commentMovementTypes = [
        {
            value: 'INITIAL',
            label: 'Initial Inventory',
        },
        {
            value: 'DELIVERY',
            label: 'Delivery',
        },
        {
            value: 'PULLOUT',
            label: 'Pull-out',
        },
        {
            value: 'SALES',
            label: 'Sales',
        },
        {
            value: 'RECON',
            label: 'Reconciliation',
        },
        {
            value: 'NEW_STOCK',
            label: 'New Stock',
        },
        {
            value: 'RETURN',
            label: 'Return',
        },
        {
            value: 'REPLACEMENT',
            label: 'Replacement',
        },
    ];
    
    return { commentMovementTypes };
}
