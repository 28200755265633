import { ref } from "vue";

import constructFetchError from "./constructFetchError";

import axios from "axios";

export function useJoborderReports() {
    const joborderReports = ref(null);

    const getJoborderReports = async (params) => {
        try {
            const getJoborderReportsResponse = await axios.get(route('api.joborders.reports.index', params));
            joborderReports.value = getJoborderReportsResponse.data.data;
        } catch(e) {
            console.log(e);
            throw constructFetchError(e, 'Error while fetching joborder reports.');
        }
    };

    return {
        joborderReports,
        getJoborderReports,
    };
}
