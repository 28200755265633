<template>
    <DataTable
        :loading="loadingFlags.has('fetchImages')"
        lazy
        :value="images"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="
            (e) => {
                onPage(e);
                fetchImages();
            }
        "
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        sort-mode="multiple"
        v-model:multi-sort-meta="multiSortMeta"
        @sort="
            (e) => {
                onSort(e);
                fetchImages();
            }
        "
        :pt="{ table: { class: 'table table-bordered table-hover' } }"
    >
        <Column
            field="imageable_id"
            header="ID"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="
                        (_) => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }
                    "
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="status"
            header="Status"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="
                        (_) => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }
                    "
                    class="form-control"
                    placeholder="Search"
                />
            </template>
            <template #body="{ data }">
                <span v-if="data.status == 'DONE'" class="badge bg-success">
                    Done
                </span>
                <span v-else-if="data.status == 'NO_URL'" class="badge bg-warning">
                    No URL Yet
                </span>
                <span v-else-if="data.status == 'UPLOADING'" class="badge bg-primary">
                    Uploading to GDrive
                </span>
                <span v-else-if="data.status == 'PENDING'" class="badge bg-secondary">
                    Pending
                </span>
            </template>
        </Column>
        <Column
            field="updated_at"
            header="Updated At"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="
                        (_) => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }
                    "
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center py-2">
                <i class="icon ph-bold ph-database me-2"></i>No data yet.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useImages } from '@/composables/data/images';
import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    filters: Object,
    multiSortMeta: Array,
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    "imageable_id",
    "status",
    "updated_at",
]);

const { images, totalRecords, getImages } = useImages();

const fetchTimeout = ref(null);

async function fetchImages() {
    images.value = null;

    loadingFlags.add('fetchImages');
    try {
        await getImages({
            ...params.value,
            hardFilters: props.filters,
        });
    } catch(e) {
        toasts.add('ERROR', 'Error', e.message);
    }
    loadingFlags.delete('fetchImages');
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchImages, 300);
}

onMounted(() => {
    multiSortMeta.value = props.multiSortMeta;
    onSort();
    fetchImages();
});

watch(() => props.refreshFlag, fetchImages);
</script>
