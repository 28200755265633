<template>
    <fieldset>
        <legend>
            <i class="icon ph-bold ph-tray-arrow-up me-2"></i> Uploaded {{ reportType }} Files
        </legend>
        <DataTable
            :loading="isLoading"
            lazy
            :value="uploadedSalesReportFileCollections"
            :total-records="totalRecords"
            paginator
            :rows="10"
            @page="
                (e) => {
                    onPage(e);
                    fetchUploadedSalesReportFileCollections();
                }
            "
            filter-display="row"
            v-model:filters="filters"
            @filter="tableOnFilter"
            sort-mode="multiple"
            v-model:multi-sort-meta="multiSortMeta"
            @sort="
                (e) => {
                    onSort(e);
                    fetchUploadedSalesReportFileCollections();
                }
            "
            v-model:selection="selectedUploadedSalesReportFileCollection"
            selection-mode="single"
            :pt="{ table: { class: 'table table-bordered table-hover' } }"
        >
            <Column
                field="time_period"
                header="Time Period"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="file_name"
                header="File"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <Column
                field="created_at"
                header="Date Uploaded"
                sortable
                :pt="{
                    filterInput: { class: 'input-group input-group-sm' },
                    filterMenuButton: { class: 'd-none' },
                    headerFilterClearButton: { class: 'd-none' },
                }"
            >
                <template #filter="{ filterModel, filterCallback }">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @input="filterCallback"
                        class="form-control"
                        placeholder="Search"
                    />
                </template>
            </Column>
            <template #empty>
                <div class="text-center py-2">
                    <i class="icon ph-bold ph-database me-2"></i>No data.
                </div>
            </template>
        </DataTable>
        <div class="text-end">
            <button
                type="button"
                class="btn btn-primary btn-sm"
                :disabled="!selectedUploadedSalesReportFileCollection"
                @click="downloadFiles"
            >
                <i class="icon ph-bold ph-download me-2"></i>Download Files
            </button>
        </div>
    </fieldset>
</template>

<script setup>
import { ref, watch } from "vue";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useUploadedSalesReportFileCollections } from "@/composables/data/uploadedSalesReportFileCollections";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const periodic = "PERIODIC";
const liquidation = "LIQUIDATION";

const props = defineProps({
    reportType: String,
    refreshFlag: null,
});

const emit = defineEmits(["error"]);

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    'time_period', 'file_name', 'created_at'
]);

const {
    uploadedSalesReportFileCollections,
    totalRecords,
    getUploadedSalesPeriodicReportFileCollections,
    getUploadedSalesLiquidationReportFileCollections,
} = useUploadedSalesReportFileCollections();

const selectedUploadedSalesReportFileCollection = ref(null);
const isLoading = ref(false);
const fetchTimeout = ref(null);

async function fetchUploadedSalesReportFileCollections() {
    uploadedSalesReportFileCollections.value = null;
    isLoading.value = true;
    try {
        if (props.reportType === periodic) {
            await getUploadedSalesPeriodicReportFileCollections(params.value);
        } else if (props.reportType === liquidation) {
            await getUploadedSalesLiquidationReportFileCollections(
                params.value
            );
        }
    } catch (e) {
        emit("error", e.message);
    }
    isLoading.value = false;
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(
        fetchUploadedSalesReportFileCollections,
        300
    );
}

watch(() => props.refreshFlag, fetchUploadedSalesReportFileCollections);

watch(
    () => props.reportType,
    () => {
        fetchUploadedSalesReportFileCollections();
        selectedUploadedSalesReportFileCollection.value = null;
    }
);

function downloadFiles() {
    if (props.reportType === periodic) {
        window.open(
            route(
                "api.uploaded-sales-report-periodic-file-collections.file.show",
                {
                    file_collection:
                        selectedUploadedSalesReportFileCollection.value.id,
                }
            )
        );
    } else if (props.reportType === liquidation) {
        window.open(
            route(
                "api.uploaded-sales-report-liquidation-file-collections.file.show",
                {
                    file_collection:
                        selectedUploadedSalesReportFileCollection.value.id,
                }
            )
        );
    }
}
</script>
