<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-plus me-2"></i>Create/Update Breakdown
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                >
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <FormInput
                        type="text"
                        v-model="orderIdDisplay"
                        disabled
                        label="Order ID"
                        id-prefix="orderId"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormInput
                        type="text"
                        v-model="form.SWATCH_NO"
                        label="Swatch No."
                        id-prefix="swatchNo"
                        :errors="errors.SWATCH_NO"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <div class="row gx-1">
                        <div class="col">
                            <FormInput
                                type="text"
                                v-model="form.COLOR"
                                label="Color"
                                id-prefix="color"
                                :errors="errors.COLOR"
                                class="mb-md-1 mb-2"
                                is-horizontal
                                label-class="col-xl-5 col-md-5 label-sm"
                                input-class="form-control-sm"
                                input-container-class="col-xl-7 col-md-7 col-12"
                            />
                        </div>
                        <div class="col-auto">
                            <div class="text-end mb-md-1 mb-2">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primary px-1"
                                    @click="
                                        (_) => {
                                            isSelectColorPopupOpen = true;
                                        }
                                    "
                                >
                                    <i class="icon ph-bold ph-magnifying-glass me-2"></i>Select
                                </button>
                            </div>
                        </div>
                    </div>
                    <FormInput
                        type="number"
                        v-model="form.QUANTITY"
                        label="Quantity"
                        id-prefix="quantity"
                        :errors="errors.QUANTITY"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormSelect
                        v-model="form.STATUS"
                        label="Status"
                        id-prefix="status"
                        :options="STATUS_OPTIONS"
                        :option-value="(option) => option.value"
                        :option-to-string="(option) => option.label"
                        :errors="errors.STATUS"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        select-class="form-select-sm"
                        select-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <FormInput
                        type="date"
                        v-model="form.ARRIVAL_DATE"
                        label="Arrival Date"
                        id-prefix="arrivalDate"
                        :errors="errors.ARRIVAL_DATE"
                        class="mb-md-1 mb-2"
                        is-horizontal
                        label-class="col-xl-4 col-md-5 label-sm"
                        input-class="form-control-sm"
                        input-container-class="col-xl-8 col-md-7 col-12"
                    />
                    <div class="text-end mt-3">
                        <button
                            type="button"
                            class="btn btn-primary me-2 btn-sm mb-1"
                            :disabled="
                                loadingFlags.has('insertRawOrderBreakdown') ||
                                loadingFlags.has('updateRawOrderBreakdown')
                            "
                            @click="
                                (_) => {
                                    if (!rawOrderBreakdown) {
                                        insertRawOrderBreakdown();
                                    } else {
                                        updateRawOrderBreakdown();
                                    }
                                }
                            "
                        >
                            <span
                                v-if="
                                    loadingFlags.has('insertRawOrderBreakdown') ||
                                    loadingFlags.has('updateRawOrderBreakdown')
                                "
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            >
                            </span>
                            <i class="icon ph-bold ph-floppy-disk me-2"></i>Save
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm mb-1 me-2"
                            @click="reset"
                        >
                            <i class="icon ph-bold ph-x me-2"></i>Clear
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SelectColor
        :is-open="isSelectColorPopupOpen"
        @close="
            (_) => {
                isSelectColorPopupOpen = false;
            }
        "
        @select="
            (selectedColor) => {
                form.COLOR = selectedColor.COLOR;
                isSelectColorPopupOpen = false;
            }
        "
    />
</template>

<script setup>
import { computed, ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";

import { useRawOrderBreakdowns } from "@/composables/data/rawOrderBreakdowns";

import FormInput from "@/components/utils/FormInput.vue";
import FormSelect from "@/components/utils/FormSelect.vue";
import SelectColor from "@/components/utils/SelectColor.vue";

const props = defineProps({
    isOpen: Boolean,
    orderId: Number,
});

const emit = defineEmits(["success", "close"]);

const toasts = useToastsStore();

const { postRawOrderBreakdown, putRawOrderBreakdown } = useRawOrderBreakdowns();

const STATUS_OPTIONS = [
    {
        value: "O",
        label: "To Order",
    },
    {
        value: "R",
        label: "Received",
    },
    {
        value: "C",
        label: "Cancelled",
    },
];

const rawOrderBreakdown = defineModel();

const orderIdDisplay = computed(() => props.orderId);

const form = ref({
    SWATCH_NO: null,
    COLOR: null,
    QUANTITY: null,
    STATUS: null,
});

const errors = ref({});

const loadingFlags = useLoadingFlagsStore();

const isSelectColorPopupOpen = ref(false);

watch(rawOrderBreakdown, () => {
    errors.value = {};
    form.value = Object.assign(
        {
            SWATCH_NO: null,
            COLOR: null,
            QUANTITY: null,
            STATUS: null,
        },
        rawOrderBreakdown.value
    );
});

function reset() {
    rawOrderBreakdown.value = null;
    if (!rawOrderBreakdown.value) {
        form.value = {
            SWATCH_NO: null,
            COLOR: null,
            QUANTITY: null,
            STATUS: null,
        };
    }
    errors.value = {};
}

async function insertRawOrderBreakdown() {
    errors.value = {};

    loadingFlags.add("insertRawOrderBreakdown");
    try {
        const newRawOrderBreakdown = await postRawOrderBreakdown(
            props.orderId,
            form.value
        );
        toasts.add(
            "SUCCESS",
            "success",
            "Successfully created raw order breakdown."
        );
        rawOrderBreakdown.value = newRawOrderBreakdown;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "error", e.message);
    }
    loadingFlags.delete("insertRawOrderBreakdown");
}

async function updateRawOrderBreakdown() {
    errors.value = {};

    loadingFlags.add("updateRawOrderBreakdown");
    try {
        const newRawOrderBreakdown = await putRawOrderBreakdown(
            props.orderId,
            rawOrderBreakdown.value.BREAKDOWN_ID,
            form.value
        );
        toasts.add(
            "SUCCESS",
            "success",
            "Successfully updated raw order breakdown."
        );
        rawOrderBreakdown.value = newRawOrderBreakdown;
        emit("success");
    } catch (e) {
        errors.value = e.errors ?? {};
        toasts.add("ERROR", "error", e.message);
    }
    loadingFlags.delete("updateRawOrderBreakdown");
}
</script>

<style scoped>
.container {
    max-width: 500px;
}
</style>
