<template>
    <DataTable
        :loading="loadingFlags.has('fetchEmployeesWithDaysOfWorkAndYearsOfService')"
        lazy
        :value="employees"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="e => { onPage(e); fetchEmployeesWithDaysOfWorkAndYearsOfService(); }"
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        sort-mode="multiple"
        v-model:multi-sort-meta="multiSortMeta"
        @sort="e => { onSort(e); fetchEmployeesWithDaysOfWorkAndYearsOfService(); }"
        :pt="{ table: { class: 'table table-bordered table-hover' }}">
        <Column
            field="EMPID"
            header="Employee ID"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="DEPT"
            header="Department"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="STATUS"
            header="Status"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="FNAME"
            header="First Name"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="LNAME"
            header="Last Name"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="BIRTHDATE"
            header="Birthdate"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="HIREDATE"
            header="Hiredate"
            sortable
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' }
            }">
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="age"
            header="Age"
        />
        <Column
            field="latestRate.RATE"
            header="Rate"
        />
        <Column
            field="latestRate.COLA"
            header="COLA"
        />
        <Column
            field="latestRate.START_DATE"
            header="Rate Start"
        />
        <Column
            field="latestRate.END_DATE"
            header="Rate End"
        />
        <Column
            field="TOTAL_DOW"
            header="Days of Work"
        />
        <Column
            field="TOTAL_YOW"
            header="Years of Work"
        />
        <Column
            field="YEARS"
            header="Years"
        />
        <Column
            field="MONTHS"
            header="Months"
        />
        <template #empty>
            <div class="text-center py-2">
                <i class="icon ph-bold ph-database me-2"></i>No data yet.
            </div>
        </template>
        <template #footer>
            {{ totalRecords ?? 0 }} total records
        </template>
    </DataTable>
</template>

<script setup>
import { ref, watch } from 'vue';

import { useLoadingFlagsStore } from '@/stores/loadingFlags';
import { useToastsStore } from '@/stores/toasts';
import { useDataTableParams } from '@/composables/data/dataTableParams';
import { useEmployees } from '@/composables/data/employees';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

const props = defineProps({
    paytype: String,
    departmentGroupId: Number,
    startDate: String,
    endDate: String,
    refreshFlag: null,
});

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const reportParams = defineModel('params');
const reportTotalRecords = defineModel('totalRecords');

const { params, filters, multiSortMeta, onPage, onFilter, onSort } = useDataTableParams([
    'EMPID',
    'DEPT',
    'STATUS',
    'FNAME',
    'LNAME',
    'BIRTHDATE',
    'HIREDATE',
    'latestRate.RATE',
    'latestRate.COLA',
    'latestRate.START_DATE',
    'latestRate.END_DATE',
    'TOTAL_DOW',
    'TOTAL_YOW',
    'YEARS',
    'MONTHS',
]);

const { employees, totalRecords, getEmployeesWithDaysOfWorkAndYearsOfService } = useEmployees();

const fetchTimeout = ref(null);

async function fetchEmployeesWithDaysOfWorkAndYearsOfService() {
    employees.value = null;

    if (!props.paytype || !props.startDate || !props.endDate) {
        return;
    }

    loadingFlags.add("fetchEmployeesWithDaysOfWorkAndYearsOfService");
    try {
        await getEmployeesWithDaysOfWorkAndYearsOfService(
            props.paytype,
            props.departmentGroupId,
            props.startDate,
            props.endDate,
            params.value
        );
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchEmployeesWithDaysOfWorkAndYearsOfService");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchEmployeesWithDaysOfWorkAndYearsOfService, 800);
}

watch(
    () => [
        props.paytype,
        props.departmentGroupId,
        props.startDate,
        props.endDate,
        props.refreshFla
    ],
    fetchEmployeesWithDaysOfWorkAndYearsOfService
);

watch(params, () => {
    reportParams.value = params.value;
});

watch(totalRecords, () => {
    reportTotalRecords.value = totalRecords.value;
});
</script>
