<template>
    <div
        v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center"
    >
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-warning me-2"></i>Warnings
                </h4>
                <button
                    type="button"
                    class="btn-close"
                    @click="emit('close')"
                ></button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <DataTable
                        :value="details?.warnings"
                        paginator
                        :rows="10"
                        :pt="{ table: { class: 'table table-bordered table-hover' } }"
                    >
                        <Column header="Warning">
                            <template #body="{ data }">
                                <span class="badge bg-warning"> Warning </span>
                            </template>
                        </Column>
                        <Column field="line" header="Line" />
                        <Column field="message" header="Message" />
                        <template #empty>
                            <div class="text-center py-2">
                                <i class="icon ph-bold ph-database me-2"></i>No messages.
                            </div>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Column from "primevue/column";
import DataTable from "primevue/datatable";

const props = defineProps({
    details: Object,
    isOpen: Boolean,
});

const emit = defineEmits(["close"]);
</script>

<style scoped>
.container {
    max-width: 700px;
}
</style>
