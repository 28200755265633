<template>
    <DataTable
        :loading="loadingFlags.has('fetchRawOrders')"
        lazy
        :value="rawOrders"
        :total-records="totalRecords"
        paginator
        :rows="10"
        @page="
            (e) => {
                onPage(e);
                fetchRawOrders();
            }
        "
        filter-display="row"
        v-model:filters="filters"
        @filter="tableOnFilter"
        selection-mode="single"
        v-model:selection="selectedRawOrder"
        data-key="ORDER_ID"
        :pt="{ table: { class: 'table table-bordered table-hover' } }"
    >
        <Column
            field="ORDER_ID"
            header="Order ID"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="purchase_order.PURCHASE_ORDER_NUMBER"
            header="PO Number"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="
                        (_) => {
                            filterModel.matchMode = 'contains';
                            filterCallback();
                        }
                    "
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="DATE_ORDERED"
            header="Date Ordered"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="supplier.SUPPLIER_NAME"
            header="Supplier"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="STYLE"
            header="Style"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="UNIT_PRICE"
            header="Unit Price"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column
            field="QUANTITY"
            header="Quantity"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <Column header="Match">
            <template #body="{ data }">
                <span
                    v-if="data.BREAKDOWNS_SUM_QUANTITY != data.QUANTITY"
                    class="badge bg-danger"
                >
                    Not Match
                </span>
                <span v-else class="badge bg-success"> Match </span>
            </template>
        </Column>
        <Column header="Total">
            <template #body="{ data }">
                {{ (data.UNIT_PRICE * data.QUANTITY).toFixed(2) }}
            </template>
        </Column>
        <Column
            field="STATUS"
            header="Status"
            :pt="{
                filterInput: { class: 'input-group input-group-sm' },
                filterMenuButton: { class: 'd-none' },
                headerFilterClearButton: { class: 'd-none' },
            }"
        >
            <template #filter="{ filterModel, filterCallback }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    class="form-control"
                    placeholder="Search"
                />
            </template>
        </Column>
        <template #empty>
            <div class="text-center py-2">
                <i class="icon ph-bold ph-database me-2"></i>No data yet.
            </div>
        </template>
    </DataTable>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import { useLoadingFlagsStore } from "@/stores/loadingFlags";
import { useToastsStore } from "@/stores/toasts";
import { useDataTableParams } from "@/composables/data/dataTableParams";
import { useRawOrders } from "@/composables/data/rawOrders";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

const props = defineProps({
    refreshFlag: null,
    purchaseOrderNumber: null,
    class: String,
});

const { params, filters, onPage, onFilter } = useDataTableParams([
    "ORDER_ID",
    "purchase_order.PURCHASE_ORDER_NUMBER",
    "DATE_ORDERED",
    "supplier.SUPPLIER_NAME",
    "STYLE",
    "UNIT_PRICE",
    "QUANTITY",
    "STATUS",
]);

const { rawOrders, totalRecords, getRawOrders } = useRawOrders();

const selectedRawOrder = defineModel();

const loadingFlags = useLoadingFlagsStore();
const toasts = useToastsStore();

const fetchTimeout = ref(null);

async function fetchRawOrders() {
    rawOrders.value = null;

    loadingFlags.add("fetchRawOrders");
    try {
        await getRawOrders(params.value);

        if (selectedRawOrder.value) {
            selectedRawOrder.value =
                rawOrders.value.filter(
                    (rawOrder) =>
                        rawOrder.ORDER_ID == selectedRawOrder.value.ORDER_ID
                )[0] ?? selectedRawOrder.value;
        }
    } catch (e) {
        toasts.add("ERROR", "Error", e.message);
    }
    loadingFlags.delete("fetchRawOrders");
}

function tableOnFilter(e) {
    onFilter(e);
    if (fetchTimeout.value) {
        clearTimeout(fetchTimeout.value);
    }
    fetchTimeout.value = setTimeout(fetchRawOrders, 300);
}

onMounted(fetchRawOrders);

watch(() => props.refreshFlag, fetchRawOrders);
watch(
    () => props.purchaseOrderNumber,
    () => {
        filters.value["purchase_order.PURCHASE_ORDER_NUMBER"] = {
            value: props.purchaseOrderNumber,
            matchMode: "equals",
        };
        fetchRawOrders();
    }
);
</script>
