<template>
    <div v-if="isOpen"
        class="pop-up d-flex justify-content-center align-items-center">
        <div class="container bg-light px-0 py-3 rounded">
            <div class="d-flex justify-content-between align-items-center px-4">
                <h4 class="title mb-0 fw-bold">
                    <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                </h4>
                <button type="button"
                    class="btn-close"
                    @click="emit('close')">
                </button>
            </div>
            <hr />
            <div class="modal-container px-4">
                <div class="modal-padding-container">
                    <template v-if="canSelectPaytype">
                        <FormSelect
                            v-model="selectedPaytype"
                            :options="props.paytypeOptions ?? ['BIMONTHLY', 'WEEKLY', 'MONTHLY', 'YEARLY']"
                            label="Paytype"
                            id-prefix="timePeriodPopupPaytype"
                            class="mb-3"
                        />
                    </template>
                    <TimePeriodTable
                        :paytype="selectedPaytype"
                        v-model="selectedTimePeriod"
                        show-from-current-date
                        :sort="[{ field: 'TIME_PERIOD', order: -1 }]"
                    />
                    <hr class="my-3" />
                    <div class="text-end">
                        <button type="button"
                            class="btn btn-primary"
                            :disabled="!selectedTimePeriod"
                            @click="(_) => { emit('select', selectedTimePeriod); }">
                            <i class="icon ph-bold ph-calendar me-2"></i>Select Time Period
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

import FormSelect from "../FormSelect.vue";
import TimePeriodTable from "@/components/utils/tables/TimePeriodTable.vue";

const props = defineProps({
    paytype: {
        type: String,
        default: null,
    },
    canSelectPaytype: {
        type: Boolean,
        default: false,
    },
    paytypeOptions: Array,
    isOpen: Boolean,
});

const emit = defineEmits(["select", "error", "close"]);

const selectedPaytype = ref(null);

const selectedTimePeriod = ref(null);

onMounted(() => {
    selectedPaytype.value = props.paytype ?? props.paytypeOptions?.[0] ?? 'MONTHLY';
});

watch(() => props.paytype, () => {
    selectedPaytype.value = props.paytype;
});
</script>
